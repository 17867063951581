<section class="modal-container" (keyup.esc)="closeModal()" [@slideUpDown]>
  <div class="change-picture-modal">
    <!-- Close modal. -->
    <i class="fas fa-times" (click)="closeModal()"></i>

    <!-- Title. -->
    <div class="modal-title">{{ 'GENERIC.CHANGE_PICTURE' | translate }}</div>

    <!-- [CONTENT: START] -->
    <div *ngIf="!isLoading" class="modal-content" style="padding: 20px 0">
      <!-- Avatar. -->
      <div *ngIf="!imageChangedEvent" class="human-avatar">
        <div>
          <p style="font-weight: bold">Escolha uma foto.</p>
        </div>

        <!-- Current and new picture. -->
        <div class="human-pics">
          <img
            *ngIf="!human.picture"
            src="{{ getGenericAvatar() }}"
            class="img-avatar" />
          <img
            *ngIf="human.picture"
            src="{{ humanAvatar }}"
            class="img-avatar" />
          <i
            *ngIf="newAvatarUrl"
            class="fas fa-long-arrow-alt-right i-avatar-separator"></i>
          <img *ngIf="newAvatarUrl" [src]="newAvatarUrl" class="img-avatar" />
        </div>

        <form id="frm-avatar" enctype="multipart/form-data">
          <input
            type="file"
            accept="image/png, image/jpeg"
            name="file-picture"
            id="file-picture"
            (change)="changeHumanAvatar($event)"
            class="custom-input-file custom-input-file--2"
            [disabled]="isSaving" />
        </form>

        <label for="file-picture" class="custom-input-label">
          <i class="fa fa-upload"></i>
          <span id="spn-new-pic-upload">{{ newAvatarBtnText }}</span>
        </label>

        <div
          *ngIf="newAvatarUrl && !isSaving"
          class="cancel-avatar"
          (click)="cancelNewAvatar()">
          cancelar
        </div>
      </div>

      <!-- Crop image. -->
      <div *ngIf="imageChangedEvent" class="human-crop">
        <div>
          <p style="font-weight: bold; margin: 0 0 10px">Ajuste a imagem.</p>
        </div>

        <!-- Image cropper. -->
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          format="png"
          roundCropper="true"
          [resizeToWidth]="400"
          [resizeToHeight]="400"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>

      <!-- Crop button. -->
      <div *ngIf="imageChangedEvent" style="float: right; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-success btn-icon-label"
          (click)="cropDone()">
          <span class="btn-inner--icon"
            ><i class="fas fa-check-circle"></i
          ></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.DONE' | translate
          }}</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>

      <!-- Save button. -->
      <div *ngIf="!imageChangedEvent" style="float: right; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-success btn-icon-label"
          (click)="saveNewPicture()"
          [disabled]="!upReady || !newAvatar || isSaving">
          <span class="btn-inner--icon"
            ><i class="fas fa-check-circle"></i
          ></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.SAVE' | translate
          }}</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>
    </div>
    <!-- [CONTENT: END] -->

    <div *ngIf="isLoading">Buscando dados...</div>
  </div>
</section>
