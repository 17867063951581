import { IClinic } from './clinic.model';
import { IPet, IPetFamily } from './pet.model';

// Interface for treatments model.
export interface ITreatment {
  blocked?: boolean;
  code: string;
  countryId: number;
  createdAt: string;
  id?: number;
  name: string;
  petTreatments?: number;
  updatedAt?: string;
  description: string | null;
  type: ETreatmentType | null;
  limitType: ETreatmentLimitType | null;
  limitPeriodAmount: number | null;
  limitPeriodUnit: ETreatmentLimitPeriod | null;
  limitPeriodStatus?: boolean | null;
  gracePeriodAmount: number | null;
  gracePeriodUnit: ETreatmentGracePeriodUnit | null;
  gracePeriodStatus: boolean | null;
  petTypes: IPetFamily[] | null;
}

// Interface for treatments request view.
export interface ITreatmentsRequest {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: ITreatmentsRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  items: ITreatmentsItem[];
}

export interface ITreatmentsRequestAdminView {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: ITreatmentsRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  pet: IPet;
  clinic: IClinic;
  items: ITreatmentsItem[];
}

// Iterface for updating treatments requests
export interface ITreatmentsRequestView {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: ITreatmentsRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  pet?: IPet;
}

// Interface for treatments request view.
export interface ITreatmentsRequestUpdate {
  id: number;
  code: string;
  statusId: number;
  refuseNotes: string | null;
  decisionAt: string;
  deletedAt: string;
}

// Iterface for new treatments requests
export interface INewTreatmentsRequest {
  clinicId: number;
  petId: number;
}

// Interface for treatments requests item model.
export interface ITreatmentsItem {
  id: number;
  requestId: number;
  cost: number;
  description: string;
  observations: string;
  statusId: number;
  treatmentId: number;
  createdAt: string;
  updatedAt: Date | null;
  deletedAt: Date | null;
  refuseNotes: string | null;
  fiscalNote: string | null;
}

// Interface for new treatments requests item model.
export interface INewTreatmentsItem {
  requestId: number;
  cost: number;
  description: string;
  observations: string;
  treatmentId: number;
}

// Interface for update treatments requests item model.
export interface IUpdateTreatmentsItem {
  id: number;
  cost: number;
  description: string;
  observations: string;
  statusId?: number;
  updatedAt?: string | null;
  deletedAt?: string | null;
  refuseNotes?: string | null;
  fiscalNote?: string | null;
}

// Interface for update treatments requests item model.
export interface ICancelTreatmentsItem {
  id: number;
}

// Interface for treatment requets status model.
export interface ITreatmentsRequestStatus {
  code: ETreatmentRequestStatusCode;
  createdAt: string;
  id?: number;
  updatedAt?: string;
}

export type TreatmentStatusMap = {
  [K in ETreatmentRequestStatusCode]: number;
};

export enum ETreatmentRequestStatusCode {
  pending = 'pending',
  approved = 'approved',
  completed = 'completed',
  refused = 'refused',
  canceled = 'canceled',
}

export enum ETreatmentType {
  appointment = 'appointment',
  exams = 'exams',
  hospitalization = 'hospitalization',
  procedures = 'procedures',
  vaccines = 'vaccines',
  checkup = 'checkup',
}

export enum ETreatmentLimitType {
  limited = 'limited',
  unlimited = 'unlimited',
}

export enum ETreatmentLimitPeriod {
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum ETreatmentGracePeriodUnit {
  day = 'day',
  month = 'month',
  year = 'year',
}

export interface ITreatmentReport {
  petChip?: string;
  petId: number;
  petName: string;
  petPicture: string;
  treatCost: number;
  treatDate: number;
  treatDescription: string;
  treatName: string;
  treatId: number;
  treatNF?: string;
}
