<section
  *ngIf="isLoaded"
  id="agreements"
  class="modal-container"
  [ngClass]="{ 'mob-mode': isMobile }"
  [@slideDown]>
  <div class="close-modal">
    <i class="far fa-times-circle" (click)="closeWindow($event)"></i>
  </div>

  <div class="modal-content" [ngClass]="{ 'mob-mode': isMobile }">
    <object
      data="assets/docs/Termo-adesao-usuarios-Petbee.pdf"
      type="application/pdf"
      width="100%"
      height="100%">
      <p>
        O seu navegador não suporta arquivos PDF.<br />
        <a href="assets/docs/Termo-adesao-usuarios-Petbee.pdf">Faça o download</a
        >.
      </p>
    </object>
  </div>
</section>
