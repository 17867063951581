// Angular modules.
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, EBroadcast } from '@app/core/models';

// App services.
import {
  AuthService,
  BroadcastService,
  MobileService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss'],
  animations: [slideBottomAnimation],
})
export class RememberPasswordComponent implements OnInit {
  // Form.
  public email: string;
  public errEmail: string;

  // Element's ids.
  public idEmail: string = 'txt-email';
  public idPass: string = 'txt-password';

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoaded: boolean = false;
  public isSendingEmail: boolean = false;
  public emailWasSent: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private mobileService: MobileService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/remember-password'
      );
      this.isLoaded = true;
      if (!this.isMobile) {
        setTimeout(() => {
          return (
            document.getElementById('txt-email') &&
            document.getElementById('txt-email').focus()
          );
        }, 500);
      }
    }, 1500);
  }

  // Try to send message another time.
  public tryAgain(): void {
    this.clearErrors();
    this.emailWasSent = false;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }

  // Redirect to home page.
  public goToHome(): void {
    window.location.href = environment.urls.site;
  }

  // Send e-mail with instructions.
  public sendEmail(event?: any): void {
    this.isSendingEmail = true;
    this.emailWasSent = false;

    if (this.validateForm()) {
      this.authService
        .rememberPassword(this.email)
        .then(response => {
          this.emailWasSent = true;
          this.utilsService.showAlert(
            'alert-reg-frm',
            this.translate.instant('GENERIC.MESSAGES.EMAIL_SENT'),
            EAlertType.Success
          );
        })
        .catch(error => {
          this.utilsService.showAlert(
            'alert-rec-pass',
            this.translate.instant('GENERIC.MESSAGES.REMEMBER_PASSWORD_ERROR'),
            EAlertType.Error
          );
          this.isSendingEmail = false;
        })
        .finally(() => {
          this.isSendingEmail = false;
        });
    } else {
      this.isSendingEmail = false;
    }
  }

  // Validate form.
  private validateForm(): boolean {
    let valid: boolean = true;

    // Clear all errors.
    this.clearErrors();

    // E-Mail.
    if (!this.email) {
      this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL');
      this.inputError(this.idEmail, true);
      if (valid) {
        document.getElementById(this.idEmail) &&
          document.getElementById(this.idEmail).focus();
      }
      valid = false;
    } else {
      if (!this.utilsService.validateEmail(this.email)) {
        this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL_INVALID');
        this.inputError(this.idEmail, true);
        if (valid) {
          document.getElementById(this.idEmail) &&
            document.getElementById(this.idEmail).focus();
        }
        valid = false;
      } else {
        this.inputError(this.idEmail, false);
      }
    }

    return valid;
  }

  // Clear login errors.
  private clearErrors(): void {
    this.errEmail = null;
    this.inputError(this.idEmail, false);
  }

  // Toggle input error.
  private inputError(id: string, add: boolean = true): void {
    const errorClass: string = 'frm-error';
    const el: HTMLElement = document.getElementById(id);

    if (el) {
      if (add) {
        el.classList.add(errorClass);
      } else {
        el.classList.remove(errorClass);
      }
    }
  }
}
