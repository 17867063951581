<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <div class="row">
    <!-- Street. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.STREET' | translate }}:</label>
      <div class="div-item">{{ address.address || '---' }}</div>
    </div>

    <!-- Number. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.NUMBER' | translate }}:</label>
      <div class="div-item">{{ address.number || '---' }}</div>
    </div>
  </div>

  <div class="row">
    <!-- Complement. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.COMPLEMENT' | translate }}:</label>
      <div class="div-item">{{ address.complement || '---' }}</div>
    </div>

    <!-- Neighborhood. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.NEIGHBORHOOD' | translate }}:</label>
      <div class="div-item">{{ address.neighborhood || '---' }}</div>
    </div>

    <!-- Zip code. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ZIP_CODE' | translate }}:</label>
      <div class="div-item">{{ (address.zipCode | zipcode) || '---' }}</div>
    </div>
  </div>

  <div class="row">
    <!-- City. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.CITY' | translate }}:</label>
      <div class="div-item">{{ city.name || '...' }}</div>
    </div>

    <!-- State. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'REGISTER.ELEMENTS.ADDRESS.STATE' | translate }}:</label>
      <div class="div-item">{{ state.code || '...' }}</div>
    </div>
  </div>
</div>

<!-- Loader. -->
<div *ngIf="isLoading" style="margin: 30px 0">
  <app-horizontal-loader></app-horizontal-loader>
</div>
