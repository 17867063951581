import {
  animate,
  keyframes,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Fade in/out animation.
export const fadeAnimation: any = [
  trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('600ms ease-in-out', style({ opacity: 0.5 })),
      animate('700ms ease-in-out', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5 })),
      animate('800ms ease-in-out', style({ opacity: 0 })),
    ]),
  ]),
];
