// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import { IAPIResponse } from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const epMain: string = endpoint + 'plans/';

@Injectable({ providedIn: 'root' })
export class PlansService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(private authService: AuthService, private http: HttpClient) {}

  // Get human's subscriptions.
  public getAll(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get pet by its identification number.
  public getById(planId: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + planId, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }
}
