// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// App enumerators.
import { ESubscriptionInterval } from '@app/core/models';

// Interfaces.
import {
  IAPIResponse,
  IHumanGuest,
  ISubscription,
  ISubscriptionDiscount,
} from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const epMain: string = endpoint + 'subscriptions/';
const epFree: string = epMain + 'free/';
const epCielo: string = endpoint + 'cielo/';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get human's subscriptions.
  public getSubscriptions(humanId: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'human/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new subscription.
  public createSubscription(subs: ISubscription): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epMain, JSON.stringify(subs), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update a subscription.
  public updateSubscription(subs: ISubscription): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epMain + subs.id, JSON.stringify(subs), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Cancel a subscription.
  public cancelSubscription(subs: ISubscription): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(epMain + subs.id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Cancel a free subscription.
  public cancelFreeSubscription(subs: ISubscription): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epFree + subs.id + '/cancel', {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Verify coupon by code and human.
  public verifyCoupon(coupon: string, humanId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          epMain + 'verify-coupon/',
          JSON.stringify({ coupon, humanId }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get subscriptions related to a coupon.
  public getByCoupon(coupon: string): Promise<IHumanGuest[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHumanGuest[]>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'get-by-coupon/' + coupon, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            const guests: IHumanGuest[] = [];
            if (res && res.rows && res.rows.length > 0) {
              res.rows.map(r => {
                const g: IHumanGuest = {
                  coupon: r.coupon,
                  createdAt: r.createdAt,
                  guestEmail: r.human.email,
                  guestId: r.human.id,
                  guestName: r.human.fullName,
                  subscribed: r.human.subscribed,
                  // petId: r.pet.id,
                  // petName: r.pet.name
                };
                guests.push(g);
              });
              resolve(guests);
            } else {
              resolve();
            }
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get last N subscriptions.
  public getLastSubscriptions(count: number = 10): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'last/' + count, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Return discount values.
  public getDiscounts(): ISubscriptionDiscount[] {
    const d: ISubscriptionDiscount[] = [];
    d.push({ totalPets: 1, monthlyDiscount: 0, annualDiscount: 0 });
    d.push({ totalPets: 2, monthlyDiscount: 30, annualDiscount: 15 });
    d.push({ totalPets: 3, monthlyDiscount: 40, annualDiscount: 23 });
    d.push({ totalPets: 4, monthlyDiscount: 50, annualDiscount: 30 });
    d.push({ totalPets: 5, monthlyDiscount: 50, annualDiscount: 34 });
    d.push({ totalPets: 6, monthlyDiscount: 50, annualDiscount: 37 });
    d.push({ totalPets: 7, monthlyDiscount: 60, annualDiscount: 40 });
    d.push({ totalPets: 8, monthlyDiscount: 60, annualDiscount: 43 });
    d.push({ totalPets: 9, monthlyDiscount: 70, annualDiscount: 46 });
    d.push({ totalPets: 10, monthlyDiscount: 80, annualDiscount: 49 });
    return d;
  }

  // Return signature's value with discount.
  public getSignatureDiscount(
    totalPets: number,
    recurrencyInterval: ESubscriptionInterval
  ): number {
    // const d: ISubscriptionDiscount[] = this.getDiscounts();
    // let discount: number;
    // let monthRecurrency: boolean = recurrencyInterval === ESubscriptionInterval.Monthly

    // if (totalPets > 10) {
    //   discount = monthRecurrency ? d[d.length - 1].monthlyDiscount : d[d.length - 1].annualDiscount;
    // } else if (totalPets <= 10 && totalPets > 0) {
    //   discount = monthRecurrency ? d.find(i => i.totalPets === totalPets).monthlyDiscount : d.find(i => i.totalPets === totalPets).annualDiscount;;
    // } else {
    //   discount = 0;
    // }

    // return discount;

    let discount: number;

    if (totalPets > 1) {
      discount = 5000;
    } else {
      discount = 0;
    }

    return discount;
  }
}
