// Angular modules.
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zipcode' })
export class ZipcodePipe implements PipeTransform {
  transform(value: string): string | null {
    const a: string = value.substring(0, value.length - 3);
    const b: string = value.substring(value.length - 3, value.length);
    return a + '-' + b;
  }
}
