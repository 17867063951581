<section *ngIf="isLoaded" id="reset-pass" class="page-container" [@slideDown]>
  <!-- Empty column. -->
  <div class="page-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div class="page-content page-form">
    <div class="form-container" [@slideDown]>
      <!-- Instructions. -->
      <h1 style="font-style: italic">{{ 'LOGIN.RESET.TITLE' | translate }}</h1>
      <p>{{ 'LOGIN.RESET.TEXT' | translate }}</p>

      <!-- E-Mail. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="email"
          id="txt-email"
          [(ngModel)]="email"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{ 'LOGIN.ELEMENTS.EMAIL' | translate }}</span>
      </div>
      <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

      <!-- Password. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="password"
          id="txt-password"
          [(ngModel)]="password"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'LOGIN.ELEMENTS.PASSWORD' | translate
        }}</span>
      </div>
      <span *ngIf="errPassword" class="spn-error">{{ errPassword }}</span>

      <!-- Password. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="password"
          id="txt-password-conf"
          [(ngModel)]="passwordConf"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.PASSWORD_CONFIRM' | translate
        }}</span>
      </div>
      <span *ngIf="errPasswordConf" class="spn-error">{{
        errPasswordConf
      }}</span>

      <!-- Send e-mail button. -->
      <div class="form-element">
        <button
          class="btn btn-info btn-full"
          (click)="changePassword()"
          [disabled]="isSaving">
          <span *ngIf="!isSaving">{{
            'LOGIN.RESET.BUTTON_TEXT' | translate
          }}</span>
          <span *ngIf="isSaving"><i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <div class="new-login">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/login')">
            {{ 'LOGIN.ELEMENTS.SIGN_IN' | translate }}
          </button>
        </div>

        <div class="new-account">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/register')">
            {{ 'LOGIN.ELEMENTS.REGISTER' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- [CONTENT: END] -->

  <!-- Empty column. -->
  <div class="page-content lg-b"></div>
</section>
