// Main module.
export * from '@app/register/register.module';

// Module routes.
export * from '@app/register/register-routing.module';

// Module components.
export * from '@app/register/register.component';
export * from '@app/register/register-availability/register-availability.component';
export * from '@app/register/register-form/register-form.component';
export * from '@app/register/register-notification/register-notification.component';
export * from '@app/register/register-start/register-start.component';
