// Interface for subscription installments.
export interface IDiscountData {
  message: string;
  monthsFree: number;
  discount: number;
}

export interface IGiftData {
  couponMessage: string;
  giftMessage: string;
  giftTitle: string;
  monthsFree: number;
  discount: number;
}
export interface IGiftcardData {
  giftMessage: string;
  giftTitle: string;
  discount: number;
}

// Model for paymetn system types.
export enum EPaymentSystem {
  CreditCard = 'CreditCard',
  Pix = 'Pix',
}
