// Angular modules.
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
  // Constructor method.
  constructor() {}

  // Recieves a value with point (1.45) or comma (1,45) separator and return value in Petbee format (145).
  public getPetbeeValue(val: string): number {
    let f: number = parseInt(val, 10);
    const s: string[] =
      val.indexOf('.') > -1
        ? val.split('.')
        : val.indexOf(',') > -1
        ? val.split(',')
        : [];

    if (s.length > 0) {
      const d: number = parseInt(s[1], 10);
      let df: string;
      if (s[1] && s[1].startsWith('0')) {
        df = '0' + Math.floor(d / 10);
      } else if (d < 10) {
        df = d + '0';
      } else if (d > 99) {
        df = d.toString().slice(0, 2);
      } else {
        df = d.toString();
      }
      f = parseInt(s[0] + df, 10);
    }

    return f;
  }

  // Recieves a default value (12345) and return a float value (123.45).
  public getFloatValue(val: number): number {
    const s: string[] = val.toString().split('');
    if (s.length > 2) {
      const a: string[] = s.slice(0, s.length - 2);
      const b: string[] = s.slice(s.length - 2, s.length);
      const c: string = a.join('') + '.' + b.join('');
      return +c;
    } else {
      return val;
    }
  }

  // Recieves a default value (12345) and return a float value (123.45).
  public getFloatValueStringBRL(val: number): string {
    const s: string[] = val.toString().split('');
    if (s.length > 2) {
      const a: string[] = s.slice(0, s.length - 2);
      const b: string[] = s.slice(s.length - 2, s.length);
      const c: string = a.join('') + ',' + b.join('');
      return c;
    } else {
      return val.toString().replace('.', ',');
    }
  }
}
