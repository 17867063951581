// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import { IHumanLocalData, IPet, IPetFamily } from '@app/core/models';

// App services.
import {
  AuthService,
  ErrorService,
  PetService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-vp-resume',
  templateUrl: './vp-resume.component.html',
  styleUrls: ['./vp-resume.component.scss'],
  animations: [pageAnimations],
})
export class VpResumeComponent implements OnInit {
  // Inputs.
  @Input() pet: IPet;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Pet.
  public petFamilies: IPetFamily[] = [];
  public chipNumber: string;
  public petFamily: string;

  // Status.
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public isEditing: boolean = false;

  // Constuctor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private petService: PetService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get chip number (if any).
    this.getChipText();

    // Get pet's family.
    this.getFamily();
  }

  // Add/edit identification number info.
  public editChip(): void {
    this.isEditing = !this.isEditing;

    if (this.isEditing) {
      setTimeout(() => {
        document.getElementById('pet-chip') &&
          document.getElementById('pet-chip').focus();
      }, 500);
    }
  }

  // Save new chip.
  public saveChip(): void {
    let run: boolean = true;

    if (!this.pet.chip) {
      run = confirm(
        'O pet será salvo sem um número de identificação. Confirma a operação?'
      );
    }

    if (run) {
      this.isSaving = true;
      this.petService
        .updatePet(this.pet)
        .then(res => {
          this.editChip();
          this.getChipText();
        })
        .catch(err => {
          this.utilsService.showAlert(
            'adm-pets',
            this.errorService.manageError(
              err,
              this.constructor.name,
              'saveChip() > petService.updatePet()'
            ),
            EAlertType.Error
          );
        })
        .finally(() => (this.isSaving = false));
    }
  }

  // Get chip text.
  private getChipText(): void {
    this.chipNumber =
      this.pet.chip || this.translate.instant('PETS.ERRORS.NO_CHIP');
  }

  // Get pet's family.
  private getFamily(): void {
    this.isLoading = true;

    // Get pet's family.
    this.petService
      .getFamilies()
      .then(res => {
        this.petFamilies = res.rows as IPetFamily[];
        this.petFamily = this.petFamilies.find(
          pf => pf.id === this.pet.familyId
        ).name;
      })
      .catch(err => {
        this.utilsService.showAlert(
          'adm-pets',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getFamily() > petService.getFamilies()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
