// Interface for coupon model.
export interface ICoupon {
  blocked?: boolean;
  code: string;
  createdAt: string;
  daysFree?: number;
  endDate?: number;
  deletedAt?: string;
  discount: number;
  discountType: string;
  isFirstBuy: boolean;
  id: number;
  planType?: EPlanType;
  public: boolean;
  recurring: boolean;
  updatedAt?: number;
}

export enum EPlanType {
  Monthly = 'monthly',
  Bimonthly = 'bimonthly',
  Quarterly = 'quarterly',
  SemiAnnual = 'semiAnnual',
  Annual = 'annual',
}
