// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Rxjs
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App models.
import { IAddressRegister, IAPIResponse } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';

// App environment.
import { environment } from '@env/environment';

// Endpoints.
const citiesEndpoint: string = environment.urls.api + 'cities/';
const countriesEndpoint: string = environment.urls.api + 'countries/';
const statesEndpoint: string = environment.urls.api + 'states/';

@Injectable({ providedIn: 'root' })
export class LocationService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Search a brazilian address based on a CEP.
  public searchByZipCode(cep: string): any {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`).pipe(
      take(1),
      map(data => this.convertToAddress(data))
    );
  }

  // Get a country by its ISO code.
  public getCountryByIso(code: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(countriesEndpoint + 'get-by-iso/' + code, {
          headers: this.headers,
        })
        .subscribe(
          result => {
            if (result && result.rows && result.rows.length > 0) {
              resolve(result);
            } else {
              reject({
                message: this.translate.instant(
                  'LOCATION.ERRORS.COUNTRY_NOT_FOUND'
                ),
              });
            }
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get a city by its ID.
  public getCityById(cityId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(citiesEndpoint + cityId, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }

  // Get a city.
  public getCity(
    country: string,
    name: string,
    state: string
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.getCountryByIso(country)
        .then(result => {
          const countryId: number = result.rows[0].id;

          this.http
            .get<IAPIResponse>(
              citiesEndpoint +
                'get-by-name/{"countryId":"' +
                countryId +
                '", "state":"' +
                state +
                '", "name":"' +
                name +
                '"}',
              { headers: this.headers }
            )
            .subscribe(
              res => {
                if (res && res.rows && res.rows.length > 0) {
                  resolve(res);
                } else {
                  reject({
                    message: this.translate.instant(
                      'LOCATION.ERRORS.CITY_NOT_FOUND'
                    ),
                  });
                }
              },
              err => {
                reject(err);
              }
            );
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }

  // Get all states of a country.
  public getStates(countryId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(statesEndpoint + 'country/' + countryId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get state by its ID.
  public getStateById(stateId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(statesEndpoint + stateId, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }

  // Get all cities of a state.
  public getStateCities(stateId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(citiesEndpoint + 'state/' + stateId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Convert data to address format.
  private convertToAddress(data: any): IAddressRegister {
    const add: IAddressRegister = {
      city: data.localidade,
      complement: data.complemento || null,
      country: 'Brasil',
      neighborhood: data.bairro,
      // eslint-disable-next-line id-blacklist
      number: null,
      zipCode: data.cep || null,
      state: data.uf,
      street: data.logradouro || null,
      unity: data.unidade || null,
    };

    return add;
  }
}
