// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import { IHuman } from '@app/core/models';

// App services.
import { ErrorService, HumanService, UtilsService } from '@app/core/services';

@Component({
  selector: 'app-vh-resume',
  templateUrl: './vh-resume.component.html',
  styleUrls: ['./vh-resume.component.scss'],
  animations: [pageAnimations],
})
export class VhResumeComponent implements OnInit {
  // Selected human's data.
  @Input() selHuman: IHuman;
  public humanInvite: IHuman;
  public invitedBy: string;

  // Status.
  public isLoading: boolean = true;

  // Constuctor method.
  constructor(
    private errorService: ErrorService,
    private humanService: HumanService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get person who invite this user (if any).
    this.getUserWhoInvite();
  }

  // Get invited by.
  private getUserWhoInvite(): void {
    this.invitedBy = null;

    if (this.selHuman.invitedBy) {
      this.humanService
        .getWithCoupon(this.selHuman.invitedBy)
        .then(res => {
          this.humanInvite = res as IHuman;
          if (this.humanInvite) {
            this.invitedBy =
              this.humanInvite.id +
              ' - ' +
              this.humanInvite.fullName +
              ' (' +
              this.humanInvite.coupon +
              ')';
          } else {
            this.invitedBy = this.selHuman.invitedBy;
          }
        })
        .catch(err => {
          this.utilsService.showAlert(
            'vh-address',
            this.errorService.manageError(
              err,
              this.constructor.name,
              'getAddress() > humanService.getHumanAddress()'
            ),
            EAlertType.Error
          );
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.isLoading = false;
    }
  }
}
