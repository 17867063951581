// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import { IAPIResponse, IExpense, IHumanLocalData } from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const epMain: string = endpoint + 'expenses/';

@Injectable({ providedIn: 'root' })
export class ExpenseService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all expenses.
  public getAll(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http.get<IAPIResponse>(epMain, { headers: this.headers }).subscribe(
        res => resolve(res),
        err => reject(err)
      );
    });
    return promise;
  }

  // Filter expenses.
  public getExpenses(from: number, to: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'filter?from=' + from + '&to=' + to, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }

  // Create new expense.
  public createNew(ex: IExpense): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epMain, JSON.stringify(ex), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }

  // Update an expense.
  public update(ex: IExpense): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epMain + ex.id, JSON.stringify(ex), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }

  // Delete an expense.
  public delete(ex: IExpense): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(epMain + ex.id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });
    return promise;
  }
}
