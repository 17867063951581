/* eslint-disable id-blacklist */
import { stringList } from 'aws-sdk/clients/datapipeline';

// Credit card expiration Year Verificaiton
export interface IExpirationYearVerification {
  isValid: boolean;
  isPotentiallyValid: boolean;
  isCurrentYear: boolean;
}

// Model for a Cielo transaction.
export interface ICieloTransaction {
  MerchantOrderId: string;
  Customer: ICieloCustomer;
  Payment: ICieloPayment;
  CreditCard: ICieloCreditCard | ICieloCreditCardToken;
}

// Model for a Cielo transaction response.
export interface ICeiloResponse {
  MerchantOrderId: string;
  Customer: ICieloCustomer;
}

// Model for a Cielo client.
export interface ICieloCustomer {
  Id?: string | number;
  Name: string;
  Email: string;
  Identity: string;
  IdentityType: string;
  Birthdate?: string;
  Address?: ICieloAddress;
  DeliveryAddress?: ICieloAddress;
  Billing?: ICieloAddress;
}

// Model for a Cielo address.
export interface ICieloAddress {
  Street: string;
  Number: string;
  Complement?: string;
  ZipCode: string;
  City: string;
  State: string;
  Country: string;
}

export interface ICieloCreditCard {
  Brand: string;
  CardNumber: string;
  Holder?: string;
  ExpirationDate: string;
  SaveCard: boolean;
  SecurityCode?: string;
  CardOnFile: ICieloCardOnFile;
}

export interface ICieloCreditCardToken {
  Brand: string;
  CardToken: string;
  CardNumber?: string;
}

export interface ICieloCardOnFile {
  Usage: ECieloCardOnFileUsage;
  Reason: ECieloCardOnFileReason;
}

// Model for a Cielo subscription (recurrence).
export interface ICieloSubscription {
  MerchantOrderId: string;
  Customer: {
    Id: string;
    Name: string;
  };
  Payment: {
    Amount: number;
    AuthorizeNow?: boolean;
    EndDate?: string;
    Installments: number;
    Interval?: string;
    Recurrent?: boolean;
    SoftDescriptor: string;
    StartDate?: string;
    Type: string;
  };
  CreditCard: ICieloCreditCard;
}

// Model for a Cielo payment.
export interface ICieloPayment {
  Type: ECieloPaymentType;
  Amount: number;
  Currency: string;
  Country?: string;
  Provider?: string;
  ServiceTaxAmount?: number;
  SoftDescriptor: string;
  Installments: number;
  Interest?: string;
  Capture: boolean;
  Authenticate?: boolean;
  Recurrent?: boolean;
  IsCryptoCurrencyNegotiation?: boolean;
}

export interface IPaymentRequest {
  Amount: number;
  Installments: number;
  Capture: boolean;
}

// Model for a Cielo payment response.
export interface ICieloPaymentResponse {
  ServiceTaxAmount: number;
  Installments: number;
  Interest: ECieloInteret;
  Capture: boolean;
  Authenticate: boolean;
  CreditCard: ICreditCard;
  CardOnFile: {
    Usage: string;
    Reason: string;
  };
  IsCryptoCurrencyNegotiation: boolean;
  tryautomaticcancellation: boolean;
  ProofOfSale: string;
  Tid: string;
  AuthorizationCode: string;
  SoftDescriptor: string;
  PaymentId: string;
  Type: ECieloPaymentType;
  Amount: number;
  CapturedAmount: number;
  Country: string;
  AirlineData: {
    TicketNumber: string;
  };
  ExtraDataCollection: any[];
  Status: number;
  ReturnCode: string;
  ReturnMessage: string;
  Links: [
    {
      Method: string;
      Rel: string;
      Href: string;
    }
  ];
}

// Model for credit card data.
export interface ICreditCard {
  CardNumber: string;
  Holder: string;
  ExpirationDate: string;
  SecurityCode: string;
  SaveCard: boolean;
  Brand: ECreditCardBrand;
  PaymentAccountReference?: string;
}

// Model for credit card data valitation result.
export interface ICreditCardValidationResult {
  CardFlag?: string;
  CardIcon?: string;
  CardToken?: string;
  Message?: string;
  MerchantOrderId?: string;
  PaymentData?: any;
  Valid: boolean;
}

export interface CardNumberOptions {
  maxLength?: number;
  luhnValidateUnionPay?: boolean;
}

export interface CardNumberVerification {
  isValid: boolean;
  isPotentiallyValid: boolean;
}

// Model for bin validation response.
export interface ICardBinResponse {
  Status: string;
  Provider: string;
  CardType: string;
  ForeignCard: boolean;
  CorporateCard: boolean;
  Issuer: string;
  IssuerCode: string;
  Prepaid: boolean;
}

// Model for card data update.
export interface ICreditCardUpdate {
  Payment: {
    Amount: string;
    Country: string;
    CreditCard: {
      Brand: string;
      CardNumber: string;
      ExpirationDate: string;
      Holder: string;
      SecurityCode: string;
    };
    Currency: string;
    Installments: number;
    SoftDescriptor: string;
    Type: string;
  };
  RecurrentPaymentId: string;
}

// Model for card token creation.
export interface ICreditCardTokenData {
  CustomerName: string;
  Brand: string;
  CardNumber: string;
  ExpirationDate: string;
  Holder: string;
}

// Model for recurrent payments.
export interface ICieloRecurrentPay {
  AuthorizeNow: boolean;
  EndDate?: string;
  StartDate?: string;
  Interval: string;
}

// Enumerator for credit card brands.
export enum ECreditCardBrand {
  Visa = 'Visa',
  Master = 'Master',
  Amex = 'Amex',
  Elo = 'Elo',
  Aura = 'Aura',
  JCB = 'JCB',
  Diners = 'Diners',
  Discover = 'Discover',
  Hipercard = 'Hipercard',
  Hiper = 'Hiper',
}

// Enumerator for payment types.
export enum ECieloPaymentType {
  CreditCard = 'CreditCard',
}

// Enumerator for payment interest values.
export enum ECieloInteret {
  ByMerchant = 'ByMerchant',
}

export enum ECieloCardOnFileUsage {
  First = 'First',
  Used = 'Used',
}

export enum ECieloCardOnFileReason {
  Recurring = 'Recurring',
  Unscheduled = 'Unscheduled',
  Installments = 'Installments',
}
