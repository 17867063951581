// Angular modules.
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, EBroadcast } from '@app/core/models';

// App interfaces.
import {} from '@app/core/models';

// App services.
import {
  AuthService,
  BroadcastService,
  ErrorService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [slideBottomAnimation],
})
export class ResetPasswordComponent implements OnInit {
  // Key.
  public key: string;

  // Form.
  public email: string;
  public password: string;
  public passwordConf: string;
  public errEmail: string;
  public errPassword: string;
  public errPasswordConf: string;

  // Element's ids.
  public idEmail: string = 'txt-email';
  public idPass: string = 'txt-password';
  public idPassConf: string = 'txt-password-conf';

  // Status.
  public isLoaded: boolean = false;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get operation key.
    this.route.paramMap.subscribe(params => {
      if (this.route.snapshot.queryParams.key) {
        this.key = this.route.snapshot.queryParams.key;
        this.isLoaded = true;
      } else {
        this.router.navigate(['remember-password']);
      }
    });
  }

  // Change user's password.
  public changePassword(): void {
    this.isSaving = true;

    if (this.validateForm()) {
      this.authService
        .saveNewPassword(this.email, this.key, this.password)
        .then(res => {
          this.utilsService.showAlert(
            'reset-pass',
            res.message,
            EAlertType.Success
          );
          if (res.count > 0) {
            this.router.navigate(['login']);
          }
        })
        .catch(err => {
          this.utilsService.showAlert(
            'reset-pass',
            this.errorService.manageError(
              err,
              this.constructor.name,
              'changePassword() > authService.saveNewPassword()'
            ),
            EAlertType.Error
          );
          if (err.error.count === -1) {
            this.router.navigate(['remember-password']);
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this.isSaving = false;
    }
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }

  // Redirect to home page.
  public goToHome(): void {
    window.location.href = environment.urls.site;
  }

  // Validate form.
  private validateForm(): boolean {
    let valid: boolean = true;

    // Clear all errors.
    this.clearErrors();

    // E-Mail.
    if (!this.email) {
      this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL');
      this.inputError(this.idEmail, true);
      if (valid) {
        document.getElementById(this.idEmail) &&
          document.getElementById(this.idEmail).focus();
      }
      valid = false;
    } else {
      if (!this.utilsService.validateEmail(this.email)) {
        this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL_INVALID');
        this.inputError(this.idEmail, true);
        if (valid) {
          document.getElementById(this.idEmail) &&
            document.getElementById(this.idEmail).focus();
        }
        valid = false;
      } else {
        this.inputError(this.idEmail, false);
      }
    }

    // Password.
    if (!this.password) {
      this.errPassword = this.translate.instant('LOGIN.ERRORS.PASSWORD');
      this.inputError(this.idPass, true);
      if (valid) {
        document.getElementById(this.idPass) &&
          document.getElementById(this.idPass).focus();
      }
      valid = false;
    }

    // Password config.
    if (!this.passwordConf) {
      this.errPasswordConf = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_CONFIRM_REQUIRED'
      );
      this.inputError(this.idPassConf, true);
      if (valid) {
        document.getElementById(this.idPassConf) &&
          document.getElementById(this.idPassConf).focus();
      }
      valid = false;
    }

    // Password match.
    if (
      this.password &&
      this.passwordConf &&
      this.password !== this.passwordConf
    ) {
      this.errPasswordConf = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_DOES_NOT_MATCH'
      );
      this.inputError(this.idPass, true);
      this.inputError(this.idPassConf, true);
      this.password = null;
      this.passwordConf = null;
      if (valid) {
        document.getElementById(this.idPass) &&
          document.getElementById(this.idPass).focus();
      }
      valid = false;
    }

    return valid;
  }

  // Clear login errors.
  private clearErrors(): void {
    this.errEmail = null;
    this.errPassword = null;
    this.errPasswordConf = null;

    this.inputError(this.idEmail, false);
    this.inputError(this.idPass, false);
    this.inputError(this.idPassConf, false);
  }

  // Toggle input error.
  private inputError(id: string, add: boolean = true): void {
    const errorClass: string = 'frm-error';
    const el: HTMLElement = document.getElementById(id);

    if (el) {
      if (add) {
        el.classList.add(errorClass);
      } else {
        el.classList.remove(errorClass);
      }
    }
  }
}
