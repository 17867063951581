<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <!-- Owners count. -->
  <div class="owners-count">
    <div *ngIf="!pet || pet.humans.length === 0">
      Este pet não possui tutores.
    </div>
    <div *ngIf="pet && pet.humans.length === 1">Este pet possui 1 tutor.</div>
    <div *ngIf="pet && pet.humans.length > 1">
      Este pet possui {{ pet.humans.length }} tutores.
    </div>
  </div>

  <!-- Owners list. -->
  <div *ngFor="let o of pet.humans; let i = index" class="owner-container">
    <!-- Picture. -->
    <div class="owner-avatar"><img src="{{ getHumanAvatar(o) }}" /></div>

    <!-- Data. -->
    <div class="owner-data">
      <p class="owner-name">{{ o.fullName }}</p>
      <p>{{ o.email }}</p>
      <p>{{ o.phone | mask: '(00) 00000-0000' }}</p>
    </div>
  </div>
</div>
