// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, EPetFamily } from '@app/core/models';

// App models.
import { IHumanLocalData, IPet, IPetFamily } from '@app/core';

// App services.
import {
  AuthService,
  ErrorService,
  PetService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-pet-register',
  templateUrl: './pet-register.component.html',
  styleUrls: ['./pet-register.component.scss'],
  animations: [modalAnimation],
})
export class PetRegisterComponent implements OnInit {
  // Outputs.
  @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Inputs.
  @Input() pet: IPet;

  // Human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Pet's data.
  public pageTitle: string;
  public isNewPet: boolean;

  // Form data.
  public step: number = 0;
  public totalSteps: number = 4;
  public activeFamily: number = 0;
  public activeGender: string = '0';
  public petFamilies: IPetFamily[] = [];

  // New avatar.
  public currentAvatar: string;
  public newAvatar: any;
  public newAvatarUrl: any;
  public newAvatarBtnText: string = this.translate.instant(
    'PETS.CHOOSE_PICTURE'
  );
  public avatarUpdated: boolean = false;

  // Image cropper.
  public imageChangedEvent: any = '';

  // Errors.
  public errAvatar: string;
  public errFamily: string;
  public errGender: string;
  public errName: string;
  public errYear: string;

  // Status.
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private petService: PetService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Build empty pet.
    this.buildNewPet();

    // Get pet families.
    this.getFamilies();

    // Set page title.
    // Pet's data.
    this.pageTitle = this.translate.instant(
      'PETS.' + (this.pet ? 'UPDATE' : 'NEW')
    );
    this.isNewPet = !this.pet || !this.pet.id ? true : false;
  }

  // Create/update pet.
  public savePet(): void {
    if (this.validateForm()) {
      this.isSaving = true;

      const f: number = parseInt(this.activeFamily.toString(), 10);
      this.pet.family = this.petFamilies.find(pf => pf.id === f);
      this.pet.gender = this.activeGender;
      this.pet.picture = null;
      this.petService
        .createPet(this.pet)
        .then(result => {
          if (result.rows.petId && this.newAvatar) {
            const petId: number = result.rows.petId;
            this.petService
              .updatePetAvatar(petId, this.newAvatar)
              .then(() => {})
              .catch(errAvatar => {
                console.error(errAvatar);
                this.utilsService.showAlert(
                  'pet-reg',
                  this.errorService.manageError(
                    errAvatar,
                    this.constructor.name,
                    'savePet() > petService.updateAvatar()'
                  ),
                  EAlertType.Error
                );
                this.isSaving = false;
              })
              .finally(() => {
                this.isSaving = false;
                this.closeModal(true);
              });
          } else {
            this.isSaving = false;
            this.closeModal(true);
          }
        })
        .catch(error => {
          console.error(error);
          this.utilsService.showAlert(
            'pet-reg',
            this.errorService.manageError(
              error,
              this.constructor.name,
              'savePet() > petService.createPet()'
            ),
            EAlertType.Error
          );
          this.isSaving = false;
        });
    } else {
      this.utilsService.showAlert(
        'pet-reg',
        'Há erros no formulário. Verifique os passos.',
        EAlertType.Error
      );
    }
  }

  // Previous step.
  public previousStep(): void {
    this.step = this.step > 0 ? --this.step : 0;
    this.newStep();
  }

  // Next step.
  public nextStep(): void {
    let canContine: boolean = true;

    switch (this.step) {
      case 0:
        if (!this.validateSpecies()) {
          canContine = false;
        }
        break;
      case 1:
        if (!this.validateGender()) {
          canContine = false;
        }
        break;
      case 2:
        if (!this.validateName()) {
          canContine = false;
        }
        break;
      case 3:
        if (!this.validateYear()) {
          canContine = false;
        }
        break;
    }

    if (canContine) {
      this.step = this.step < this.totalSteps ? ++this.step : this.totalSteps;
      this.newStep();
    }
  }

  // Get generic avatar.
  public getGenericAvatar(): string {
    let i: string;
    const cat: string = 'avatar_cat.png';
    const dog: string = 'avatar_dog.png';
    const af: number = parseInt(this.activeFamily.toString(), 10);

    if (af > 0) {
      this.petFamilies.map(f => {
        if (f.id === af) {
          i = f.name.toLowerCase() === 'gato' ? cat : dog;
        }
      });
    } else {
      i = dog;
    }

    return 'assets/images/avatar/' + (i || dog);
  }

  // Change avatar.
  public changePetAvatar(event?: any): void {
    if (event && event.target && event.target.files.length > 0) {
      this.errAvatar = null;
      this.newAvatar = event.target.files[0];

      if (this.newAvatar.type.match(/image\/*/) == null) {
        this.errAvatar = this.translate.instant(
          'PROFILE.ACCOUNT.NEW_PICTURE_INVALID'
        );
        this.newAvatar = null;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(this.newAvatar);
        reader.onload = ev => {
          // this.newAvatarUrl = reader.result;
          this.imageChangedEvent = event;
        };

        this.newAvatarBtnText = this.newAvatar.name;
      }
    }
  }

  // [CROPPER] When image is cropped.
  public imageCropped(event: ImageCroppedEvent): void {
    this.newAvatarUrl = event.base64;
    this.newAvatar = event;
  }

  // [CROPPER] When image is loaded.
  public imageLoaded(): void {}

  // [CROPPER] When cropper is ready.
  public cropperReady(): void {}

  // [CROPPER] On error.
  public loadImageFailed(): void {}

  public cropDone(): void {
    this.imageChangedEvent = null;
  }

  // Close modal.
  public closeModal(reload: boolean = false): void {
    this.finished.emit(reload);
  }

  // Cancel upload new avatar.
  public cancelNewAvatar(): void {
    this.errAvatar = null;
    this.newAvatar = null;
    this.newAvatarUrl = null;
    this.newAvatarBtnText = this.translate.instant('PETS.CHOOSE_PICTURE');
  }

  // Validate form.
  private validateForm(): boolean {
    this.clearErrors();
    return (
      this.validateSpecies() &&
      this.validateGender() &&
      this.validateName(true) &&
      this.validateYear(true)
    );
  }

  // Validate species.
  private validateSpecies(): boolean {
    this.errFamily = null;
    let isValid: boolean = true;
    if (this.activeFamily === 0) {
      this.errFamily = this.translate.instant('PETS.ERRORS.FAMILY');
      isValid = false;
    }
    return isValid;
  }

  // Validate gender.
  private validateGender(): boolean {
    this.errGender = null;
    let isValid: boolean = true;
    if (this.activeGender === '0') {
      this.errGender = this.translate.instant('PETS.ERRORS.GENDER');
      isValid = false;
    }
    return isValid;
  }

  // Validate name.
  private validateName(avoidFocus: boolean = false): boolean {
    this.errName = null;
    let isValid: boolean = true;
    if (!this.pet.name) {
      this.errName = this.translate.instant('PETS.ERRORS.NAME');
      isValid = false;
      if (!avoidFocus) {
        document.getElementById('pet-name') &&
          document.getElementById('pet-name').focus();
      }
    }
    return isValid;
  }

  // Validate year.
  private validateYear(avoidFocus: boolean = false): boolean {
    this.errYear = null;
    let isValid: boolean = true;

    if (this.pet.year) {
      const y: string = this.pet.year.toString();
      const n: Date = new Date();
      let isValidYear: boolean = true;

      if (y.length !== 4) {
        isValidYear = false;
      }
      if (isValidYear && isNaN(parseFloat(y))) {
        isValidYear = false;
      }
      if (isValidYear && parseInt(y, 10) > n.getFullYear()) {
        isValidYear = false;
      }
      if (isValidYear && parseInt(y, 10) < n.getFullYear() - 100) {
        isValidYear = false;
      }

      if (isValidYear) {
        const today: Date = new Date();
        const diff: number = today.getFullYear() - this.pet.year;
        const ageLimitCat: number = environment.pets.ageLimit.cat;
        const ageLimitDog: number = environment.pets.ageLimit.dog;
        const m: string =
          'Limite de idade excedido (' +
          ageLimitCat +
          ' anos para gatos e ' +
          ageLimitDog +
          ' anos para cães).';
        if (this.isCat() && diff > ageLimitCat) {
          this.errYear = m;
          isValidYear = false;
        } else if (this.isDog() && diff > ageLimitDog) {
          this.errYear = m;
          isValidYear = false;
        }
      }

      if (!isValidYear) {
        this.errYear =
          this.errYear || this.translate.instant('PETS.ERRORS.YEAR');
        isValid = false;
        if (!avoidFocus) {
          document.getElementById('pet-year') &&
            document.getElementById('pet-year').focus();
        }
      }
    } else {
      this.errYear = this.translate.instant('PETS.ERRORS.YEAR_MISSING');
      isValid = false;
    }

    return isValid;
  }

  // Get pet families.
  private getFamilies(): void {
    this.isLoading = true;
    this.petFamilies = [];

    this.petService
      .getFamilies()
      .then(res => {
        this.petFamilies = res.rows ? (res.rows as IPetFamily[]) : [];
      })
      .catch(err => {
        this.utilsService.showAlert(
          'pet-reg',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getFamilies() > petService.getFamilies()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => {
        this.isLoading = false;
        setTimeout(() => {
          document.getElementById('selFamily') &&
            document.getElementById('selFamily').focus();
        }, 500);
      });
  }

  // Check if pet is a cat.
  private isCat(): boolean {
    let cat: boolean = false;
    const fam: number = parseInt(this.activeFamily.toString(), 10);
    if (fam > 0) {
      this.petFamilies.map(f => {
        if (f.id === fam && f.code === 'Cat') {
          cat = true;
        }
      });
    }
    return cat;
  }

  // Check if pet is a dog.
  private isDog(): boolean {
    let dog: boolean = false;
    const fam: number = parseInt(this.activeFamily.toString(), 10);
    if (fam > 0) {
      this.petFamilies.map(f => {
        if (f.id === fam && f.code === 'Dog') {
          dog = true;
        }
      });
    }
    return dog;
  }

  // When a step is changed.
  private newStep(): void {
    switch (this.step) {
      case 0:
        setTimeout(() => {
          document.getElementById('selFamily') &&
            document.getElementById('selFamily').focus();
        }, 300);
        break;
      case 1:
        setTimeout(() => {
          document.getElementById('selGender') &&
            document.getElementById('selGender').focus();
        }, 300);
        break;
      case 2:
        setTimeout(() => {
          document.getElementById('pet-name') &&
            document.getElementById('pet-name').focus();
        }, 300);
        break;
      case 3:
        setTimeout(() => {
          document.getElementById('pet-year') &&
            document.getElementById('pet-year').focus();
        }, 300);
        break;
      case 4:
        break;
    }
  }

  // Build empty pet.
  private buildNewPet(): void {
    if (!this.pet) {
      this.pet = {
        createdAt: null,
        family: null,
        gender: null,
        name: null,
        id: null,
        picture: null,
        updatedAt: null,
        year: null,
      };
    }
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errAvatar = null;
    this.errFamily = null;
    this.errGender = null;
    this.errName = null;
    this.errYear = null;
  }
}
