// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// declare var require: any;
declare let require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-agreement-partners',
  templateUrl: './agreement-partners.component.html',
  styleUrls: ['./agreement-partners.component.scss'],
  animations: [slideBottomAnimation],
})
export class AgreementPartnersComponent implements OnInit {
  // Event emmiters.
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  // File.
  public fileName: string = 'Termo-adesao-clinicas-Petbee.pdf';
  public filePath: string =
    environment.urls.app + 'assets/docs/' + this.fileName;
  // public filePath: string = environment.urls.site + 'documents/' + this.fileName;

  // Status.
  public isLoaded: boolean = true;

  // Constructor method.
  constructor() {}

  // On init.
  public ngOnInit(): void {}

  // Download PDF file.
  public downloadFile(): void {
    FileSaver.saveAs(this.filePath, this.fileName);
  }

  // Close this modal.
  public closeWindow(event?: any): void {
    this.isLoaded = false;
    setTimeout(() => {
      this.closeModal.emit();
    }, 300);
  }
}
