import { IAddress } from './location.model';
import { EPaymentSystem } from './payment.model';

// Interface for Humans model.
export interface IHuman {
  addresses?: IHumanAddress[];
  blocked?: boolean;
  coupon?: string;
  createdAt: string;
  email: string;
  fullName: string;
  id?: number;
  invitedBy?: string;
  isAdmin?: boolean;
  password: string;
  phone: string;
  picture?: string;
  updatedAt?: string;
  document?: string;
}

// Interface for Humans model (not registered user).
export interface IHumanRegister {
  coupon?: string;
  createdAt?: string;
  email?: string;
  fullName?: string;
  invitedBy?: string;
  password?: string;
  passwordConfirm?: string;
  phone?: string;
}

// Interface for Humans-Addresses model.
export interface IHumanAddress {
  addressId: number;
  createdAt: string;
  id?: number;
  humanId: number;
  updatedAt?: string;
}

// Interface for human's login data.
export interface IHumanLogin {
  email: string;
  password: string;
}

// Interface for human's data stored locally.
export interface IHumanLocalData {
  coupon?: string;
  createdAt?: string;
  email: string;
  fullName: string;
  id: string;
  invitedBy?: string;
  isAdmin: boolean;
  location?: IHumanLocalLocation;
  name: string;
  phone?: string;
  picture?: string;
  token: string;
  document?: string;
}

// Interface for Google's account data.
export interface IGoogleData {
  email: string;
  id: string;
  imageUrl: string;
  name: string;
}

// Interface for temporary social login data.
export interface ISocialLoginData {
  id: string;
  type: EAuthType;
  name?: string;
  email?: string;
  picture?: string;
}

// Interface for local human's location.
export interface IHumanLocalLocation {
  city: string;
  cityId: number;
  country?: string;
  countryId?: number;
  state: string;
  stateId: number;
  stateName: string;
}

// Interface for queue register.
export interface IHumanQueue {
  city: string;
  createdAt?: number;
  id?: number;
  email: string;
  invite?: string;
  name: string;
  phone: string;
}

// Interface for payment method
export interface IHumanPaymentMethod {
  id?: number;
  paymentSystem: EPaymentSystem;
  cardNumber?: string;
  address?: IAddress;
}

// Model for login types.
export enum EAuthType {
  Email = 'Email',
  Facebook = 'Facebook',
  Google = 'Google',
}

// Model for active profile picture.
export enum EActivePicture {
  Facebook = 'Facebook',
  Google = 'Google',
  Petbee = 'Petbee',
}
