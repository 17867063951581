<section id="reg-form" *ngIf="isLoaded" class="register-container" [@slideDown]>
  <!-- Side column. -->
  <div class="register-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [FORM: START] -->
  <div class="register-content register-form">
    <!-- [CONTAINER: START] -->
    <div *ngIf="newHuman" class="form-container">
      <h1>Queremos conhecer mais sobre você!</h1>

      <p class="p-subtitle">Preencha seus dados abaixo:</p>

      <div class="register-form-container">
        <!-- Name. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-name"
            [(ngModel)]="newHuman.fullName"
            (blur)="validateForm()"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.NAME' | translate
          }}</span>
        </div>
        <span *ngIf="errName" class="spn-error">{{ errName }}</span>

        <!-- E-Mail. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="email"
            id="txt-email"
            [(ngModel)]="newHuman.email"
            (blur)="validateForm()"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.EMAIL' | translate
          }}</span>
        </div>
        <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

        <!-- Phone. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-phone"
            [(ngModel)]="newHuman.phone"
            mask="(00) 00000-0000"
            (blur)="validateForm()"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.PHONE' | translate
          }}</span>
        </div>
        <span *ngIf="errPhone" class="spn-error">{{ errPhone }}</span>

        <div class="two-columns">
          <!-- Password. -->
          <div>
            <div class="frm-element">
              <input
                class="frm-textbox"
                type="password"
                id="txt-password"
                [(ngModel)]="newHuman.password"
                (blur)="validateForm()"
                [disabled]="isSaving"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.PASSWORD' | translate
              }}</span>
            </div>
            <span *ngIf="errPassword" class="spn-error">{{ errPassword }}</span>
          </div>

          <!-- Confirm password. -->
          <div>
            <div class="frm-element">
              <input
                class="frm-textbox"
                type="password"
                id="txt-password-confirm"
                [(ngModel)]="newHuman.passwordConfirm"
                (blur)="validateForm()"
                [disabled]="isSaving"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.PASSWORD_CONFIRM' | translate
              }}</span>
            </div>
            <span *ngIf="errPasswordConfirm" class="spn-error">{{
              errPasswordConfirm
            }}</span>
          </div>
        </div>

        <div
          *ngIf="errPasswordMatch"
          class="frm-element"
          style="height: auto; text-align: center">
          <span class="spn-error">{{ errPasswordMatch }}</span>
        </div>
      </div>

      <p class="p-subtitle">Informe o seu endereço:</p>

      <div class="register-form-container">
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-zipcode"
            mask="00000-000"
            [(ngModel)]="newAddress.zipCode"
            (keyup)="typingPostalCode($event)"
            (blur)="validateForm()"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.ZIP_CODE' | translate
          }}</span>
          <span *ngIf="isLoadingPostalCode" class="spn-spin-zipcode">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
        </div>
        <span *ngIf="errAddrZipCode" class="spn-error">{{
          errAddrZipCode
        }}</span>

        <div class="grid-row" style="grid-template-columns: 70% 30%">
          <div>
            <div class="frm-element left-el">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-street"
                [(ngModel)]="newAddress.street"
                (blur)="validateForm()"
                [input]="newAddress.street"
                [disabled]="isSaving || !zipCodeFound"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.STREET' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrStreet" class="spn-error">{{
              errAddrStreet
            }}</span>
          </div>

          <div>
            <div class="frm-element">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-number"
                [(ngModel)]="newAddress.number"
                (blur)="validateForm()"
                [input]="newAddress.number"
                [disabled]="isSaving || !zipCodeFound"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.NUMBER' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrNumber" class="spn-error">{{
              errAddrNumber
            }}</span>
          </div>
        </div>

        <div class="grid-row">
          <div>
            <div class="frm-element left-el">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-complement"
                [(ngModel)]="newAddress.complement"
                (blur)="validateForm()"
                [input]="newAddress.complement"
                [disabled]="isSaving || !zipCodeFound"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.COMPLEMENT' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrComplement" class="spn-error">{{
              errAddrComplement
            }}</span>
          </div>

          <div>
            <div class="frm-element">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-neighborhood"
                [(ngModel)]="newAddress.neighborhood"
                (blur)="validateForm()"
                [input]="newAddress.neighborhood"
                [disabled]="isSaving || !zipCodeFound"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.NEIGHBORHOOD' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrNeighborhood" class="spn-error">{{
              errAddrNeighborhood
            }}</span>
          </div>
        </div>

        <div class="grid-row" style="grid-template-columns: 70% 30%">
          <div>
            <div class="frm-element left-el">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-city"
                [(ngModel)]="newAddress.city"
                (blur)="validateForm()"
                [input]="newAddress.city"
                [disabled]="!zipCodeFound || !newAddress.city"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.CITY' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrCity" class="spn-error">{{ errAddrCity }}</span>
          </div>

          <div>
            <div class="frm-element">
              <input
                class="frm-textbox"
                type="text"
                id="txt-addr-state"
                maxlength="2"
                [(ngModel)]="newAddress.state"
                (blur)="validateForm()"
                [input]="newAddress.state"
                [disabled]="!zipCodeFound || !newAddress.state"
                appInputs />
              <span class="frm-label">{{
                'REGISTER.ELEMENTS.ADDRESS.STATE' | translate
              }}</span>
            </div>
            <span *ngIf="errAddrState" class="spn-error">{{
              errAddrState
            }}</span>
          </div>
        </div>
      </div>

      <div class="accept-grid">
        <div class="ag-item">
          <div class="ag-chk" (click)="togglePolicy()">
            <input [(ngModel)]="chkPolicy" type="checkbox" />Aceito a
            <a (click)="toggleTermsWindow()">política de privacidade</a>
          </div>
        </div>
      </div>

      <!-- Save button. -->
      <div style="margin: 25px 0; text-align: center">
        <button
          type="button"
          class="btn btn-success btn-icon-label"
          (click)="createHuman()"
          [disabled]="isSaving">
          <span class="btn-inner--icon">
            <i class="fas fa-user-check"></i>
          </span>
          <span *ngIf="!isSaving" class="btn-inner--text">Criar conta</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <button
          class="btn btn-link btn-link-thin"
          (click)="exitPage($event, '/login')">
          {{ 'LOGIN.QUESTIONS.ALREADY_REGISTERED' | translate }}
          {{ 'LOGIN.ELEMENTS.DO_LOG_IN' | translate }}
        </button>
      </div>
    </div>
    <!-- [CONTAINER: END] -->
  </div>
  <!-- [FORM: END] -->

  <!-- Empty column. -->
  <div class="login-content lg-b"></div>
</section>

<!-- Alerts. -->
<app-alerts id="alert-reg-frm" style="display: none"></app-alerts>

<!-- Agreements modal. -->
<app-agreement
  *ngIf="isAgreementVisible"
  (closeModal)="toggleAgreementsWindow($event)"></app-agreement>

<!-- Terms of use modal. -->
<app-termsofuse
  *ngIf="isTermsVisible"
  (closeModal)="toggleTermsWindow($event)"></app-termsofuse>

<!-- Social login (new account alert). -->
<section
  *ngIf="sld && sld.type"
  id="modal-socialLogin"
  class="modal-socialLogin hide"
  [@slideDown]>
  <div class="container">
    <p>Olá,</p>

    <p>
      Você optou por fazer <strong>login</strong> utilizando sua conta do
      <strong>{{ sld.type }}</strong
      >, mas no seu primeiro acesso precisaremos de mais algumas informações
      para completar o cadastro.
    </p>

    <p>
      Não se preocupe! Nas próximas vezes você poderá se identificar com apenas
      um clique.
    </p>

    <p>
      Aproveite para definir uma senha, caso não seja possível utilizar o login
      via {{ sld.type }} no futuro.
    </p>

    <button
      class="btn btn-link btn-link-thin"
      (click)="toggleSocialInfoModal(false)">
      Endendi
    </button>
  </div>
</section>
