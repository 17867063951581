// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss'],
  animations: [slideBottomAnimation],
})
export class TermsofuseComponent implements OnInit {
  // Event emmiters.
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  // Status.
  public isLoaded: boolean = true;

  // Constructor method.
  constructor() {}

  // On init.
  public ngOnInit(): void {}

  // Close this modal.
  public closeWindow(event?: any): void {
    this.isLoaded = false;
    setTimeout(() => {
      this.closeModal.emit();
    }, 300);
  }
}
