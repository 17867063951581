// Angular modules.
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Environment.
import { environment } from '@env/environment';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  // Constructor method.
  constructor(private router: Router, private translate: TranslateService) {}

  // Manage an error returned from API.
  public manageError(error: any, component?: string, method?: string): string {
    // Log into console.
    this.consoleLog(error, component, method);

    //  error.
    // if (error.status === 401 && error.statusText === 'Unauthorized') {
    if (error.status === 401) {
      if (this.router.url !== '/login') {
        localStorage.setItem('last-path', this.router.url);
        localStorage.setItem('refresh-login', 'true');
      }
      this.router.navigate(['login']);
      return this.translate.instant('GENERIC.AUTH_REQUIRED');
    } else {
      return this.getError(error);
    }
  }

  // Get error message.
  public getError(e?: any): string {
    let msg: string = 'Ocorreu um erro na operação.';

    if (e) {
      if (e.error) {
        if (e.error.error) {
          msg = e.error.error.message || e.error.error;
        } else {
          msg = e.error.message || e.error;
        }
      } else if (e.message) {
        msg = e.message;
      } else if (typeof e === 'string' || e instanceof String) {
        msg = e.toString();
      }
    }

    return msg;
  }

  // Log into console.
  private consoleLog(error: any, component?: string, method?: string): void {
    if (environment.consoleLog) {
      console.error(error);
    }
  }
}
