import { IClinic } from './clinic.model';
import { IPet, IPetFamily } from './pet.model';

// Interface for procedures model.
export interface IProcedure {
  blocked?: boolean;
  code: string;
  countryId: number;
  createdAt: string;
  id?: number;
  name: string;
  petProcedures?: number;
  updatedAt?: string;
  description: string | null;
  type: EProceduretType | null;
  limitType: EProceduretLimitType | null;
  limitPeriodAmount: number | null;
  limitPeriodUnit: EProceduretLimitPeriod | null;
  limitPeriodStatus: boolean | null;
  gracePeriodAmount: number | null;
  gracePeriodUnit: EProcedureGracePeriodUnit | null;
  gracePeriodStatus: boolean | null;
  petTypes: IPetFamily[] | null;
}

// Interface for treatments request view.
export interface IProceduresRequest {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: IProceduresRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  items: IProceduresItem[];
}

export interface IProceduresRequestAdminView {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: IProceduresRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  pet: IPet;
  clinic: IClinic;
  items: IProceduresItem[];
}

// Iterface for updating procedures requests
export interface IProceduresRequestView {
  id: number;
  code: string;
  clinicId: number;
  petId: number;
  status: IProceduresRequestStatus;
  refuseNotes: string | null;
  createdAt: string;
  decisionAt: string;
  updatedAt: string;
  deletedAt: string;
  pet?: IPet;
}

// Interface for treatments request view.
export interface IProceduresRequestUpdate {
  id: number;
  code: string;
  statusId: number;
  refuseNotes: string | null;
  decisionAt: string;
  deletedAt: string;
}

// Iterface for new procedures requests
export interface INewProceduresRequest {
  clinicId: number;
  petId: number;
}

// Interface for procedures requests item model.
export interface IProceduresItem {
  id: number;
  requestId: number;
  code: string;
  cost: number;
  description: string;
  observations: string;
  statusId: number;
  procedureId: number;
  createdAt: string;
  updatedAt: Date | null;
  deletedAt: Date | null;
  refuseNotes: string | null;
  fiscalNote: string | null;
}

// Interface for new procedures requests item model.
export interface INewProceduresItem {
  requestId: number;
  cost: number;
  description: string;
  observations: string;
  procedureId: number;
}

// Interface for update procedures requests item model.
export interface IUpdateProceduresItem {
  id: number;
  cost: number;
  description: string;
  observations: string;
  statusId?: number;
  updatedAt?: string | null;
  deletedAt?: string | null;
  refuseNotes?: string | null;
  fiscalNote?: string | null;
}

// Interface for update procedures requests item model.
export interface ICancelProceduresItem {
  id: number;
}

// Interface for procedure requets status model.
export interface IProceduresRequestStatus {
  code: EProcedureRequestStatusCode;
  createdAt: string;
  id?: number;
  updatedAt?: string;
}

export type ProcedureStatusMap = {
  [K in EProcedureRequestStatusCode]: number;
};

export enum EProcedureRequestStatusCode {
  pending = 'pending',
  approved = 'approved',
  completed = 'completed',
  refused = 'refused',
  canceled = 'canceled',
}

export enum EProceduretType {
  appointment = 'appointment',
  exams = 'exams',
  vaccines = 'vaccines',
}

export enum EProceduretLimitType {
  limited = 'limited',
  unlimited = 'unlimited',
}

export enum EProceduretLimitPeriod {
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum EProcedureGracePeriodUnit {
  day = 'day',
  month = 'month',
  year = 'year',
}
