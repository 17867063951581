<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <div *ngIf="!isEditing">
    <div *ngIf="pet.updatedAt" class="last-update">
      {{ 'GENERIC.LAST_UPDATE' | translate }}:
      {{ pet.updatedAt | date : 'dd/MM/yyyy hh:mm' }}
    </div>

    <div class="row">
      <!-- Species. -->
      <div class="frm-element frm-el-inline">
        <label>{{ 'PETS.SPECIE' | translate }}:</label>
        <div class="div-item">{{ petFamily || '---' }}</div>
      </div>

      <!-- Gender. -->
      <div class="frm-element frm-el-inline">
        <label>{{ 'PETS.GENDER' | translate }}:</label>
        <div *ngIf="pet.gender === 'F'" class="div-item">
          {{ 'GENERIC.GENDERS.FEMALE' | translate }}
        </div>
        <div *ngIf="pet.gender === 'M'" class="div-item">
          {{ 'GENERIC.GENDERS.MALE' | translate }}
        </div>
      </div>

      <!-- Chip. -->
      <div class="frm-element frm-el-inline">
        <label>{{ 'PETS.CHIP' | translate }}:</label>
        <div class="div-item">
          {{ chipNumber }}
          <i class="fas fa-plus i-edit-chip" (click)="editChip()"></i>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Pet's name. -->
      <div class="frm-element frm-el-inline">
        <label>{{ 'PETS.ATTRIBUTES.NAME' | translate }}</label>
        <div content="notranslate" class="div-item notranslate">
          {{ pet.name }}
        </div>
      </div>

      <!-- Year. -->
      <div class="frm-element frm-el-inline">
        <label>{{ 'PETS.ATTRIBUTES.BIRTHDAY' | translate }}</label>
        <div class="div-item">
          {{ pet.birthday ? (pet.birthday | date : 'dd/MM/yyyy') : '---' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isEditing">
    <!-- Identification Number value. -->
    <div class="frm-element">
      <div>
        <input
          id="pet-chip"
          class="frm-textbox"
          [ngClass]="{ 'el-has-value': pet.chip }"
          type="text"
          [(ngModel)]="pet.chip"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{ 'PETS.CHIP' | translate }}</span>
      </div>
    </div>

    <div class="row">
      <!-- Cancel button. -->
      <div style="float: left; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-secondary btn-icon-label"
          (click)="editChip()"
          [disabled]="isSaving">
          <span class="btn-inner--icon"><i class="fas fa-angle-left"></i></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.CANCEL' | translate
          }}</span>
        </button>
      </div>

      <!-- Save button. -->
      <div style="float: right; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-success btn-icon-label"
          (click)="saveChip()"
          [disabled]="isSaving">
          <span class="btn-inner--icon"
            ><i class="fas fa-check-circle"></i
          ></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.SAVE' | translate
          }}</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Loader. -->
<div *ngIf="isLoading" style="margin: 30px 0">
  <app-horizontal-loader></app-horizontal-loader>
</div>
