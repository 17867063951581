// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App enumerators.
import {
  EPetFamily,
  EProcedureGracePeriodUnit,
  ETreatmentGracePeriodUnit,
  IProcedure,
  ITreatment,
} from '@app/core/models';

// App interfaces.
import { IAPIResponse, IPet, IPetFamily, IPetHuman } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';

// Main endpoint.
const endpoint: string = environment.urls.api;
const humansEndpoint: string = endpoint + 'humans/';
const petsEndpoint: string = endpoint + 'pets/';
const petFamiliesEndpoint: string = endpoint + 'pet-families/';
const petHumansEndpoint: string = endpoint + 'pets-humans/';

@Injectable({ providedIn: 'root' })
export class PetService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Pet families.
  public families: IPetFamily[] = [];

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    // Get families.
    this.getFamilies().then(res => {
      this.families = res.rows as IPetFamily[];
    });
  }

  // Get all pets.
  public getAllPets(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(petsEndpoint, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  public getAllPetsFiltered(
    params: Record<string, any>
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(petsEndpoint + 'filter/', {
          headers: this.headers,
          params,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get human's pets.
  public getMyPets(humanId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<any>(petHumansEndpoint + 'human/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          result => {
            if (result.rows && result.rows.length > 0) {
              const arIds: number[] = [];
              result.rows.map(i => arIds.push(i.petId));
              this.http
                .post<any>(
                  petsEndpoint + 'multiple/',
                  { arIds },
                  { headers: this.headers }
                )
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get pet by its id.
  public getById(petId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(petsEndpoint + petId, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get pet by its identification number.
  public getByChip(chip: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(petsEndpoint + 'microchip/' + chip, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get pet families.
  public getFamilies(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<any>(petFamiliesEndpoint, { headers: this.headers })
        .subscribe(
          result => {
            this.families = result.rows as IPetFamily[];
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Create new pet.
  public createPet(newPet: IPet): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<any>(petsEndpoint, newPet, { headers: this.headers })
        .subscribe(
          result => {
            const now = new Date();
            const createdAtUtc = new Date(
              now.getUTCFullYear(),
              now.getUTCMonth(),
              now.getUTCDate(),
              now.getUTCHours(),
              now.getUTCMinutes(),
              now.getUTCSeconds()
            );

            if (result.rows && result.rows.id) {
              const ph: IPetHuman = {
                createdAt: createdAtUtc.toISOString(),
                humanId: parseInt(this.authService.getUserId(), 10),
                petId: result.rows.id,
              };
              this.http
                .post<any>(petHumansEndpoint, ph, { headers: this.headers })
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Update a pet.
  public updatePet(pet: IPet): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<any>(petsEndpoint + pet.id, pet, { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Delete a pet.
  public deletePet(pet: IPet): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<any>(petsEndpoint + pet.id, { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get pet's avatar.
  public getAvatar(pet: IPet): string {
    let avatar: string = environment.pets.genericDogAvatar;

    if (pet.picture) {
      avatar = environment.pets.uploadPath + '/pets/' + pet.picture;
    } else {
      this.families.map(f => {
        if (f.id === pet.familyId) {
          if (f.code === EPetFamily.Dog) {
            avatar = environment.pets.genericDogAvatar;
          } else {
            avatar = environment.pets.genericCatAvatar;
          }
        }
      });
    }

    return avatar;
  }

  // Get generic avatar.
  public getGenericAvatar(pet: IPet): string {
    let avatar: string = environment.pets.genericDogAvatar;

    this.families.map(f => {
      if (f.id === pet.familyId) {
        if (f.code === EPetFamily.Dog) {
          avatar = environment.pets.genericDogAvatar;
        } else {
          avatar = environment.pets.genericCatAvatar;
        }
      }
    });

    return avatar;
  }

  // Update pet's avatar.
  /*public updateAvatar(
    petId: number,
    file: File,
    isAdmin: boolean = false
  ): Promise<IAPIResponse> {

    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {

      const head: HttpHeaders = this.authService.getUploadHttpHeaders();

      let name: string = file.name;
      const n: string[] = name.split('.');
      if (n[n.length - 1] === 'jpg') {
        name = petId + '.jpeg';
      }

      const formData: FormData = new FormData();
      // formData.append('petPicture', file);
      formData.append('petPicture', file, name);
      // formData.append('petPicture', file, file.name);

      this.http.put<any>(petsEndpoint + petId, formData, { headers: head }).subscribe(
        result => resolve(result),
        error => reject(error)
      );

    });

    return promise;

  }*/

  // Update pet's avatar.
  public updatePetAvatar(petId: number, file: any): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const head: HttpHeaders = this.authService.getUploadHttpHeaders();

      const name: string = petId + '.png';
      const f: any = this.DataURIToBlob(file.base64);
      const formData: FormData = new FormData();
      formData.append('petPicture', f, name);

      this.http
        .put<any>(petsEndpoint + petId, formData, { headers: head })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get pet's owners.
  public getOwners(petId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<any>(petHumansEndpoint + 'pet/' + petId, { headers: this.headers })
        .subscribe(
          result => {
            if (result.rows && result.rows.length > 0) {
              const arIds: number[] = [];
              result.rows.map(i => arIds.push(i.humanId));
              this.http
                .post<any>(
                  humansEndpoint + 'multiple/',
                  { arIds },
                  { headers: this.headers }
                )
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Create new owner.
  public createOwner(ph: IPetHuman): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<any>(petHumansEndpoint, ph, { headers: this.headers })
        .subscribe(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get pet status name.
  public getPetStatus(
    subscription: IPet['subscription'],
    isHuman: boolean
  ): string {
    let status: string = this.translate.instant(
      'PETS.SUBSCRIPTION_STATUS.GET_PLAN'
    );

    if (isHuman) {
      if (subscription && subscription.id && !subscription.finished) {
        if (subscription.blocked) {
          status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.BLOCKED');
        } else if (subscription.canceledAt) {
          status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.CANCELED');
        } else {
          status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.PROTECTED');
        }
      } else {
        status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.UNPROTECTED');
      }

      return status;
    }

    if (subscription && subscription.id && !subscription.finished) {
      if (subscription.blocked) {
        status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.BLOCKED');
      } else if (subscription.canceledAt) {
        status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.CANCELED');
      } else {
        const gracePeriod = this.getPetGracePeriod(subscription);
        if (gracePeriod.gracePeriodStatus) {
          switch (gracePeriod.gracePeriodType) {
            case 'full':
              status = this.translate.instant(
                'PETS.SUBSCRIPTION_STATUS.FULL_GRACE_PERIOD'
              );
              break;
            case 'partial':
            case 'partial-procedures':
              status = this.translate.instant(
                'PETS.SUBSCRIPTION_STATUS.PARTIAL_GRACE_PERIOD'
              );
              break;
          }
        } else {
          status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.PROTECTED');
        }
      }
    }

    return status;
  }

  // Get pet status name.
  public getPetStatusProcedures(
    subscription: IPet['subscription'],
    isHuman: boolean
  ): string {
    let status: string = this.translate.instant(
      'PETS.SUBSCRIPTION_STATUS.GET_PLAN'
    );

    if (subscription && subscription.id && !subscription.finished) {
      if (subscription.blocked) {
        status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.BLOCKED');
      } else if (subscription.canceledAt) {
        status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.CANCELED');
      } else {
        const gracePeriod = this.getPetGracePeriod(subscription);
        if (gracePeriod.gracePeriodStatus && gracePeriod.totalDays <= 45) {
          status = this.translate.instant(
            'PETS.SUBSCRIPTION_STATUS.FULL_GRACE_PERIOD'
          );
        } else {
          status = this.translate.instant('PETS.SUBSCRIPTION_STATUS.PROTECTED');
        }
      }
    }

    return status;
  }

  // Get pet status name.
  public getSubscriptionStatus(subscription: IPet['subscription']): boolean {
    if (subscription && subscription.id) {
      if (subscription.blocked || subscription.canceledAt) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  // Get subscription status class (CSS related).
  public getPetStatusClass(subscription: IPet['subscription']): string {
    let statusClass: string = 'unprotected';

    if (subscription && subscription.id && !subscription.finished) {
      if (subscription.blocked) {
        statusClass = 'blocked';
      } else if (subscription.canceledAt) {
        statusClass = 'subs-canceled';
      } else {
        statusClass = null;
      }
    } else {
      if (subscription) {
        statusClass = 'blocked';
      } else {
        statusClass = 'unprotected';
      }
    }

    return statusClass;
  }

  // Get pet grace period and css classes.
  public getPetGracePeriod(subscription: IPet['subscription']) {
    const startDate = new Date(subscription.createdAt);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - startDate.getTime());
    const diffDays = Math.trunc(timeDiff / (1000 * 3600 * 24));
    let gracePeriodStatus = false;
    let gracePeriodType: string = null;
    let gracePeriodClass = 'no-grace-period';
    let daysLeft = 0;

    if (diffDays <= 45) {
      daysLeft = 45 - diffDays;
      gracePeriodStatus = true;
      gracePeriodType = 'full';
      gracePeriodClass = 'full-grace-period';
    } else if (diffDays > 45 && diffDays <= 60) {
      daysLeft = 60 - diffDays;
      gracePeriodStatus = true;
      gracePeriodType = 'partial';
      gracePeriodClass = 'partial-grace-period';
    } else if (diffDays > 60 && diffDays <= 120) {
      daysLeft = 120 - diffDays;
      gracePeriodStatus = true;
      gracePeriodType = 'partial-procedures';
      gracePeriodClass = 'partial-grace-period';
    } else {
      gracePeriodStatus = false;
      gracePeriodClass = 'no-grace-period';
    }

    return {
      totalDays: diffDays,
      daysLeft,
      gracePeriodStatus,
      gracePeriodType,
      gracePeriodClass,
    };
  }

  // Get pet grace period and css classes for procedures.
  public getPetProceduresGracePeriod(subscription: IPet['subscription']) {
    const startDate = new Date(subscription.createdAt);
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let gracePeriodStatus = false;
    let gracePeriodType: string = null;
    let gracePeriodClass = 'no-grace-period';
    let daysLeft = 0;

    if (diffDays < 45) {
      daysLeft = 45 - diffDays;
      gracePeriodStatus = true;
      gracePeriodType = 'full';
      gracePeriodClass = 'full-grace-period';
    } else {
      gracePeriodStatus = false;
      gracePeriodClass = 'no-grace-period';
    }

    return {
      totalDays: diffDays,
      daysLeft,
      gracePeriodStatus,
      gracePeriodType,
      gracePeriodClass,
    };
  }

  public mapTreatmentGracePeriodStatus = (
    treatments: ITreatment[],
    startDate: Date
  ): ITreatment[] => {
    const today = new Date();

    return treatments.map(treatment => {
      const gracePeriodDate = new Date(startDate);
      const gracePeriodAmount = treatment.gracePeriodAmount;
      const gracePeriodUnit = treatment.gracePeriodUnit;

      if (gracePeriodUnit === ETreatmentGracePeriodUnit.day) {
        gracePeriodDate.setDate(gracePeriodDate.getDate() + gracePeriodAmount);

        treatment.gracePeriodStatus =
          today.getTime() <= gracePeriodDate.getTime();
      } else if (gracePeriodUnit === ETreatmentGracePeriodUnit.month) {
        gracePeriodDate.setMonth(
          gracePeriodDate.getMonth() + gracePeriodAmount
        );

        treatment.gracePeriodStatus =
          today.getTime() <= gracePeriodDate.getTime();
      } else if (gracePeriodUnit === ETreatmentGracePeriodUnit.year) {
        gracePeriodDate.setFullYear(
          gracePeriodDate.getFullYear() + gracePeriodAmount
        );

        treatment.gracePeriodStatus =
          today.getTime() <= gracePeriodDate.getTime();
      }

      return treatment;
    });
  };

  // Get pet status name.
  public getSubscriptionStartDate(subscription: IPet['subscription']): string {
    if (subscription && subscription.id) {
      if (subscription.startDate === null) return '---';

      const startDate = new Date(subscription.startDate);

      return startDate.toLocaleDateString('pt-BR');
    } else {
      return '---';
    }
  }

  // Get pet subscription start date.
  public getSubscriptionCreateDate(subscription: IPet['subscription']): string {
    if (subscription && subscription.id) {
      const createdDate = new Date(subscription.createdAt);
      return createdDate.toLocaleDateString('pt-BR');
    } else {
      return '---';
    }
  }

  // Get pet status name.
  public getSubscriptionDeletedDate(
    subscription: IPet['subscription']
  ): string {
    if (subscription && subscription.id && subscription.canceledAt) {
      const createdDate = new Date(subscription.canceledAt);
      return createdDate.toLocaleDateString('pt-BR');
    } else {
      return '---';
    }
  }

  // Update pet's avatar.
  /*public updateAvatarFromCamera(
    formData: FormData,
    petId: number,
    isAdmin: boolean = false
  ): Promise<IAPIResponse> {

    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {

      const head: HttpHeaders = this.authService.getUploadHttpHeaders();

      this.http.put<any>(petsEndpoint + petId, formData, { headers: head }).subscribe(
        result => resolve(result),
        error => reject(error)
      );

    });

    return promise;

  }*/

  // Convert Base64 image to Blob.
  private DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
}
