// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { PartnersComponent } from '@app/partners/partners.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: PartnersComponent,
    data: {
      title: 'Petbee - Cadastro de parceiros',
      description:
        'Página dedicada ao cadastro de clínicas e laboratórios parceiros da Petbee.',
      ogUrl: 'http:/www.petbee.com.br',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PartnersRoutingModule {}
