// Interface for report types.
export interface IReportType {
  title: string;
  type: EReportTypes;
}

// Enumerator for report types.
export enum EReportTypes {
  Payment = 'Payment',
}
