// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App models.
import { IAddressRegister, IAPIResponse, IAddress } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';
import { LocationService } from '@app/core/services/location/location.service';

// Main endpoint.
const endpoint: string = environment.urls.api + 'addresses/';
const clinicsAddressesEndpoint: string =
  environment.urls.api + 'clinics-addresses/';
const humansAddressesEndpoint: string =
  environment.urls.api + 'humans-addresses/';

@Injectable({ providedIn: 'root' })
export class AddressService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient,
    private locationService: LocationService
  ) {}

  // Create a new address.
  public createNewAddress(newAddress: IAddressRegister): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.locationService
        .getCity('BR', newAddress.city, newAddress.state)
        .then(result => {
          const now = new Date();
          const createdAtUtc = new Date(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds()
          );

          const address: IAddress = {
            address: newAddress.street,
            cityId: result.rows[0].id,
            complement: newAddress.complement || null,
            createdAt: createdAtUtc.toISOString(),
            id: null,
            neighborhood: newAddress.neighborhood,
            // eslint-disable-next-line id-blacklist
            number: newAddress.number || null,
            updatedAt: null,
            zipCode: newAddress.zipCode,
          };

          this.http
            .post<IAPIResponse>(endpoint, JSON.stringify(address), {
              headers: this.headers,
            })
            .subscribe(
              res => {
                resolve(res);
              },
              err => {
                reject(err);
              }
            );
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }

  // Associate a address to an human.
  public createHumanAddress(
    humanId: number,
    addressId: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          humansAddressesEndpoint,
          JSON.stringify({ humanId, addressId }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Update an address.
  public updateAddress(
    id: number,
    address: IAddressRegister
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(endpoint + id, JSON.stringify(address), {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get address by it's ID.
  public getById(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + id, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic address.
  public getClinicAddress(clinicId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(clinicsAddressesEndpoint + 'clinic/' + clinicId, {
          headers: this.headers,
        })
        .subscribe(
          result => {
            if (result.rows && result.rows.length > 0) {
              this.http
                .get<IAPIResponse>(endpoint + result.rows[0].addressId, {
                  headers: this.headers,
                })
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              reject(null);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Associate an address to a clinic.
  public createClinicAddress(
    clinicId: number,
    addressId: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          clinicsAddressesEndpoint,
          JSON.stringify({ clinicId, addressId }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }
}
