// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { ResetPasswordComponent } from '@app/reset-password/reset-password.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: ResetPasswordComponent,
    data: {
      title: 'Petbee - Lembrar senha de usuário',
      description: 'Esqueceu sua senha? Saiba como recuperá-la.',
      ogUrl: 'http:/www.petbee.com.br',
    },
  },
  {
    path: '**',
    loadChildren: () =>
      import('@app/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResetPasswordRoutingModule {}
