// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// App environment.
import { environment } from '@env/environment';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, EBroadcast } from '@app/core/models';

// App interfaces.
import { IAlert, INewClinicRegister } from '@app/core/models';

// App services.
import {
  BroadcastService,
  ClinicsService,
  ErrorService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  animations: [slideBottomAnimation],
})
export class PartnersComponent implements OnInit {
  // Output.
  @Output() redirectToMain: EventEmitter<any> = new EventEmitter<any>();

  // Form.
  public newPartner: INewClinicRegister;

  // Contract, terms and policy.
  public chkContract: boolean = false;
  public chkPolicy: boolean = false;

  // Errors.
  public errAddrCity: string;
  public errAddrState: string;
  public errContactName: string;
  public errEmail: string;
  public errName: string;
  public errPhone: string;

  // Modals.
  public isAgreementVisible: boolean = false;
  public isTermsVisible: boolean = false;

  // Status.
  public isLoaded: boolean = false;
  public isSaving: boolean = false;
  public saved: boolean = false;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private clinicsService: ClinicsService,
    private errorService: ErrorService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/partners'
      );
      this.isLoaded = true;
      setTimeout(() => {
        this.buildNewFormData();
      }, 500);
    }, 1500);
  }

  // Create new partner.
  public createPartner(): void {
    if (this.validateForm(true)) {
      if (this.chkContract && this.chkPolicy) {
        this.isSaving = true;
        this.saved = false;

        this.clinicsService
          .createPartner(this.newPartner)
          .then(res => {
            this.utilsService.showAlert(
              'alert-reg-frm',
              this.translate.instant('REGISTER.MESSAGES.ACCOUNT_CREATED'),
              EAlertType.Success
            );
            this.saved = true;
          })
          .catch(err => {
            console.error(err);
            this.utilsService.showAlert(
              'alert-reg-frm',
              this.errorService.manageError(
                err,
                this.constructor.name,
                'createPartner() > clinicsService.createPartner()'
              ),
              EAlertType.Error
            );
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.utilsService.showAlert(
          'alert-reg-frm',
          'Você deve aceitar o contrato e a política de privacidade.',
          EAlertType.Error
        );
      }
    }
  }

  // Check/uncheck contract field.
  public toggleContract(): void {
    this.chkContract = !this.chkContract;
  }

  // Check/uncheck policy field.
  public togglePolicy(): void {
    this.chkPolicy = !this.chkPolicy;
  }

  // Show/hide agreements modal.
  public toggleAgreementsWindow(event?: any): void {
    this.isAgreementVisible = !this.isAgreementVisible;
  }

  // Show/hide terms of use modal.
  public toggleTermsWindow(event?: any): void {
    this.isTermsVisible = !this.isTermsVisible;
  }

  // Return to main page.
  public returnToMainPage(): void {
    this.redirectToMain.emit();
  }

  // Redirect to home page.
  public goToHome(): void {
    window.location.href = environment.urls.site;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }

  // Validate form.
  public validateForm(force: boolean = false): boolean {
    let isValid: boolean = true;
    const nh: INewClinicRegister = this.newPartner;

    // Clear all errors.
    this.clearFormErrors();

    // Validate human's data.
    if (!nh.email && (force || this.hasTouched('txt-email'))) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_REQUIRED');
      isValid = false;
    } else if (
      nh.email &&
      !this.errEmail &&
      !this.utilsService.validateEmail(nh.email)
    ) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_INVALID');
      isValid = false;
    }
    if (!nh.name && (force || this.hasTouched('txt-name'))) {
      this.errName = this.translate.instant('REGISTER.ERRORS.NAME_REQUIRED');
      isValid = false;
    }
    if (!nh.contactName && (force || this.hasTouched('txt-contact-name'))) {
      this.errContactName = this.translate.instant(
        'REGISTER.ERRORS.NAME_REQUIRED'
      );
      isValid = false;
    }
    if (!nh.phone && (force || this.hasTouched('txt-phone'))) {
      this.errPhone = this.translate.instant('REGISTER.ERRORS.PHONE_REQUIRED');
      isValid = false;
    }
    if (!nh.phone && (force || this.hasTouched('txt-phone'))) {
      this.errPhone = this.translate.instant('REGISTER.ERRORS.PHONE_REQUIRED');
      isValid = false;
    }
    if (!nh.city && (force || this.hasTouched('txt-addr-city'))) {
      this.errAddrCity = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_CITY_REQUIRED'
      );
      isValid = false;
    }
    if (!nh.state && (force || this.hasTouched('txt-addr-state'))) {
      this.errAddrState = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_STATE_REQUIRED'
      );
      isValid = false;
    }

    return isValid;
  }

  // Check if an element was touched.
  private hasTouched(elementId: string): boolean {
    let touched: boolean = false;

    const el: HTMLElement = document.getElementById(elementId);
    if (el && el.classList.contains('ng-touched')) {
      touched = true;
    }

    return touched;
  }

  // Clear all errors.
  private clearFormErrors(): void {
    this.errAddrCity = null;
    this.errAddrState = null;
    this.errContactName = null;
    this.errEmail = null;
    this.errName = null;
    this.errPhone = null;
  }

  // Build newHuman and newAddress object.
  private buildNewFormData(): void {
    this.newPartner = {
      city: null,
      contactName: null,
      email: null,
      name: null,
      phone: null,
      state: null,
    };

    setTimeout(() => {
      document.getElementById('txt-name') &&
        document.getElementById('txt-name').focus();
    }, 300);
  }
}
