<section
  id="availability"
  *ngIf="isLoaded"
  class="availability-container"
  [@slideDown]>
  <!-- Side column. -->
  <div class="availability-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div *ngIf="!saved" class="availability-content availability-form">
    <div class="form-container" [@slideDown]>
      <!-- Logo (mobile only). -->
      <img
        class="img-logo mob-logo"
        src="assets/images/petbee.png"
        (click)="goToHome()" />

      <h1 style="font-style: italic">
        {{ 'REGISTER.OPERATIONS.CREATE_ACCOUNT' | translate }}
      </h1>

      <p class="p-small">
        No momento estamos efetuando cadastros apenas com convites. Em breve
        disponibilizaremos o cadastro para o público em geral.
      </p>

      <p class="p-small">
        Se você quiser pode preencher os dados abaixo e entrar na fila para
        receber um convite. Periodicamente liberamos alguns para pessoas
        pré-cadastradas.
      </p>

      <hr class="divider divider-fade" />

      <!-- [FORM: START] -->
      <div>
        <!-- Name. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-name"
            [(ngModel)]="newName"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.NAME' | translate
          }}</span>
        </div>
        <span *ngIf="errName" class="spn-error">{{ errName }}</span>

        <!-- E-Mail. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="email"
            id="txt-email"
            [(ngModel)]="newEmail"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.EMAIL' | translate
          }}</span>
        </div>
        <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

        <!-- Phone. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-phone"
            [(ngModel)]="newPhone"
            mask="(00) 00000-0000"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.PHONE' | translate
          }}</span>
        </div>
        <span *ngIf="errPhone" class="spn-error">{{ errPhone }}</span>

        <!-- City. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-city"
            [(ngModel)]="newCity"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.ADDRESS.CITY_STATE' | translate
          }}</span>
        </div>
        <span *ngIf="errCity" class="spn-error">{{ errCity }}</span>

        <!-- Save button. -->
        <div style="margin: 40px 0; text-align: center">
          <button
            type="button"
            class="btn btn-success btn-icon-label"
            (click)="save()"
            [disabled]="isSaving">
            <span class="btn-inner--icon">
              <i class="far fa-thumbs-up"></i>
            </span>
            <span *ngIf="!isSaving" class="btn-inner--text"
              >Solicitar convite</span
            >
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
      <!-- [FORM: END] -->
    </div>
  </div>
  <!-- [CONTENT: END] -->

  <!-- Saved. -->
  <div *ngIf="saved" id="queue-success" class="queue-success">
    <div>
      <p class="p-queue-success">Solicitação enviada!</p>
      <p>
        Fique de olho em seu e-mail, pois a qualquer momento podemos lhe enviar
        um convite!
      </p>
      <p><a href="https://www.petbee.com.br">Ir para o site</a></p>
    </div>
  </div>
</section>
