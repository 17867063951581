// Angular modules.
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  public isBrowser = isPlatformBrowser(this.platformId);

  // Constructor method.
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {
    super();
  }

  // Handle global errors.
  public handleError(error: any): void {
    let showError: boolean = true;

    if (this.isBrowser) {
      // Handle some errors.
      if (error && error.name && error.code) {
        // Firebase errors.
        if (error.name === 'FirebaseError') {
          if (error.code === 'resource-exhausted') {
            showError = false;
            window.location.href = '/maintenance';
          }
        }
      }
    }

    super.handleError(error);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    /*if (showError) {
      throw error;
    }*/
  }
}
