/* eslint-disable @typescript-eslint/no-unused-expressions */
// Angular modules.
import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, EAuthType, EBroadcast } from '@app/core/models';

// App interfaces.
import {
  IGoogleData,
  IHuman,
  IHumanLogin,
  ISocialLoginData,
} from '@app/core/models';

// App services.
import {
  AuthService,
  BroadcastService,
  ErrorService,
  HumanService,
  MobileService,
  UtilsService,
} from '@app/core/services';

// Glogal window reference.
declare global {
  interface Window {
    FB: any;
  }
}

// GTM.
// eslint-disable-next-line @typescript-eslint/ban-types
declare let dataLayer: any[];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [slideBottomAnimation],
})
export class LoginComponent implements OnInit {
  @Output() hideFullLoader: EventEmitter<number> = new EventEmitter<number>();

  // Login.
  public email: string;
  public password: string;

  // Login errors.
  public globalError: string;
  public errEmail: string;
  public errPassword: string;

  // Element's ids.
  public idEmail: string = 'txt-email';
  public idPass: string = 'txt-password';

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoaded: boolean = false;
  public isSignin: boolean = false;
  public waitingFacebook: boolean = false;
  public waitingGoogle: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private humanService: HumanService,
    private mobileService: MobileService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private ngZone: NgZone
  ) {}

  // On init.
  public ngOnInit(): void {
    // Force logout.
    this.authService.logout();

    const refresh: string = localStorage.getItem('refresh-login');
    if (refresh) {
      localStorage.removeItem('refresh-login');
      location.reload();
    }

    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOff, '/login');
      this.isLoaded = true;
      setTimeout(() => {
        if (!this.isMobile) {
          document.getElementById('txt-email') &&
            document.getElementById('txt-email').focus();
        }

        if (environment.localhost) {
          const em: HTMLElement = document.getElementById('txt-email');
          em.classList.remove('ng-pristine');
          em.classList.add('el-has-value');
          em.classList.add('ng-dirty');
        }
      }, 500);
    }, 500);
  }

  // User signed in with Google account.
  // https://developers.google.com/identity/sign-in/web/sign-in
  public onGoogleSignIn(googleUser: IGoogleData): void {}

  // User signed in with Facebook account.
  // https://developers.facebook.com/docs/javascript
  // https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus
  public facebookLogin(): void {
    // Test user:
    // email: open_jmbrtvw_user@tfbnw.net
    // pass: zpets2020

    this.waitingFacebook = true;

    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          // Get user's data.
          window.FB.api('/me?fields=email,name,picture', (resp: any) => {
            // Build social login data object.
            const sld: ISocialLoginData = {
              id: resp.id,
              type: EAuthType.Facebook,
              email: resp.email,
              name: resp.name,
              picture: resp.picture.data.url,
            };

            // Check if human already exists.
            this.humanService
              .getWithEmail(sld.email)
              .then(res => {
                const h: IHuman = res as IHuman;

                if (h) {
                  this.humanService.updateHuman(h);

                  window.FB.getLoginStatus((fbStatus: any) => {
                    if (fbStatus.status === 'connected') {
                      const id: string = fbStatus.authResponse.userID;
                      const tk: string = fbStatus.authResponse.accessToken;
                      this.authService
                        .checkFacebookLogin(id, tk)
                        .then(r => {
                          if (r.token) {
                            this.authService
                              .socialLogin(h, EAuthType.Facebook, r.token)
                              .then(slres => {
                                // this.humanService.updateHuman(h);
                                const upHuman: string =
                                  this.authService.encrypt(JSON.stringify(h));
                                localStorage.setItem(
                                  environment.storageKeys.updateUser,
                                  upHuman
                                );
                                this.waitingFacebook = false;

                                this.broadcastService.sendBroadcast(
                                  EBroadcast.FullLoaderOn
                                );
                                this.hideFullLoader.emit(2000);
                                // this.ngZone.run(() => this.router.navigate([''])).then();
                                const nextPath: string =
                                  localStorage.getItem('last-path') || '';
                                localStorage.removeItem('last-path');
                                window.location.href = nextPath;
                              })
                              .catch(slerr => {
                                alert(
                                  'Erro ao efetuar o login com o Facebook. Tente novamente ou use outro método.'
                                );
                              });
                          }
                        })
                        .catch(e => {
                          alert(e.error.message);
                        });
                    }
                  });
                } else {
                  // New user. Redirect to register form.
                  this.waitingFacebook = false;
                  localStorage.setItem(
                    environment.storageKeys.social,
                    JSON.stringify(sld)
                  );
                  this.router.navigate(['register/form']);
                }
              })
              .catch(err => {
                alert(err);
                this.waitingFacebook = false;
              });
          });
        } else {
          this.waitingFacebook = false;
          // this.utilsService.showAlert('alert-login', 'Operação cancelada pelo Facebook.', EAlertType.Error);
        }
      },
      { scope: 'public_profile,email' }
    );
  }

  // Sign in user with e-mail/password.
  public signIn(event?: any): void {
    this.isSignin = true;

    if (this.validateForm()) {
      const loginData: IHumanLogin = {
        email: this.email,
        password: this.password,
      };

      this.authService
        .login(loginData)
        .then(res => {
          this.authService.getLocalData();

          this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn);
          this.hideFullLoader.emit(2000);
          setTimeout(() => {
            const nextPath: string = localStorage.getItem('last-path') || '';
            localStorage.removeItem('last-path');
            this.router.navigate([nextPath]);
          }, 500);
        })
        .catch(err => {
          this.globalError = this.errorService.manageError(
            err,
            this.constructor.name,
            'signIn() > authService.login()'
          );
          document.getElementById(this.idPass) &&
            document.getElementById(this.idPass).focus();
          // this.utilsService.showAlert('alert-login', 'Erro durante o login.', EAlertType.Error);
        })
        .finally(() => {
          this.isSignin = false;
        });
    } else {
      this.isSignin = false;
    }
  }

  // Redirect to home page.
  public goToHome(): void {
    // this.router.navigate([environment.urls.site]);
    window.location.href = environment.urls.site;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }

  // Validate e-mail address.
  public validateEmail(event?: any): boolean {
    let valid: boolean = true;
    const alreadyContainsError: boolean = this.errEmail !== null;

    if (
      !event ||
      !document.getElementById('txt-email').classList.contains('ng-pristine')
    ) {
      if (!this.email) {
        this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL');
        this.inputError(this.idEmail, true);
        if (valid && !alreadyContainsError) {
          document.getElementById(this.idEmail) &&
            document.getElementById(this.idEmail).focus();
        }
        valid = false;
      } else {
        if (!this.utilsService.validateEmail(this.email)) {
          this.errEmail = this.translate.instant('LOGIN.ERRORS.EMAIL_INVALID');
          this.inputError(this.idEmail, true);
          if (valid && !alreadyContainsError) {
            document.getElementById(this.idEmail) &&
              document.getElementById(this.idEmail).focus();
          }
          valid = false;
        } else {
          this.errEmail = null;
          this.inputError(this.idEmail, false);
        }
      }
    }

    return valid;
  }

  // Validate form.
  private validateForm(): boolean {
    // Clear all errors.
    this.clearErrors();

    // E-Mail.
    const validEmail: boolean = this.validateEmail();

    // Password.
    const validPass: boolean = this.validatePassword(null, validEmail);

    // Return result.
    return validEmail && validPass;
  }

  // Validate password.
  private validatePassword(event?: any, validEmail: boolean = true): boolean {
    let valid: boolean = true;
    const alreadyContainsError: boolean = this.errPassword !== null;

    if (!this.password) {
      this.errPassword = this.translate.instant('LOGIN.ERRORS.PASSWORD');
      this.inputError(this.idPass, true);
      if (validEmail && !alreadyContainsError) {
        document.getElementById(this.idPass) &&
          document.getElementById(this.idPass).focus();
      }
      valid = false;
    } else {
      this.errPassword = null;
      this.inputError(this.idPass, false);
    }

    return valid;
  }

  // Clear login errors.
  private clearErrors(): void {
    this.globalError = null;
    this.errEmail = null;
    this.errPassword = null;
    this.inputError(this.idEmail, false);
  }

  // Toggle input error.
  private inputError(id: string, add: boolean = true): void {
    const errorClass: string = 'frm-error';
    const el: HTMLElement = document.getElementById(id);

    if (el) {
      if (add) {
        el.classList.add(errorClass);
      } else {
        el.classList.remove(errorClass);
      }
    }
  }
}
