<div *ngIf="showContent" [@slideUpDown]>
  <!-- LOGO -->
  <div class="container-logo">
    <img
      *ngIf="!isMobile"
      class="img-logo"
      src="assets/images/petbee.png"
      (click)="goToPage('/')" />
    <img
      *ngIf="isMobile"
      class="img-logo-mob"
      src="assets/images/petbee-100.png"
      (click)="goToPage('/')" />
  </div>

  <!-- Development flag. -->
  <div *ngIf="!isMobile && !isProd" class="dev-flag">
    <i class="fas fa-code"></i>
    Desenvolvimento
  </div>

  <!-- [MAIN MENU: START] -->
  <div class="container-menu" [ngClass]="{ 'mob-mode': isMobile }">
    <ul class="ul-menu-vertical">
      <li
        *ngFor="let m of menu"
        [ngClass]="{ 'mnu-active': m.active }"
        (click)="goToPage(m.url)">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="{{ m.icon }}"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">{{ m.title }}</div>
      </li>
    </ul>
  </div>
  <!-- [MAIN MENU: END] -->
</div>
