<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <!-- E-Mail. -->
  <div class="row">
    <div class="frm-element">
      <label>{{ 'GENERIC.EMAIL' | translate }}:</label>
      <div class="div-item">{{ selHuman.email || '---' }}</div>
    </div>
  </div>

  <!-- Phone. -->
  <div class="row">
    <div class="frm-element">
      <label>{{ 'GENERIC.PHONE' | translate }}:</label>
      <div class="div-item">{{ (selHuman.phone | phone : 'BR') || '---' }}</div>
    </div>
  </div>

  <!-- Coupon. -->
  <div class="row">
    <div class="frm-element">
      <label>{{ 'GENERIC.COUPON' | translate }}:</label>
      <div class="div-item">{{ selHuman.coupon || '---' }}</div>
    </div>
  </div>

  <!-- Coupon. -->
  <div class="row">
    <div class="frm-element">
      <label>{{ 'HUMANS.INVITED_BY' | translate }}:</label>
      <div class="div-item">{{ invitedBy || '---' }}</div>
    </div>
  </div>
</div>
