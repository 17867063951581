// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import {
  EAdditionalCoverage,
  EAlertType,
  EPaymentSystem,
  ESubscriptionInterval,
} from '@app/core/models';

// App interfaces.
import {
  IHumanLocalData,
  IPet,
  ISubscription,
  IAdditionalCoverages,
} from '@app/core/models';

// App services.
import {
  AuthService,
  ErrorService,
  MobileService,
  PaymentService,
  PetService,
  SubscriptionService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-vp-subscription',
  templateUrl: './vp-subscription.component.html',
  styleUrls: ['./vp-subscription.component.scss'],
  animations: [pageAnimations],
})
export class VpSubscriptionComponent implements OnInit {
  // Outputs.
  @Output() updatedSubs: EventEmitter<IPet> = new EventEmitter<IPet>();

  // Inputs.
  @Input() pet: IPet;

  public isMobile: boolean = this.mobileService.isMobile();

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Plan's data
  public startDate: string = new Date().toLocaleDateString('pt-BR');
  public additionalCoverages: IAdditionalCoverages[] = [];
  public chargeDefaultPlan: boolean = true;
  public freeDefaultPlan: boolean = false;
  public paymentSystem: EPaymentSystem;
  public planName: string;

  // Free apoortionment
  public activeAssessment: boolean = true;

  // Form data
  public subscriptionPlan: ESubscriptionInterval;
  public vaccinesCoverage: boolean;
  public checkupCoverage: boolean;
  public dentalCoverage: boolean;
  public financialOverdue: boolean = false;
  public petDied: boolean = false;
  public petDiedDate: string = new Date().toLocaleDateString('pt-BR');
  public subscriptionCancelDate: string;

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public showPlanOptions: boolean = false;
  public showCancelOptions: boolean = false;
  public formValid: boolean;
  public cancelFormValid: boolean;

  // Form errors.
  public errHSubscriptionPlan: string;
  public errPetDiedDate: string;
  public errSubscriptionCancelDate: string;
  public errAdditionalCoverage: string;
  public errStartDate: string;

  // PVT

  // Pet.
  private currentSubscription: ISubscription;

  // Pet Human
  private petHumanId: number;

  // Plans data.
  private planValue: number;

  // Constuctor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private paymentService: PaymentService,
    public petService: PetService,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private mobileService: MobileService
  ) {}

  // On init.
  public async ngOnInit(): Promise<void> {
    this.petHumanId = this.pet.humans[0].id;
    this.currentSubscription = this.pet.subscription;
    await this.getHumanPaymentMethod();
    await this.checkPetPlan();
    await this.checkFreeDefaultPlan();
    this.checkCurrentPet();
    await this.checkCurrentPlan();
  }

  public async getHumanPaymentMethod(): Promise<void> {
    if (!this.petHumanId) {
      return;
    }

    const paymentMethod = await this.utilsService.getHumanPaymentMethod(
      this.petHumanId
    );
    this.paymentSystem = paymentMethod.paymentSystem;
  }

  public async checkFreeDefaultPlan(): Promise<void> {
    if (!this.petHumanId) {
      return;
    }

    const humanSubs = (
      await this.subscriptionService.getSubscriptions(
        this.petHumanId.toString()
      )
    ).rows;

    if (humanSubs && humanSubs.length === 1) {
    }

    const activeHumanSubs =
      humanSubs &&
      humanSubs.length &&
      humanSubs.filter(sub => !sub.canceledAt && !sub.blocked);

    const firstSubscription =
      activeHumanSubs &&
      activeHumanSubs.length &&
      activeHumanSubs.reduce((prev, curr) =>
        prev.createdAt < curr.createdAt ? prev : curr
      );

    if (firstSubscription && firstSubscription.createdAt < 1654052400000) {
      this.chargeDefaultPlan = false;
      this.freeDefaultPlan = true;
    }
  }

  public checkCurrentPet(): void {
    const petDiedDate = this.pet.diedAt;

    if (petDiedDate) {
      this.petDied = true;
      this.petDiedDate = new Date(petDiedDate).toLocaleDateString('pt-BR');
    }
  }

  public async checkCurrentPlan(): Promise<void> {
    const subs = this.currentSubscription;

    if (!subs) {
      return;
    }

    const planId = subs.planId;
    const subscriptionPlan = this.utilsService.getSubscriptionInverval(
      subs.interval
    );
    const joinAssessment = subs.joinAssessment;
    const additionalCoveragesTypes =
      this.utilsService.mapPlanAdditionalCoverages(planId);
    const additionalCoverages = this.utilsService.getInitialAdditionalCoverages(
      planId,
      subscriptionPlan
    );
    const vaccinesCoverage = additionalCoveragesTypes.includes(
      EAdditionalCoverage.vaccines as never
    );
    const checkupCoverage = additionalCoveragesTypes.includes(
      EAdditionalCoverage.checkup as never
    );
    const dentalCoverage = additionalCoveragesTypes.includes(
      EAdditionalCoverage.dental as never
    );
    const financialOverdue = subs.blocked;
    const subsCancelDate = subs.canceledAt;

    this.changePlan(subscriptionPlan);

    const planValue = this.getFinalValue();

    this.planValue = planValue;
    this.additionalCoverages = additionalCoverages;
    this.activeAssessment = joinAssessment;
    this.subscriptionPlan = subscriptionPlan;
    this.vaccinesCoverage = vaccinesCoverage;
    this.checkupCoverage = checkupCoverage;
    this.dentalCoverage = dentalCoverage;
    this.financialOverdue = financialOverdue;
    this.subscriptionCancelDate = subsCancelDate
      ? new Date(subsCancelDate).toLocaleDateString('pt-BR')
      : new Date().toLocaleDateString('pt-BR');
  }

  public async checkPetPlan(): Promise<void> {
    const currentSubscription = this.pet.subscription;
    const planId = currentSubscription && currentSubscription.planId;

    if (planId) {
      this.planName = await this.utilsService.getPlanName(planId);
    }
  }

  // Change subscription plan.
  public changePlan(i: string): void {
    const interval = ESubscriptionInterval[i];
    this.subscriptionPlan = ESubscriptionInterval[interval];

    if (!this.chargeDefaultPlan) {
      this.planValue = 0;
    } else {
      if (interval === ESubscriptionInterval.Monthly) {
        this.planValue = environment.plans.default.monthlValue;
      }

      if (interval === ESubscriptionInterval.Annual) {
        this.planValue = environment.plans.default.annualValue;
      }
    }

    this.updateCoverage();
    this.validateSubscriptionPlan();
  }

  // Change additional coverage.
  public changeCoverage(t: string = null, e: any = null): void {
    const checked = e && e.target && e.target.checked;
    const type = EAdditionalCoverage[t];

    if (!checked) {
      this.additionalCoverages = this.additionalCoverages.filter(
        ac => ac.type !== type
      );
    }

    if (checked) {
      this.additionalCoverages.push({ type });
    }

    this.additionalCoverages = this.utilsService.getAdditionalCoverages(
      this.additionalCoverages,
      this.subscriptionPlan
    );

    if (checked !== undefined && checked !== null) {
      switch (type) {
        case EAdditionalCoverage.vaccines:
          this.vaccinesCoverage = e.target.checked;
          break;
        case EAdditionalCoverage.checkup:
          this.checkupCoverage = e.target.checked;
          break;
        case EAdditionalCoverage.dental:
          this.dentalCoverage = e.target.checked;
          break;
      }
    }
  }

  public validateSubscriptionPlan(): boolean {
    let isValid: boolean = true;
    this.errHSubscriptionPlan = null;

    if (!this.subscriptionPlan) {
      isValid = false;
      this.errHSubscriptionPlan = this.translate.instant(
        'SUBSCRIPTION.ERRORS.SUBS_INTERVAL'
      );
    }

    this.formValid = isValid;

    return isValid;
  }

  // Check cancel form
  public validateCancelForm(): boolean {
    let isValid: boolean = false;

    const vPetDiedDate = this.validatePetDiedDate();
    const vSubscriptionCancelDate = this.validateSubscriptionCancelDate();

    if (vPetDiedDate && vSubscriptionCancelDate) {
      isValid = true;
    }

    this.cancelFormValid = isValid;

    return isValid;
  }

  // Check pet died date
  public validatePetDiedDate(): boolean {
    let isValid: boolean = true;
    this.errPetDiedDate = null;

    if (this.petDied && !this.petDiedDate) {
      isValid = false;
      this.errPetDiedDate = 'Preencha a data de falecimento';
    }

    this.cancelFormValid = isValid;

    return isValid;
  }

  // Check subscription cancel date
  public validateSubscriptionCancelDate(): boolean {
    let isValid: boolean = true;
    this.errSubscriptionCancelDate = null;

    if (!this.subscriptionCancelDate) {
      isValid = false;
      this.errSubscriptionCancelDate =
        'Preencha a data de cancelamento do plano';
    }

    this.cancelFormValid = isValid;

    return isValid;
  }

  // Check add plan form
  public validateAddToPlanForm(): boolean {
    let isValid: boolean = false;

    const vSubscriptionPlan = this.validateSubscriptionPlan();
    const vSubscriptionStartDate = this.validateStartDate();

    if (vSubscriptionPlan && vSubscriptionStartDate) {
      isValid = true;
    }

    this.formValid = isValid;

    return isValid;
  }

  // Check subscription cancel date
  public validateStartDate(): boolean {
    let isValid: boolean = true;
    this.errSubscriptionCancelDate = null;

    if (!this.startDate) {
      isValid = false;
      this.errStartDate = 'Preencha a data de início do plano';
    }

    this.formValid = isValid;

    return isValid;
  }

  // Add pet plan.
  public addToPlan(): void {
    if (!this.validateAddToPlanForm()) {
      return;
    }

    const interval: number =
      this.subscriptionPlan === ESubscriptionInterval.Annual ? 12 : 1;

    const planId = this.utilsService.getPlanId(
      this.vaccinesCoverage,
      this.checkupCoverage,
      this.dentalCoverage
    );
    const nextRecurrency = this.utilsService.getSubscriptionNextRecurrencyDate(
      planId,
      this.subscriptionPlan
    );
    const valueByPet = this.getFinalValue();
    const ed: string = this.utilsService.getSubscriptionEndDate(
      planId,
      this.subscriptionPlan
    );

    const updatedSubscrition: Partial<ISubscription> = {
      amount: valueByPet ? valueByPet.toString() : '0',
      blocked: false,
      currency: 'BRL',
      interval,
      freeware: !this.chargeDefaultPlan,
      joinAssessment: this.activeAssessment,
      nextRecurrency,
      paymentId: null,
      petId: this.pet.id,
      planId,
      startDate: this.utilsService.getFormatedDBDate(
        new Date(this.startDate.split('/').reverse().join('/'))
      ),
      endDate: this.utilsService.getSubscriptionEndDate(
        planId,
        this.subscriptionPlan
      ),
      canceledAt: null,
    };

    if (confirm('Confirma a adição do pet a rede?')) {
      this.isSaving = true;
      const subs = this.currentSubscription;

      if (subs) {
        const subsToUpdate = Object.assign(subs, updatedSubscrition);

        this.subscriptionService
          .updateSubscription(subsToUpdate)
          .then(res => {
            this.utilsService.showAlert(
              'adm-pets',
              'Assinatura atualizada com sucesso.',
              EAlertType.Success
            );
            this.pet.subscription = res.rows as ISubscription;
            this.showPlanOptions = false;
            this.updatedSubs.emit(this.pet);
          })
          .catch(err => {
            this.utilsService.showAlert(
              'adm-pets',
              this.errorService.manageError(
                err,
                this.constructor.name,
                'addToPlan() > subscriptionService.updateSubscription()'
              ),
              EAlertType.Error
            );
            this.isSaving = false;
          })
          .finally(() => (this.isSaving = false));
      } else {
        const subsToUpdate: ISubscription = Object.assign(
          {
            amount: valueByPet ? valueByPet.toString() : '0',
            authCode: null,
            blocked: false,
            coupon: null,
            currency: 'BRL',
            createdAt: this.utilsService.getFormatedDBDate(new Date()),
            externalId: null,
            humanId: this.pet.humans[0].id,
            interval,
            freeware: !this.chargeDefaultPlan,
            joinAssessment: this.activeAssessment,
            nextRecurrency,
            paymentId: null,
            petId: this.pet.id,
            planId,
            recPayId: null,
            startDate: this.utilsService.getFormatedDBDate(
              new Date(this.startDate.split('/').reverse().join('/'))
            ),
            endDate: this.utilsService.getSubscriptionEndDate(
              planId,
              this.subscriptionPlan
            ),
          },
          updatedSubscrition
        );

        this.subscriptionService
          .createSubscription(subsToUpdate)
          .then(res => {
            this.utilsService.showAlert(
              'adm-pets',
              'Assinatura criada com sucesso.',
              EAlertType.Success
            );
            this.pet.subscription = res.rows as ISubscription;
            this.showPlanOptions = false;
            this.updatedSubs.emit(this.pet);
          })
          .catch(err => {
            this.utilsService.showAlert(
              'adm-pets',
              this.errorService.manageError(
                err,
                this.constructor.name,
                'addToPlan() > subscriptionService.createSubscription()'
              ),
              EAlertType.Error
            );
          })
          .finally(() => (this.isSaving = false));
      }
    }
  }

  // Cancel subscription.
  public cancelPlan(silent: boolean = false): void {
    if (!this.validateCancelForm()) {
      return;
    }

    if (this.pet.subscription) {
      if (silent || confirm('Confirma o cancelamento da assinatura?')) {
        this.isSaving = true;
        const subs: ISubscription = this.pet.subscription;
        const pet: IPet = this.pet;

        if (this.petDied && this.petDiedDate) {
          pet.diedAt = this.utilsService.getFormatedDBDate(
            new Date(this.petDiedDate.split('/').reverse().join('/'))
          );
        } else if (!this.petDied) {
          pet.diedAt = null;
        }

        this.petService
          .updatePet(pet)
          .then(res => {
            const updatedPet = {
              ...this.pet,
              ...(res.rows as IPet),
            };
            this.pet = updatedPet;
          })
          .catch(err => {
            this.utilsService.showAlert(
              'adm-pets',
              this.errorService.manageError(
                err,
                this.constructor.name,
                'cancelPlan() > subscriptionService.cancelSubscription()'
              ),
              EAlertType.Error
            );
          });

        subs.freeware = false;
        subs.blocked = this.financialOverdue;
        subs.canceledAt = this.utilsService.getFormatedDBDate(
          new Date(this.subscriptionCancelDate.split('/').reverse().join('/'))
        );

        this.subscriptionService
          .updateSubscription(subs)
          .then(res => {
            if (!silent) {
              this.utilsService.showAlert(
                'adm-pets',
                'Assinatura cancelada com sucesso.',
                EAlertType.Success
              );
            }
            this.pet.subscription = res.rows as ISubscription;
            this.isSaving = false;
            this.showCancelOptions = false;
            this.updatedSubs.emit(this.pet);
          })
          .catch(err => {
            this.utilsService.showAlert(
              'adm-pets',
              this.errorService.manageError(
                err,
                this.constructor.name,
                'cancelPlan() > subscriptionService.cancelSubscription()'
              ),
              EAlertType.Error
            );
            this.isSaving = false;
          });
      }
    } else {
      this.utilsService.showAlert(
        'adm-pets',
        'O pet não está inscrito na rede.',
        EAlertType.Error
      );
    }
  }

  // Get final value.
  public getFinalValue(): number {
    // Get pets count, including those already protected.
    const additionalCoveragesValue: number =
      this.utilsService.mapAdditionalCoverageValue(this.additionalCoverages);
    const selPets: number = 1;

    if (!this.planValue && additionalCoveragesValue === 0) {
      return;
    }

    let finalValue: number;

    finalValue = selPets * ((this.planValue || 0) + additionalCoveragesValue);
    finalValue = finalValue < 0 ? 0 : finalValue;

    // Return the final result.
    return finalValue;
  }

  // Change additional coverage.
  public updateCoverage(): void {
    const currentAdditionalCoverages = this.additionalCoverages;

    if (currentAdditionalCoverages.length) {
      this.additionalCoverages = this.utilsService.getAdditionalCoverages(
        currentAdditionalCoverages,
        this.subscriptionPlan
      );
    }
  }

  // Validate default plan adoptions.
  public freePlanAdoptionOnChange(e): void {
    this.chargeDefaultPlan = e.target.checked;
    this.changePlan(this.subscriptionPlan);
  }

  // Get subscription date (Cielo format).
  private getSubscriptionDate(dt: Date): string {
    return this.utilsService.getFormatedDBDate(dt);
  }
}
