// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// App guards.
import { AuthUserGuard } from '@app/core/guards/auth-user/auth-user.guard';

// Routes.
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@app/my-clinics/my-clinics.module').then(m => m.MyClinicsModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@app/admin/admin.module').then(m => m.AdminModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'clinics',
    loadChildren: () =>
      import('@app/clinics/clinics.module').then(m => m.ClinicsModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@app/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('@app/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'logoff',
    loadChildren: () =>
      import('@app/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'my-clinics',
    loadChildren: () =>
      import('@app/my-clinics/my-clinics.module').then(m => m.MyClinicsModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'my-cards',
    loadChildren: () =>
      import('@app/my-plan-cards/my-plan-cards.module').then(
        m => m.MyPlanCardsModule
      ),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'my-data',
    loadChildren: () =>
      import('@app/my-data/my-data.module').then(m => m.MyDataModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'my-pet',
    loadChildren: () =>
      import('@app/my-pet/my-pet.module').then(m => m.MyPetModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'my-plans',
    loadChildren: () =>
      import('@app/my-plan/my-plan.module').then(m => m.MyPlanModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('@app/partners/partners.module').then(m => m.PartnersModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('@app/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'refer-a-friend',
    loadChildren: () =>
      import('@app/refer-a-friend/refer-a-friend.module').then(
        m => m.ReferAFriendModule
      ),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'remember-password',
    loadChildren: () =>
      import('@app/remember-password/remember-password.module').then(
        m => m.RememberPasswordModule
      ),
  },
  {
    path: 'requests',
    loadChildren: () =>
      import('@app/requests/requests.module').then(m => m.RequestsModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'reset-password/:id',
    loadChildren: () =>
      import('@app/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      ),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('@app/support/support.module').then(m => m.SupportModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: 'transparency',
    loadChildren: () =>
      import('@app/transparency/transparency.module').then(
        m => m.TransparencyModule
      ),
  },
  {
    path: 'treatments',
    loadChildren: () =>
      import('@app/treatments/treatments.module').then(m => m.TreatmentsModule),
    pathMatch: 'prefix',
    canActivate: [AuthUserGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('@app/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
