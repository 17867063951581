// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import {
  ICreditCardUpdate,
  ICreditCardTokenData,
  IHuman,
  IHumanLocalData,
  ISubscription,
  ICreditCardValidationResult,
  ICieloTransaction,
  ICieloCreditCard,
  IPaymentRequest,
  ECieloCardOnFileUsage,
  ECieloCardOnFileReason,
} from '@app/core';

// App services.
import {
  ErrorService,
  HumanService,
  MobileService,
  PaymentService,
  SubscriptionService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-change-card',
  templateUrl: './change-card.component.html',
  styleUrls: ['./change-card.component.scss'],
  animations: [modalAnimation],
})
export class ChangeCardComponent implements OnInit {
  // Outputs.
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() finished: EventEmitter<IHumanLocalData> =
    new EventEmitter<IHumanLocalData>();

  // Logged human's data.
  @Input() human: IHumanLocalData;
  public subscriptions: ISubscription[] = [];

  // Updated data.
  public upData: ICreditCardUpdate;

  // Form data.
  public cardNumber: string;
  public cardName: string;
  public cardMonth: string;
  public cardYear: string;
  public cardCVC: string;
  public cardFlag: string;
  public cardIcon: string;

  // Form errors.
  public formValid: boolean = false;
  public errCardNumber: string;
  public errCardName: string;
  public errCardMonth: string;
  public errCardYear: string;
  public errCardCVC: string;

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private errorService: ErrorService,
    private humanService: HumanService,
    private mobileService: MobileService,
    private paymentService: PaymentService,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get human's subscriptions.
    this.getSubscriptions();

    if (environment.production === false) {
      this.runDevTasks();
    }
  }

  // Get human's subscriptions.
  public getSubscriptions(): void {
    this.isLoading = true;

    this.subscriptionService
      .getSubscriptions(this.human.id)
      .then(res => {
        this.subscriptions = res.rows as ISubscription[];
        this.subscriptions.map(s => {
          if (s.recPayId) {
            this.paymentService
              .getRecurrentPayment(s.recPayId)
              .then(resRec => {
                const rec: any = resRec.rows.RecurrentPayment;
                this.upData = {
                  Payment: {
                    Amount: rec.Amount.toString(),
                    Country: 'BRA',
                    CreditCard: {
                      Brand: null,
                      CardNumber: null,
                      ExpirationDate: null,
                      Holder: null,
                      SecurityCode: null,
                    },
                    Currency: 'BRL',
                    Installments: 1,
                    Type: 'CreditCard',
                    SoftDescriptor: 'Petbee',
                  },
                  RecurrentPaymentId: rec.RecurrentPaymentId,
                };
              })
              .catch(errRec => {
                this.utilsService.showAlert(
                  'change-card',
                  this.errorService.manageError(
                    errRec,
                    this.constructor.name,
                    'getSubscriptions() > paymentService.getRecurrentPayment()'
                  ),
                  EAlertType.Error
                );
              });
          }
        });
      })
      .catch(err => {
        this.utilsService.showAlert(
          'change-card',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getSubscriptions() > subscriptionService.getSubscriptions()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => (this.isLoading = false));
  }

  // Close modal.
  public closeModal(saved: boolean = true): void {
    if (saved) {
      this.finished.emit(this.human);
    } else {
      this.modalClosed.emit();
    }
  }

  // Save new credit card info.
  public async saveNewCard(): Promise<void> {
    try {
      if (await this.validateForm()) {
        this.isSaving = true;

        const isCreditCardValid: ICreditCardValidationResult =
          await this.validateCreditCard();

        if (isCreditCardValid && !isCreditCardValid.Valid) {
          this.utilsService.showAlert(
            'change-card',
            this.errorService.manageError(
              isCreditCardValid.Message,
              this.constructor.name,
              'saveNewCard() > paymentService.changeCreditCard()'
            ),
            EAlertType.Error
          );
        }

        const human = await this.humanService.getWithId(
          parseInt(this.human.id, 10)
        );

        // const data: ICreditCardTokenData = {
        //   CustomerName: this.human.fullName,
        //   CardNumber: this.cardNumber.trim().replace(/\s/g, ''),
        //   Holder: this.cardName,
        //   ExpirationDate: this.utilsService.getExpirationDate(
        //     this.cardMonth,
        //     this.cardYear
        //   ),
        //   Brand: this.paymentService.getCieloCardFlag(this.cardFlag),
        // };

        // const createCardTokenRes = await this.paymentService.createCardTokenAlt(
        //   data
        // );

        // const cardToken =
        //   createCardTokenRes &&
        //   createCardTokenRes.rows &&
        //   createCardTokenRes.rows.CardToken;

        this.humanService.updateHuman(human);

        this.finishAll();
        this.isSaving = false;
      }
    } catch (err) {
      this.utilsService.showAlert(
        'change-card',
        this.errorService.manageError(
          err,
          this.constructor.name,
          'saveNewCard() > paymentService.changeCreditCard()'
        ),
        EAlertType.Error
      );
    }
  }

  // Validate card number.
  public async validateCardNumber(): Promise<boolean> {
    this.errCardNumber = null;
    this.cardNumber =
      this.cardNumber && this.cardNumber.trim().replace(/\s/g, '');
    const isCreditCardValid: ICreditCardValidationResult =
      await this.utilsService.validateCreditCardNumber(this.cardNumber);

    if (isCreditCardValid && !isCreditCardValid.Valid) {
      this.errCardNumber = isCreditCardValid.Message;
    }

    this.cardFlag = isCreditCardValid.CardFlag;
    this.cardIcon = isCreditCardValid.CardIcon;

    return isCreditCardValid.Valid;
  }

  // Validate card name.
  public validateCardName(): boolean {
    this.errCardName = null;

    const isCreditCardHolderNameValid = this.utilsService.validateCardName(
      this.cardName
    );

    if (isCreditCardHolderNameValid && !isCreditCardHolderNameValid.Valid) {
      this.errCardName = isCreditCardHolderNameValid.Message;
    }

    this.formValid = isCreditCardHolderNameValid.Valid;
    return isCreditCardHolderNameValid.Valid;
  }

  // Validate card month.
  public validateCardMonth(): boolean {
    const isValid: boolean = true;
    this.errCardMonth = null;

    const isCreditCardMonthValid = this.utilsService.validateCardMonth(
      this.cardMonth
    );

    if (isCreditCardMonthValid && !isCreditCardMonthValid.Valid) {
      this.errCardMonth = isCreditCardMonthValid.Message;
    }

    this.formValid = isCreditCardMonthValid.Valid;
    if (this.errCardYear) {
      this.validateCardYear();
    }
    return isCreditCardMonthValid.Valid;
  }

  public validateCardYear(): boolean {
    this.errCardYear = null;

    const isCreditCardYearValid: ICreditCardValidationResult =
      this.utilsService.validateCreditCardYear(this.cardYear, this.cardMonth);

    if (isCreditCardYearValid && !isCreditCardYearValid.Valid) {
      this.errCardYear = isCreditCardYearValid.Message;
    }

    this.formValid = isCreditCardYearValid.Valid;
    return isCreditCardYearValid.Valid;
  }

  // Validate card CVC.
  public validateCardCVC(): boolean {
    this.errCardCVC = null;

    const isCreditCardCVCValid = this.utilsService.validateCreditCardCVC(
      this.cardCVC
    );

    if (isCreditCardCVCValid && !isCreditCardCVCValid.Valid) {
      this.errCardCVC = isCreditCardCVCValid.Message;
    }

    this.formValid = isCreditCardCVCValid.Valid;
    return isCreditCardCVCValid.Valid;
  }

  // Change card's name to uppercase.
  public makeNameUpper(): void {
    setTimeout(() => {
      this.cardName = this.cardName.toUpperCase();
    }, 300);
  }

  // Finish with success.
  private finishAll(): void {
    this.utilsService.showAlert(
      'change-card',
      'Cartão de crédito atualizado com sucesso.',
      EAlertType.Success
    );
    this.closeModal();
  }

  // Validate form.
  private async validateForm(): Promise<boolean> {
    this.clearErrors();

    const cNum: boolean = await this.validateCardNumber();
    const cNam: boolean = this.validateCardName();
    const cMon: boolean = this.validateCardMonth();
    const cYea: boolean = this.validateCardYear();
    const cCVC: boolean = this.validateCardCVC();

    const v: boolean = cNum && cNam && cMon && cYea && cCVC;

    if (!v) {
      const elNumber: HTMLElement = document.getElementById('card-number');
      if (elNumber) {
        setTimeout(() => {
          elNumber.scrollIntoView();
        }, 500);
      }
    }

    this.formValid = v;

    return v;
  }

  // Validate credit card.
  private async validateCreditCard(): Promise<ICreditCardValidationResult> {
    this.cardNumber =
      this.cardNumber && this.cardNumber.trim().replace(/\s/g, '');

    const creditCard: ICieloCreditCard = {
      CardNumber: this.cardNumber,
      Holder: this.human.fullName,
      ExpirationDate: this.utilsService.getExpirationDate(
        this.cardMonth,
        this.cardYear
      ),
      SecurityCode: this.cardCVC,
      SaveCard: true,
      Brand: this.cardFlag,
      CardOnFile: {
        Usage: ECieloCardOnFileUsage.First,
        Reason: ECieloCardOnFileReason.Unscheduled,
      },
    };

    const payment: IPaymentRequest = {
      Amount: 127,
      Installments: 1,
      Capture: false,
    };

    const transaction: ICieloTransaction =
      this.utilsService.getTransactionRequest(creditCard, this.human, payment);

    const isCreditCardValid: ICreditCardValidationResult =
      await this.utilsService.validateCreditCardAndCreatePayment(
        false,
        creditCard,
        transaction
      );

    return isCreditCardValid;
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errCardCVC = null;
    this.errCardMonth = null;
    this.errCardName = null;
    this.errCardNumber = null;
    this.errCardYear = null;
  }

  // Development only tasks.
  private runDevTasks(): void {
    this.cardNumber = '4200727905281711';
    this.cardName = 'Petbee Teste';
    this.cardMonth = '11';
    this.cardYear = '2025';
    this.cardCVC = '321';
    this.validateCardNumber();

    const elList: string[] = [];
    elList.push('card-number');
    elList.push('card-name');
    elList.push('card-month');
    elList.push('card-year');
    elList.push('card-cvc');

    elList.map(e => {
      const el: HTMLElement = document.getElementById(e);
      if (el) {
        el.classList.remove('ng-untouched');
        el.classList.add('ng-touched');
        el.classList.add('el-has-value');
        el.classList.add('ng-dirty');
      }
    });
  }
}
