// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { RememberPasswordComponent } from '@app/remember-password/remember-password.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: RememberPasswordComponent,
    data: {
      title: 'Petbee - Lembrar senha de usuário',
      description: 'Esqueceu sua senha? Saiba como recuperá-la.',
      ogUrl: 'http:/www.petbee.com.br',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RememberPasswordRoutingModule {}
