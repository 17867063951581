// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App interfaces.
import { IAPIResponse, IHumanGuest } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const rootEP: string = environment.urls.api;
const endpoint: string = rootEP + 'refer-a-friend/';

@Injectable({ providedIn: 'root' })
export class ReferService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(private authService: AuthService, private http: HttpClient) {}

  // Get humans invited by another human.
  public getInvitedBy(humanId: string): Promise<IHumanGuest[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHumanGuest[]>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'invited/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }
}
