// Interface for alert messages.
export interface IAlert {
  avoidAutoClose?: boolean;
  id: string;
  type: EAlertType;
  message: string;
  secondsToClose?: number;
}

// Enumerator for alert types.
export enum EAlertType {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
}
