// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App models.
import { IAPIResponse, IApportionment } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';

// Main endpoint.
const endpoint: string = environment.urls.api + 'apportionments/';
const periodEndpoint: string = endpoint + 'period/';

@Injectable({ providedIn: 'root' })
export class ApportionmentsService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient
  ) {}

  // Get all apportionments.
  public getAll(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get by ID.
  public getById(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get from period.
  public getFromPeriod(period: string): Promise<IAPIResponse> {
    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.headers = this.authService.getHttpHeaders();

      this.http
        .get<IAPIResponse>(periodEndpoint + period, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create item.
  public create(data: IApportionment): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(endpoint, JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update item.
  public update(data: IApportionment): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(endpoint + data.id, JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Delete item.
  public delete(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(endpoint + id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }
}
