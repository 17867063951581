// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// Interface.
import { IHuman, IHumanLocalData } from '@app/core';

// Services.
import {
  AuthService,
  ErrorService,
  HumanService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-view-human',
  templateUrl: './view-human.component.html',
  styleUrls: ['./view-human.component.scss'],
  animations: [modalAnimation],
})
export class ViewHumanComponent implements OnInit {
  // Outputs.
  @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Inputs.
  @Input() selHuman: IHuman;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Selected human's data.
  public humanAvatar: string;

  // Status.
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public activeTab: string = 'about';

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private humanService: HumanService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get humans's avatar.
    this.humanAvatar = this.humanService.getHumanPicture(this.selHuman);
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  // Block/unblock user.
  public lockUser(): void {
    const msg: string =
      'Confirma o ' +
      (this.selHuman.blocked ? 'desbloqueio' : 'bloqueio') +
      ' do usuário?';
    if (confirm(msg)) {
      this.isSaving = true;
      this.selHuman.blocked = !this.selHuman.blocked;
      this.humanService
        .updateHuman(this.selHuman)
        .then(res => {})
        .catch(err => {
          this.utilsService.showAlert(
            'vh-human',
            this.errorService.manageError(
              err,
              this.constructor.name,
              'lockUser() > humanService.updateHuman()'
            ),
            EAlertType.Error
          );
        })
        .finally(() => (this.isSaving = false));
    }
  }

  // Change active tab.
  public changeTab(tab: string): void {
    this.activeTab = tab.toLowerCase();
  }

  // Close modal window.
  public closeModal(forceReload: boolean = false): void {
    this.finished.emit(forceReload);
  }
}
