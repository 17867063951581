<section *ngIf="isLoaded" id="agreements" class="modal-container" [@slideDown]>
  <div class="close-modal">
    <i class="far fa-times-circle" (click)="closeWindow($event)"></i>
  </div>

  <div class="modal-content">
    <h1>Termos do contrato</h1>

    <p>
      Faça o download do arquivo abaixo, leia-o e envie uma cópia assinada para
      <a href="mailto:suporteclinicas@petbee.com.br"
        >suporteclinicas@petbee.com.br</a
      >
      (pode usar um scanner ou tirar fotos).
    </p>

    <p>
      Assim que recebermos o contrato assinado entraremos em contato para dar
      continuidade ao processo.
    </p>

    <hr class="divider divider-fade" />

    <div class="file-download">
      <i class="far fa-file-pdf"></i>
      <!--<a href="{{filePath}}" download="{{fileName}}">Termo de adesão ao sistema Petbee.pdf</a>-->
      <a href="javascript:void(0)" (click)="downloadFile()"
        >Termo de adesão ao sistema Petbee.pdf</a
      >
    </div>

    <hr class="divider divider-fade" />

    <p class="p-small">
      <strong>Última atualização:</strong> 02 de março de 2020
    </p>
  </div>
</section>
