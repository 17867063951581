<div *ngIf="!isLoading" [@slideUpDown]>
  <!-- Avatar. -->
  <div class="container-avatar" [ngClass]="{ 'mob-mode': isMobile }">
    <img
      class="img-avatar"
      [ngClass]="{ 'mob-mode': isMobile }"
      src="{{ getHumanPicuturesPath + human.picture }}" />
    <p *ngIf="!isMobile" class="p-user-name">{{ human.name }}</p>
    <p *ngIf="!isMobile" class="p-logout" (click)="logout()">
      {{ 'AUTH.LOGOUT' | translate | lowercase }}
    </p>
  </div>

  <!-- [SIDEBAR MENU: START] -->
  <div class="container-sidebar-menu" [ngClass]="{ 'mob-mode': isMobile }">
    <ul class="ul-menu-vertical">
      <!-- Common menu. -->
      <li
        *ngFor="let m of menu"
        [ngClass]="{ 'mnu-active': m.active }"
        (click)="goToPage(m.url)">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="{{ m.icon }}"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">{{ m.title }}</div>
      </li>

      <!-- Refer a friend. -->
      <!-- <li class="refer-menu-item" (click)="goToPage('refer-a-friend')">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="fas fa-bullhorn"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">
          {{ 'MENU.USER_MENU.REFER' | translate }}
        </div>
      </li> -->
    </ul>

    <!-- My clinics menu. -->
    <!-- <ul *ngIf="clinics.length > 1" class="ul-menu-vertical">
      <li class="myclinics-menu-item" (click)="goToPage('my-clinics')">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="fas fa-clinic-medical"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">{{ myClinicsText }}</div>
      </li>
    </ul> -->

    <!-- My clinic menu. -->
    <!-- <ul *ngIf="clinics.length === 1" class="ul-menu-vertical">
      <li class="myclinics-menu-item" (click)="goToClinic(clinics[0].id)">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="fas fa-clinic-medical"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">{{ myClinicText }}</div>
      </li>
    </ul> -->

    <!-- Admin menu. -->
    <ul *ngIf="human.isAdmin" class="ul-menu-vertical">
      <li
        *ngFor="let m of menuAdmin"
        class="adm-menu-item"
        [ngClass]="{ 'mnu-active': m.active }"
        (click)="goToPage(m.url)">
        <div class="li-mnu-i" [ngClass]="{ 'mob-mode': isMobile }">
          <i class="{{ m.icon }}"></i>
        </div>
        <div *ngIf="!isMobile" class="li-mnu-title">{{ m.title }}</div>
      </li>
    </ul>
  </div>
  <!-- [SIDEBAR MENU: END] -->

  <!-- Change language. -->
  <!-- <div
    *ngIf="!isProdMode"
    class="container-lang"
    [ngClass]="{ 'mob-mode': isMobile }">
    <img
      class="img-lang"
      src="assets/images/flags/countries/flag-brazil.svg"
      (click)="changeLanguage('pt-BR')" />
    <img
      class="img-lang"
      src="assets/images/flags/countries/flag-usa.svg"
      (click)="changeLanguage('en-US')" />
  </div> -->
</div>
