// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import { IAddress, ICity, IHuman, IState } from '@app/core/models';

// App services.
import {
  ErrorService,
  HumanService,
  LocationService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-vh-address',
  templateUrl: './vh-address.component.html',
  styleUrls: ['./vh-address.component.scss'],
  animations: [pageAnimations],
})
export class VhAddressComponent implements OnInit {
  // Selected human's data.
  @Input() selHuman: IHuman;
  public address: IAddress;
  public city: ICity;
  public state: IState;

  // Status.
  public isLoading: boolean = false;

  // Constuctor method.
  constructor(
    private errorService: ErrorService,
    private humanService: HumanService,
    private locationService: LocationService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get human's address.
    this.getAddress();
  }

  // Get human's address.
  private getAddress(): void {
    this.isLoading = true;

    this.humanService
      .getHumanAddress(this.selHuman.id)
      .then(res => {
        this.address = res.rows as IAddress;

        if (this.address) {
          this.locationService
            .getCityById(this.address.cityId)
            .then(resCity => {
              this.city = resCity.rows as ICity;

              if (this.city) {
                this.locationService
                  .getStateById(this.city.stateId)
                  .then(resState => {
                    this.state = resState.rows as IState;
                  })
                  .catch(errState => {
                    this.utilsService.showAlert(
                      'vh-address',
                      this.errorService.manageError(
                        errState,
                        this.constructor.name,
                        'getAddress() > locationService.getStateById()'
                      ),
                      EAlertType.Error
                    );
                  })
                  .finally(() => (this.isLoading = false));
              } else {
                this.isLoading = false;
              }
            })
            .catch(errCity => {
              this.utilsService.showAlert(
                'vh-address',
                this.errorService.manageError(
                  errCity,
                  this.constructor.name,
                  'getAddress() > locationService.getCityById()'
                ),
                EAlertType.Error
              );
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      })
      .catch(err => {
        this.utilsService.showAlert(
          'vh-address',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getAddress() > humanService.getHumanAddress()'
          ),
          EAlertType.Error
        );
        this.isLoading = false;
      });
  }
}
