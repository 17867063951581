// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import {
  IAPIResponse,
  IHumanLocalData,
  IClinic,
  INewProceduresRequest,
  INewProceduresItem,
  IProceduresItem,
  IUpdateProceduresItem,
  ICancelProceduresItem,
  ProcedureStatusMap,
  EProcedureRequestStatusCode,
  IProceduresRequestUpdate,
} from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const epMain: string = endpoint + 'procedures/';
const epRequest: string = endpoint + 'procedures-requests/';
const epRequestItem: string = endpoint + 'procedures-requests-items/';
const epRequestStatus: string = endpoint + 'procedures-requests-status/';

@Injectable({ providedIn: 'root' })
export class ProceduresService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Procedure Status Mapping
  private procedureStatusMap: ProcedureStatusMap = {
    pending: 1,
    approved: 2,
    completed: 3,
    refused: 4,
    canceled: 5,
  };

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all available procedures for a petId.
  public getByPet(petId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'pet/' + petId, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic requests.
  public getClinicRequests(
    clinic: IClinic,
    status: number | null
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const url = `${epRequest}clinic/${clinic.id}${
      status !== null ? `?status=${status}` : ''
    }`;

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(url, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new procedure request.
  public createNewRequest(req: INewProceduresRequest): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epRequest, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get procedure request.
  public getProcedureRequest(requestId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epRequest + requestId, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update an existing procedure request.
  public updateRequest(req: IProceduresRequestUpdate): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epRequest + req.id, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new procedure request item.
  public createNewRequestItem(req: INewProceduresItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epRequestItem, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update an existing procedure request item.
  public updateRequestItem(req: IUpdateProceduresItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epRequestItem + req.id, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Delete a request item.
  public deleteRequestItem(ri: ICancelProceduresItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(epRequestItem + ri.id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get status.
  public getStatus(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epRequestStatus, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Filter request items.
  public filterRequestItems(
    status: number | null,
    from: number,
    to: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const url = `${epRequest}filter?${
      status !== null ? `status=${status}&` : ''
    }from=${from}&to=${to}`;

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http.get<IAPIResponse>(url, { headers: this.headers }).subscribe(
        res => resolve(res),
        err => reject(err)
      );
    });

    return promise;
  }

  // Convert status name to id
  public convertProcedureStatusToID: (
    status: EProcedureRequestStatusCode
  ) => number | undefined = status => this.procedureStatusMap[status];

  // Get request status.
  public getRequestStatus(s: string): string {
    switch (s) {
      case 'pending':
        return 'Aguardando aprovação';
      case 'approved':
        return 'Aprovado';
      case 'completed':
        return 'Finalizado';
      case 'refused':
        return 'Recusado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Aguardando aprovação';
    }
  }
}
