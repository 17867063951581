<section *ngIf="isLoaded" id="account" class="account-container" [@slideDown]>
  <!-- Register new user. -->
  <app-register-form
    *ngIf="currentPage === 'Register'"
    [zipCode]="zipCode"
    (redirectToMain)="redirectToMain($event)"></app-register-form>

  <!-- Register for notification. -->
  <app-register-notification
    *ngIf="currentPage === 'Notification'"
    (redirectToMain)="redirectToMain($event)"></app-register-notification>

  <router-outlet></router-outlet>
</section>
