<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <!-- Count. -->
  <div class="subscriptions-count">
    <p *ngIf="subscriptions.length === 0">O usuário não possui assinaturas.</p>
    <p *ngIf="subscriptions.length === 1">1 assinatura localizada.</p>
    <p *ngIf="subscriptions.length > 1">
      {{ subscriptions.length }} assinaturas localizadas.
    </p>
  </div>

  <!-- [SUBSCRIPTIONS: START] -->
  <div *ngIf="subscriptions.length > 0" class="subscriptions-list">
    <div
      *ngFor="let s of subscriptions; let i = index"
      class="sub-item-container">
      <h1 content="notranslate" class="notranslate">
        {{ i + 1 }}. {{ s.pet.name }}
      </h1>

      <div class="row">
        <!-- Created at. -->
        <div class="frm-element frm-el-inline">
          <label>Created at:</label>
          <div class="div-item">
            {{ s.createdAt ? (s.createdAt | date : 'dd/MM/yyyy') : '---' }}
          </div>
        </div>

        <!-- Start Date -->
        <div class="frm-element frm-el-inline">
          <label>Start at:</label>
          <div class="div-item">
            {{ s.startDate ? (s.startDate | date : 'dd/MM/yyyy') : '---' }}
          </div>
        </div>

        <!-- End date. -->
        <div class="frm-element frm-el-inline">
          <label>End date:</label>
          <div class="div-item">
            {{ s.endDate ? (s.endDate | date : 'dd/MM/yyyy') : '---' }}
          </div>
        </div>

        <!-- Next recurrency. -->
        <div class="frm-element frm-el-inline">
          <label>Next recurrency:</label>
          <div class="div-item">
            {{
              s.nextRecurrency
                ? (s.nextRecurrency | date : 'dd/MM/yyyy')
                : '---'
            }}
          </div>
        </div>

        <!-- Interval. -->
        <div class="frm-element frm-el-inline">
          <label>Interval:</label>
          <div class="div-item">{{ s.interval ? s.interval : '---' }}</div>
        </div>
      </div>

      <div class="row">
        <!-- Amount. -->
        <div class="frm-element frm-el-inline">
          <label>Amount:</label>
          <div class="div-item">
            {{ s.amount ? (s.amount | zcurrency) : '---' }}
          </div>
        </div>

        <!-- Coupon. -->
        <div class="frm-element frm-el-inline">
          <label>Coupon:</label>
          <div class="div-item">{{ s.coupon || '---' }}</div>
        </div>
      </div>

      <div class="row">
        <!-- Payment id. -->
        <div class="frm-element frm-el-inline">
          <label>Payment ID:</label>
          <div class="div-item">{{ s.paymentId ? s.paymentId : '---' }}</div>
        </div>

        <!-- Recurrent ayment id. -->
        <div class="frm-element frm-el-inline">
          <label>Rec. Payment ID:</label>
          <div class="div-item">{{ s.recPayId ? s.recPayId : '---' }}</div>
        </div>
      </div>

      <div class="row">
        <!-- TID. -->
        <div class="frm-element frm-el-inline">
          <label>TID:</label>
          <div class="div-item">{{ s.externalId ? s.externalId : '---' }}</div>
        </div>

        <!-- AuthCode. -->
        <div class="frm-element frm-el-inline">
          <label>Auth. code:</label>
          <div class="div-item">{{ s.authCode ? s.authCode : '---' }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- [SUBSCRIPTIONS: END] -->
</div>
