<div *ngIf="showContent" class="not-found-container">
  <div>
    <img
      *ngIf="!isMobile"
      class="img-logo"
      src="assets/images/petbee.png"
      (click)="goToPage('/')" />
  </div>

  <div>
    <span class="badge badge-warning badge-lg">{{
      'NOT_FOUND.PAGE_NOT_FOUND' | translate
    }}</span>
  </div>

  <div style="font-size: small">
    <a (click)="goToPage('')">{{ 'NOT_FOUND.RETURN_TO_HOME' | translate }}</a>
  </div>
</div>
