// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App enumerators.
import { ELogTypes } from '@app/core/models/';

// App interfaces.
import { ILogType } from '@app/core/models/';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api + 'logs/';

@Injectable({ providedIn: 'root' })
export class LogService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(private authService: AuthService, private http: HttpClient) {}

  // Get all logs.
  public getAllLogs(): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<any>((resolve, reject) => {
      this.http.get<any>(endpoint, { headers: this.headers }).subscribe(
        result => {
          resolve(result.rows);
        },
        error => {
          reject(error);
        }
      );
    });

    return promise;
  }

  // Filter logs by interval.
  public filterByInterval(dtStart: string, dtEnd: string): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(
          endpoint + 'filter-interval?start=' + dtStart + '&end=' + dtEnd,
          { headers: this.headers }
        )
        .subscribe(
          result => {
            resolve(result.rows);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get raw log.
  public getRawLog(file: string): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(endpoint + 'raw/' + file, { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Return available log types.
  public getLogTypes(lang: string): ILogType[] {
    const types: ILogType[] = [];

    if (lang === 'pt-BR') {
      types.push({ title: 'Pagamento', type: ELogTypes.Payment });
    } else if (lang === 'en-US') {
      types.push({ title: 'Payment', type: ELogTypes.Payment });
    }

    return types;
  }
}
