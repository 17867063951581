export const environment = {
  production: false,
  localhost: true,
  consoleLog: true,
  cryptoKey: '#zpets&!19',
  links: {
    invitation: 'https://clinics.petbee.com.br?iv=',
  },
  rafMilestone: [
    {
      id: 1,
      name: 'Crédito de R$20,00',
      description: 'R$ 20,00 em créditos abatidos do rateio do mês.',
      neededQty: 1,
    },
    {
      id: 2,
      name: 'Vacinação ou R$100,00',
      description:
        'Aplicação em domicílio da vacina V8 para cães e vacina V5 para gatos na região metropolitana de Curitiba. Valor equivalente à vacina V8 e V5, totalizando R$ 120,00, para o indicador da cidade de Pato Branco.',
      neededQty: 3,
    },
    {
      id: 3,
      name: 'Consulta domiciliar ou R$200,00',
      description:
        'Consulta e aplicação da vacina antirrábica em domicílio na região metropolitana de Curitiba. Valor equivalente à consulta e aplicação da vacina antirrábica, totalizando R$ 200,00, para o indicador da cidade de Pato Branco.',
      neededQty: 5,
    },
    {
      id: 4,
      name: 'Limpeza dentária ou R$500,00',
      description:
        'Valor equivalente à limpeza dentária, totalizando R$ 500,00, independente da localização do indicador.',
      neededQty: 10,
    },
  ],
  pets: {
    ageLimit: {
      cat: 10,
      dog: 9,
    },
    genericCatAvatar: 'assets/images/avatar/avatar_cat.png',
    genericDogAvatar: 'assets/images/avatar/avatar_dog.png',
    uploadPath: 'https://assets.dev.petbee.com.br/private/files/images',
  },
  apportionment: {
    maxValue: 4990,
  },
  plans: {
    default: {
      monthlValue: 6980,
      annualValue: 83760,
    },
    vaccines: {
      monthlValue: 2000,
      annualValue: 22000,
    },
    checkup: {
      monthlValue: 3000,
      annualValue: 32000,
    },
    dental: {
      monthlValue: 6000,
      annualValue: 72000,
    },
  },
  storageKeys: {
    invitationCode: 'inviteCode',
    social: 'soclogin',
    user: 'zph',
    updateUser: 'upusr',
    hsUserIdentification: 'hsUserIdentification',
  },
  uploadPath: 'https://assets.dev.petbee.com.br/private/files/images',
  urls: {
    api: 'https://apiv1.dev.petbee.com.br/',
    app: 'https://clinics.dev.petbee.com.br/',
    site: 'https://www.petbee.com.br/',
  },
  petbeeInvitationCode: 'ZP1591713669101',
  petbeeTestCoupon: 'zp321',
  datadog: {
    applicationId: '99c3f4f0-632a-4732-a4f9-bb817647d437',
    clientToken: 'pub76dfa480147563feefbcd35366610ce2',
    site: 'datadoghq.com',
    service: 'clinicas.dev.petbee.com.br',
    env: 'development',
  },
};
