// Angular modules.
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';
import LogRocket from 'logrocket';
import { datadogRum } from '@datadog/browser-rum';

// App animations.
import { fadeAnimation, fullLoaderAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast } from '@app/core/models';

// App services.
import {
  AuthService,
  BroadcastService,
  MobileService,
  HubSpotService,
} from '@app/core/services';

// Interface.
import { IHumanLocalData } from '@app/core';

// Version
import { version } from '../../package.json';
import { ProcessCredentials } from 'aws-sdk';
import { environment } from '@env/environment';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation, fullLoaderAnimation],
})
export class AppComponent {
  // Flags.
  public isBrowser = isPlatformBrowser(this.platformId);

  // Active page.
  public showFullLoader: boolean = true;
  public showApp: boolean = false;
  public showLogin: boolean = false;
  public showNotFound: boolean = false;
  public showPartners: boolean = false;
  public showRegisterPage: boolean = false;
  public showRememberPassPage: boolean = false;
  public showResetPassPage: boolean = false;

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public showReferModal: boolean = false;
  public containerLocked: boolean = false;

  // Logged human's data.
  private human: IHumanLocalData = this.authService.getLocalData();

  // Constructor method.
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private broadcastService: BroadcastService,
    private mobileService: MobileService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private hubSpotService: HubSpotService
  ) {
    // Start LogRocket
    if (environment.production) {
      if (this.human) {
        this.identifyHubspot();
        this.startLogRocket();
        this.startDatadog();
        // this.startHubSpot();
      } else {
        if (this.router && this.router.url.includes('register')) {
          this.identifyHubspot();
          this.startLogRocket();
          this.startDatadog();
        }
      }
    }

    // Set current language.
    this.setLanguage('pt-BR', false);

    // Subscribe to router events.
    this.listenToRouter();

    // Subscribe to broadcast events.
    this.listenToBroadcast();

    // Hide loader.
    setTimeout(() => {
      this.showFullLoader = false;
    }, 2000);
  }

  // Change active language.
  public changeLanguage(): void {
    const lang: string = this.translate.currentLang;
    this.setLanguage(lang === 'pt-BR' ? 'en-US' : 'pt-BR');
  }

  // Hide full loader after 'n' miliseconds.
  public hideLoaderLater(ms: number): void {
    setTimeout(() => {
      this.showFullLoader = false;
    }, ms);
  }

  // When the window is resized.
  public onResize(event?: any): void {
    this.isMobile = this.mobileService.isMobile();
    // this.isMobile = false;
    this.broadcastService.sendBroadcast(
      EBroadcast.WindowResized,
      this.isMobile
    );
  }

  // Start LogRocket
  private startLogRocket(): void {
    LogRocket.init('5onhxt/zpets', {
      release: version,
    });

    if (this.human) {
      //Identify user LogRocket
      LogRocket.identify(this.human.id.toString(), {
        name: this.human.fullName,
        email: this.human.email,
      });
    }
  }

  // Start Datadog
  private startDatadog(): void {
    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.datadog.service,
      env: environment.datadog.env,
      // Specify a version number to identify the deployed version of your application in Datadog
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    });

    if (this.human) {
      // Identify user Datadog
      datadogRum.setUser({
        id: this.human.id.toString(),
        name: this.human.fullName,
        email: this.human.email,
      });
    }

    datadogRum.startSessionReplayRecording();
  }

  // Identify HubSpot
  private async identifyHubspot(): Promise<void> {
    if (this.human) {
      window._hsq.push(['identify', { email: this.human.email }]);
      window._hsq.push(['trackPageView']);
    }
  }

  // // Start HubSpot
  // private async startHubSpot(): Promise<void> {
  //   if (this.human) {
  //     //Identify user HubSpot
  //     const tokenRes =
  //       await this.hubSpotService.getVisitorIdentificationToken();

  //     let attempts = 0;
  //     const intervalId = setInterval(() => {
  //       if (window.HubSpotConversations) {
  //         this.hubSpotService.setHsSettings(this.human.email, tokenRes);
  //         window.HubSpotConversations.widget.load();
  //         clearInterval(intervalId);
  //       } else {
  //         if (attempts === 5) {
  //           console.log('Failed to load chat after maximum number of attempts');
  //           clearInterval(intervalId);
  //         }
  //         attempts++;
  //       }
  //     }, 1000);
  //   }
  // }

  // Set current language.
  private setLanguage(lang: string, broadcast: boolean = true): void {
    // This language will be used as a fallback when a translation isn't found in the current language.
    this.translate.setDefaultLang(lang);

    // The lang to use, if the lang isn't available, it will use the current loader to get them.
    this.translate.use(lang);

    // Send broadcast.
    if (broadcast) {
      this.containerLocked = true;
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.ChangedLanguage, lang);
        this.containerLocked = false;
      }, 500);
    }
  }

  // Listen to router events.
  private listenToRouter(): void {
    this.router.events.subscribe((ev: Event) => {
      if (ev instanceof NavigationEnd) {
        this.showPage(ev.url);
      }
    });
  }

  // Listen to broadcast events.
  private listenToBroadcast(): void {
    this.broadcastService.events.subscribe(ev => {
      switch (ev.key) {
        // API error.
        case EBroadcast.ApiError:
          this.handleApiError(ev.value);
          break;

        // Change language.
        case EBroadcast.ChangeLanguage:
          this.setLanguage(ev.value);
          break;

        // Hide full loader.
        case EBroadcast.FullLoaderOff:
          this.showFullLoader = false;
          this.showPage(ev.value);
          break;

        // Show/hide refer modal (mobile only).
        case EBroadcast.ToggleReferModal:
          this.showReferModal = !this.showReferModal;
          break;

        // Show full loader.
        case EBroadcast.FullLoaderOn:
          this.showFullLoader = true;
          break;
      }
    });
  }

  // Handle API error.
  private handleApiError(status: number): void {
    // Unauthorized.
    if (status === 401) {
      const u: string = this.router.url;
      if (u !== '/login' && u !== 'login') {
        this.router.navigate(['/login']);
      }
    }
  }

  // Show a specific page.
  private showPage(path?: string): void {
    if (path) {
      this.showApp = false;
      this.showLogin = false;
      this.showNotFound = false;
      this.showPartners = false;
      this.showRegisterPage = false;
      this.showRememberPassPage = false;
      this.showResetPassPage = false;

      this.removeFromBody('hidden-overflow');

      if (path.search('/invitation') > -1) {
        this.manageInvitation(path, 1);
      } else if (path.search('iv=') > -1) {
        this.manageInvitation(path, 2);
      } else if (path === '/login') {
        this.showLogin = true;
        this.appendToBody('hidden-overflow');
      } else if (path === '/logout' || path === '/logoff') {
        this.router.navigate(['/login']);
      } else if (path === 'not-found') {
        this.showNotFound = true;
        this.appendToBody('hidden-overflow');
      } else if (path.includes('/partners')) {
        this.showPartners = true;
        this.appendToBody('hidden-overflow');
      } else if (path.includes('/register')) {
        this.showRegisterPage = true;
        this.appendToBody('hidden-overflow');
      } else if (path === '/remember-password') {
        this.showRememberPassPage = true;
        this.appendToBody('hidden-overflow');
      } else if (path.includes('/reset-password')) {
        this.showResetPassPage = true;
        this.appendToBody('hidden-overflow');
      } else {
        this.showApp = true;
      }
    }
  }

  // Manage invitation.
  private manageInvitation(path: string, mod: number): void {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const inviteCode = params.get('iv');

    if (inviteCode) {
      localStorage.setItem(environment.storageKeys.invitationCode, inviteCode);
    }

    this.router.navigate(['register/form']);
  }

  // Append class to body element.
  private appendToBody(value: string): void {
    document.getElementsByTagName('body')[0].classList.add(value);
  }

  // Remove class from body element.
  private removeFromBody(value: string): void {
    document.getElementsByTagName('body')[0].classList.remove(value);
  }
}

// Multiple Angular-Cli: NPX
