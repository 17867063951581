<section id="login" *ngIf="isLoaded" class="login-container" [@slideDown]>
  <!-- Side column. -->
  <div class="login-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div class="login-content login-form">
    <div class="form-container" [@slideDown]>
      <!-- Logo (mobile only). -->
      <img
        *ngIf="isMobile"
        class="img-logo mob-logo"
        src="assets/images/petbee.png"
        (click)="goToHome()" />

      <h1 style="font-style: italic">
        {{ 'LOGIN.GENERAL.IDENTIFICATION' | translate }}
      </h1>

      <!-- Facebook login. -->
      <button
        type="button"
        class="btn btn-social btn-facebook btn-icon-label"
        (click)="facebookLogin()"
        style="display: none !important">
        <span class="btn-inner--icon">
          <i class="fab fa-facebook"></i>
        </span>
        <span class="btn-inner--text">
          <span class="spn-social-name">{{
            'LOGIN.GENERAL.SIGNIN_WITH' | translate
          }}</span>
          Facebook
        </span>
        <span *ngIf="waitingFacebook" class="spn-spin spn-spin-facebook">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
      </button>

      <!-- Google login. -->
      <button
        type="button"
        class="btn btn-social btn-google btn-icon-label g-signin2"
        data-onsuccess="onGoogleSignIn"
        style="display: none !important">
        <span class="btn-inner--icon">
          <i class="fab fa-google"></i>
        </span>
        <span class="btn-inner--text">
          <span class="spn-social-name">{{
            'LOGIN.GENERAL.SIGNIN_WITH' | translate
          }}</span>
          Google
        </span>
        <span *ngIf="waitingGoogle" class="spn-spin spn-spin-google">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
      </button>

      <p class="p-separator" style="display: none">
        ----- <strong>{{ 'GENERIC.OR' | translate | lowercase }}</strong> -----
      </p>

      <!-- E-Mail. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="email"
          id="txt-email"
          [(ngModel)]="email"
          (focusout)="validateEmail($event)"
          (keydown.enter)="signIn($event)"
          [disabled]="isSignin"
          appInputs />
        <span class="frm-label">{{ 'LOGIN.ELEMENTS.EMAIL' | translate }}</span>
      </div>
      <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

      <!-- Password. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="password"
          id="txt-password"
          [(ngModel)]="password"
          (keydown.enter)="signIn($event)"
          [disabled]="isSignin"
          appInputs />
        <span class="frm-label">{{
          'LOGIN.ELEMENTS.PASSWORD' | translate
        }}</span>
      </div>
      <span *ngIf="errPassword" class="spn-error">{{ errPassword }}</span>

      <div *ngIf="globalError" class="global-error">
        {{ globalError }}
      </div>

      <!-- Login button. -->
      <div class="form-element">
        <button
          type="button"
          class="btn btn-soft-info btn-full"
          (click)="signIn()"
          [disabled]="isSignin">
          <span *ngIf="!isSignin">{{
            'LOGIN.ELEMENTS.SIGN_IN' | translate
          }}</span>
          <span *ngIf="isSignin"><i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <div class="forgot-pass">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/remember-password')">
            {{ 'LOGIN.ELEMENTS.FORGOT_PASSWORD' | translate }}
          </button>
          <!--<a href="/remember-password" role="button">{{ 'LOGIN.ELEMENTS.FORGOT_PASSWORD' | translate }}</a>-->
        </div>

        <div class="new-account">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/register/form')">
            {{ 'LOGIN.ELEMENTS.REGISTER' | translate }}
          </button>
          <!--<a href="/register" role="button">{{ 'LOGIN.ELEMENTS.REGISTER' | translate }}</a>-->
        </div>
      </div>
    </div>
  </div>
  <!-- [CONTENT: END] -->

  <!-- Empty column. -->
  <div class="login-content lg-b"></div>
</section>
