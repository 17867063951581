<section class="modal-container" [@slideUpDown]>
  <div class="pet-register-modal">
    <!-- Close modal. -->
    <i class="fas fa-times" (click)="closeModal(false)"></i>

    <!-- Title. -->
    <div class="modal-title">Registrar novo pet</div>

    <div class="content-tip">
      Você deve associar um pet à sua clínica para realizar algum
      tratamento/procedimento. O proprietário e o pet devem estar previamente
      cadastrados.
    </div>

    <!-- [CONTENT: START] -->
    <div *ngIf="!isLoading" class="modal-content" style="padding: 10px 0 20px">
      <div class="search-type">
        <p>Tipo de busca:</p>
        <!-- <input
          type="radio"
          name="rad-type"
          [(ngModel)]="searchType"
          value="0"
          (click)="changeSearchType('0')"
        ><span class="spn-radio" (click)="changeSearchType('0')">Pelo proprietário</span> -->
        <input
          type="radio"
          name="rad-type"
          [(ngModel)]="searchType"
          value="1"
          (click)="changeSearchType('1')"
          style="margin-left: 10px" /><span
          class="spn-radio"
          (click)="changeSearchType('1')"
          >Pelo Nº de identificação</span
        >
      </div>

      <!-- Pet's owner. -->
      <!-- <div *ngIf="searchType === '0'" class="frm-element">
        <div>
          <input
            id="pet-owner"
            class="frm-textbox"
            [ngClass]="{'el-has-value': ownerValue}"
            type="text"
            [(ngModel)]="ownerValue"
            (keyup.enter)="searchHumanPets()"
            [disabled]="isSearching || isSaving"
            appInputs
          >
          <span class="frm-label">{{ 'CLINICS.PETS.OWNER_DATA' | translate }}</span>
        </div>
      </div>
      <span *ngIf="errOwner" class="spn-error spn-err-input">{{errOwner}}</span> -->

      <!-- Nº de identificação. -->
      <div *ngIf="searchType === '1'" class="frm-element">
        <div>
          <input
            id="chip-code"
            class="frm-textbox"
            [ngClass]="{ 'el-has-value': chipCode }"
            type="text"
            [(ngModel)]="chipCode"
            (keyup.enter)="searchByCode()"
            [disabled]="isSearching || isSaving"
            appInputs />
          <span class="frm-label">{{
            'GENERIC.MICROCHIP_CODE' | translate
          }}</span>
        </div>
      </div>
      <span *ngIf="errChip" class="spn-error spn-err-input">{{ errChip }}</span>

      <!-- Owner's data. -->
      <div *ngIf="searchDone && owner" class="owner-data">
        <div class="h-picture">
          <img src="{{ humanService.getHumanPicture(owner) }}" />
        </div>
        <div class="h-data">
          <p>{{ owner.fullName }}</p>
          <p>{{ owner.phone | mask : '(00) 00000-0000' }}</p>
        </div>
      </div>

      <!-- [SEARCH RESULT: START] -->
      <div
        *ngIf="searchDone"
        class="search-result"
        [ngClass]="{ 'without-owner': searchType === '1' }">
        <!-- Human's pets. -->
        <div *ngIf="pets && pets.length > 0" class="table-container">
          <div
            class="content-tip"
            *ngIf="gracePeriodStatus && gracePeriodType === 'full'"
            [ngClass]="petStatusClass">
            Carência total. Não é permitido realizar consultas, exames e
            procedimentos. Restando {{ gracePeriodDaysLeft }} dia(s).
          </div>
          <div
            class="content-tip"
            *ngIf="gracePeriodStatus && gracePeriodType === 'partial'"
            [ngClass]="petStatusClass">
            Carência parcial. Permitido consultas. Não é permitido realizar
            exames e demais procedimentos. Restando
            {{ gracePeriodDaysLeft }} dia(s).
          </div>
          <div
            class="content-tip"
            *ngIf="
              gracePeriodStatus && gracePeriodType === 'partial-procedures'
            "
            [ngClass]="petStatusClass">
            Carência parcial. Permitido consultas e exames. Não é permitido
            realizar cirurgias e demais procedimentos. Restando
            {{ gracePeriodDaysLeft }} dia(s).
          </div>
          <div class="pet-container pet-item">
            <!-- [SIDEBAR: START] -->
            <div class="pet-sidebar pet-item-container">
              <img
                src="{{ petAvatar }}"
                class="pet-avatar"
                [ngClass]="petStatusClass" />

              <p class="pet-status" [ngClass]="petStatusClass">
                <i
                  *ngIf="
                    pets[0].subscription &&
                    pets[0].subscription.id &&
                    !pets[0].subscription.canceledAt &&
                    !pets[0].subscription.blocked
                  "
                  class="far fa-grin-wink"></i>
                {{ petService.getPetStatus(pets[0].subscription, false) }}
              </p>
            </div>
            <!-- [SIDEBAR: END] -->

            <!-- [DYNAMIC CONTENT: START] -->
            <div class="pet-content">
              <!-- Resume. -->
              <app-mc-register-resume [pet]="pets[0]"></app-mc-register-resume>
            </div>
            <!-- [DYNAMIC CONTENT: END] -->
          </div>
          <!-- <table class="pets-table">
            <thead>
              <tr>
                <th id="th-check"></th>
                <th id="th-name">Nome</th>
                <th id="th-chip">Nº de identificação</th>
                <th id="th-plan">Status do plano</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let p of pets; let i = index"
                [ngClass]="{ 'tr-unprotected': !isProtected(p) }"
                (click)="selectPet($event, p)">
                <td>
                  <i *ngIf="checkIfNewPet(p)" class="fas fa-check"></i>
                  <input
                    *ngIf="!checkIfNewPet(p)"
                    type="checkbox"
                    id="chk-{{ p.id }}"
                    [disabled]="!isProtected(p)" />
                </td>
                <td>{{ p.name }}</td>
                <td class="td-chip">
                  <span *ngIf="p.chip">{{ p.microchip }}</span>
                  <span *ngIf="!p.chip">---</span>
                </td>
                <td class="td-plan">
                  <span>{{
                    petService.getPetStatus(p.subscription, true)
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>

        <!-- Save button. -->
        <div
          *ngIf="pets.length > 0"
          style="margin-top: 20px; text-align: center">
          <button
            type="button"
            class="btn btn-sm btn-success btn-icon-label"
            (click)="registerPets()"
            [disabled]="disableRegisterButton()">
            <span class="btn-inner--icon"
              ><i class="fas fa-check-circle"></i
            ></span>
            <span *ngIf="!isSaving" class="btn-inner--text"
              >Registrar selecionado(s)</span
            >
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>

        <!-- No pets. -->
        <!--<div *ngIf="searchType === '1' && (!pets || pets.length === 0)">
          Nenhum pet localizado.
        </div>-->
      </div>
      <!-- [SEARCH RESULT: END] -->
    </div>
    <!-- [CONTENT: END] -->
  </div>
</section>
