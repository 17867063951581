// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App envirionment.
import { environment } from '@env/environment';

// App animations.
import { slideFadeAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast } from '@app/core/models';

// App models.
import { IMenu } from '@app/core';

// App services.
import { BroadcastService } from '@app/core/services';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  animations: [slideFadeAnimation],
})
export class MenuMobileComponent implements OnInit {
  // Outputs.
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  // Menu.
  public menu: IMenu[] = [];

  // Status.
  public isProd: boolean = environment.production;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private router: Router,
    private translate: TranslateService
  ) {
    // Subscribe to broadcast events.
    this.listenToBroadcast();

    // Subscribe to router events.
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.changedPage();
      }
    });
  }

  // On init.
  public ngOnInit(): void {
    // Build main menu.
    this.buildMenu();
  }

  // Close menu.
  public closeMenu(): void {
    this.close.emit();
  }

  // Go to another page.
  public goToPage(url: string): void {
    if (url === 'help') {
      window.open('https://wa.me/5546991048089', '_blank');
    } else {
      this.router.navigate([url]);
    }

    this.closeMenu();
    this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn);
    setTimeout(() => {
      this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOff);
    }, 1000);
  }

  // Listen to broadcast events.
  private listenToBroadcast(): void {
    this.broadcastService.events.subscribe(ev => {
      switch (ev.key) {
        // Change language.
        case EBroadcast.ChangedLanguage:
          this.buildMenu();
          break;
      }
    });
  }

  // Build menu items.
  private buildMenu(): void {
    this.menu = [];
    this.menu.push({
      active: true,
      icon: 'fas fa-clinic-medical',
      title: this.translate.instant('MENU.MAIN_MENU.HOME'),
      url: '/my-clinics',
    });
    // this.menu.push({
    //   active: false,
    //   icon: 'fas fa-user-md',
    //   title: this.translate.instant('MENU.MAIN_MENU.ALLOWED_CARES'),
    //   url: 'treatments',
    // });
    // /*this.menu.push({
    //   active: false, icon: 'fas fa-file-medical', title: this.translate.instant('MENU.MAIN_MENU.CARE_REQUESTS'), url: 'requests'
    // });*/
    // this.menu.push({
    //   active: false,
    //   icon: 'fas fa-clinic-medical',
    //   title: this.translate.instant('MENU.MAIN_MENU.AFFILIATED_CLINICS'),
    //   url: 'clinics',
    // });
    // this.menu.push({
    //   active: false,
    //   icon: 'fas fa-file-contract',
    //   title: this.translate.instant('MENU.MAIN_MENU.TRANSPARENCY_PORTAL'),
    //   url: 'transparency',
    // });
    /*this.menu.push({
      active: false, icon: 'fas fa-notes-medical', title: this.translate.instant('MENU.MAIN_MENU.REPORTS'), url: 'reports'
    });*/
    this.menu.push({
      active: false,
      icon: 'fas fa-question-circle',
      title: this.translate.instant('MENU.MAIN_MENU.HELP'),
      url: 'help',
    });
  }

  // When a new page is loaded.
  private changedPage(force?: string): void {
    const u: string = (force || this.router.url).replace('/', '');
    this.menu.map(item => (item.active = item.url === u));
  }
}
