<section *ngIf="isLoaded" id="reg-form" class="register-container" [@slideDown]>
  <!-- Side column. -->
  <div class="register-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [FORM: START] -->
  <div class="register-content register-form">
    <!-- [CONTAINER: START] -->
    <div *ngIf="newPartner && !saved" class="form-container">
      <p class="p-main-subtitle">
        Tem uma clínica ou laboratório e gostaria de fazer parte da Petbee? Então
        preencha os dados abaixo, envie o contrato assinado e aguarde o nosso
        contato.
      </p>

      <!--<h1 style="font-style: italic;">Novo cadastro</h1>-->

      <p class="p-subtitle">Dados da clínica</p>

      <!-- Name. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="text"
          id="txt-name"
          [(ngModel)]="newPartner.name"
          (blur)="validateForm()"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.CLINIC_NAME' | translate
        }}</span>
      </div>
      <span *ngIf="errName" class="spn-error">{{ errName }}</span>

      <!-- Contact name. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="text"
          id="txt-contact-name"
          [(ngModel)]="newPartner.contactName"
          (blur)="validateForm()"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.CONTACT_NAME' | translate
        }}</span>
      </div>
      <span *ngIf="errContactName" class="spn-error">{{ errContactName }}</span>

      <!-- E-Mail. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="email"
          id="txt-email"
          [(ngModel)]="newPartner.email"
          (blur)="validateForm()"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.EMAIL' | translate
        }}</span>
      </div>
      <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

      <!-- Phone. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="text"
          id="txt-phone"
          [(ngModel)]="newPartner.phone"
          mask="(00) 00000-0000"
          (blur)="validateForm()"
          [disabled]="isSaving"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.PHONE' | translate
        }}</span>
      </div>
      <span *ngIf="errPhone" class="spn-error">{{ errPhone }}</span>

      <div class="grid-row" style="grid-template-columns: 70% 30%">
        <!-- City. -->
        <div>
          <div class="frm-element" style="margin-right: 5px">
            <input
              class="frm-textbox"
              type="text"
              id="txt-addr-city"
              [(ngModel)]="newPartner.city"
              (blur)="validateForm()"
              [input]="newPartner.city"
              [disabled]="isSaving"
              appInputs />
            <span class="frm-label">{{
              'REGISTER.ELEMENTS.ADDRESS.CITY' | translate
            }}</span>
          </div>
          <span *ngIf="errAddrCity" class="spn-error">{{ errAddrCity }}</span>
        </div>

        <!-- State. -->
        <div>
          <div class="frm-element">
            <input
              class="frm-textbox"
              type="text"
              id="txt-addr-state"
              maxlength="2"
              [(ngModel)]="newPartner.state"
              (blur)="validateForm()"
              [input]="newPartner.state"
              [disabled]="isSaving"
              appInputs />
            <span class="frm-label">{{
              'REGISTER.ELEMENTS.ADDRESS.STATE' | translate
            }}</span>
          </div>
          <span *ngIf="errAddrState" class="spn-error">{{ errAddrState }}</span>
        </div>
      </div>

      <hr class="divider divider-fade" />

      <p class="p-subtitle">
        Para finalizar, leia atentamente o contrato e a política de privacidade
        do site. Em seguida marque as opções que afirmam que você está de acordo
        com o conteúdo dos documentos.
      </p>

      <div class="accept-grid">
        <div class="ag-item">
          <span>Contrato</span>
          <div class="ag-img">
            <img
              class="img-contract"
              src="/assets/images/icons/doc-contract.png"
              (click)="toggleAgreementsWindow()" />
          </div>
          <div class="ag-chk" (click)="toggleContract()">
            <input [(ngModel)]="chkContract" type="checkbox" />Aceito o contrato
          </div>
        </div>
        <div class="ag-item">
          <span>Política de privacidade</span>
          <div class="ag-img">
            <img
              class="img-terms"
              src="/assets/images/icons/doc-terms.png"
              (click)="toggleTermsWindow()" />
          </div>
          <div class="ag-chk" (click)="togglePolicy()">
            <input [(ngModel)]="chkPolicy" type="checkbox" />Aceito a política
          </div>
        </div>
      </div>

      <!-- Save button. -->
      <div style="margin: 40px 0; text-align: center">
        <button
          type="button"
          class="btn btn-success btn-icon-label"
          (click)="createPartner()"
          [disabled]="isSaving">
          <span class="btn-inner--icon">
            <i class="fas fa-user-check"></i>
          </span>
          <span *ngIf="!isSaving" class="btn-inner--text">Criar conta</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <button class="btn btn-link btn-link-thin" (click)="goToHome()">
          Ir para o site
        </button>
      </div>
    </div>
    <!-- [CONTAINER: END] -->

    <!-- [SAVED: START] -->
    <div *ngIf="newPartner && saved" class="form-container saved-container">
      <p class="saved-title">Pré-cadastro efetuado com sucesso!</p>
      <p>
        Você receberá um e-mail com as demais instruções e uma cópia do contrato
        anexada. Assim que recebermos a cópia do contrato assinada entraremos em
        contato para efetivar o cadastro.
      </p>
      <button class="btn btn-link btn-link-thin" (click)="goToHome()">
        Ir para o site
      </button>
    </div>
    <!-- [SAVED: END] -->
  </div>
  <!-- [FORM: END] -->

  <!-- Empty column. -->
  <div class="login-content lg-b"></div>
</section>

<!-- Alerts. -->
<app-alerts id="alert-reg-frm" style="display: none"></app-alerts>

<!-- Agreements modal. -->
<app-agreement-partners
  *ngIf="isAgreementVisible"
  (closeModal)="toggleAgreementsWindow($event)"></app-agreement-partners>

<!-- Terms of use modal. -->
<app-termsofuse
  *ngIf="isTermsVisible"
  (closeModal)="toggleTermsWindow($event)"></app-termsofuse>
