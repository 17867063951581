// Angular modules.
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  // Interceptor for prevent HTTP cache.
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    /*const httpRequest = req.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
    });*/
    const httpRequest = req.clone();
    httpRequest.headers.append('Cache-control', 'no-cache');
    httpRequest.headers.append('Cache-control', 'no-store');
    httpRequest.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    httpRequest.headers.append('Pragma', 'no-cache');

    return next.handle(httpRequest);
  }
}
