// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { RegisterComponent } from '@app/register/register.component';
import { RegisterAvailabilityComponent } from '@app/register/register-availability/register-availability.component';
import { RegisterFormComponent } from '@app/register/register-form/register-form.component';
import { RegisterNotificationComponent } from '@app/register/register-notification/register-notification.component';
import { RegisterStartComponent } from '@app/register/register-start/register-start.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: RegisterFormComponent,
    data: {
      title: 'Petbee - Crie sua conta',
      description:
        'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
      ogUrl: 'http:/www.petbee.com.br',
    },
    children: [
      {
        path: 'availability',
        component: RegisterAvailabilityComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
      {
        path: 'form',
        component: RegisterFormComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
      {
        path: 'form/:zipCode',
        component: RegisterFormComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
      {
        path: 'notification',
        component: RegisterNotificationComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
      {
        path: 'notification/:id',
        component: RegisterNotificationComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
      {
        path: 'start',
        component: RegisterStartComponent,
        data: {
          title: 'Petbee - Crie sua conta',
          description:
            'Cadastre-se e proteja seus pets na maior rede colaborativa do Brasil.',
          ogUrl: 'http:/www.petbee.com.br',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegisterRoutingModule {}
