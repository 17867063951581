// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App models.
import {} from '@app/core/models';

// App interfaces.
import {
  IAddress,
  IAPIResponse,
  IClinic,
  IEmailMessage,
  IHuman,
  INewClinicRegister,
} from '@app/core/models';

// App services.
import { AddressService } from '@app/core/services/address/address.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { EmailService } from '@app/core/services/email/email.service';
import { HumanService } from '@app/core/services/human/human.service';
import { PetService } from '@app/core/services/pet/pet.service';

// Email template
import { newAssociateTemplate } from './new.associate.template';
import { newPartnerTemplate } from './new.partner.template';

// Main endpoint.
const endpoint: string = environment.urls.api + 'clinics/';
const clinicCustomersEndpoint: string =
  environment.urls.api + 'clinics-customers/';
const clinicHumansEndpoint: string = environment.urls.api + 'clinics-humans/';
const clinicPetsEndpoint: string = environment.urls.api + 'clinics-pets/';

@Injectable({ providedIn: 'root' })
export class ClinicsService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private addressService: AddressService,
    private authService: AuthService,
    private emailService: EmailService,
    private http: HttpClient,
    private humanService: HumanService,
    private petService: PetService,
    private translate: TranslateService
  ) {}

  // Get all active clinics.
  public getAll(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic by ID.
  public getWithId(clinicId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + clinicId, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinics by city.
  public getByCity(cityId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'city/' + cityId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Create a new clinic.
  public create(clinic: IClinic, address: IAddress): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      // Create clinic.
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'clinics',
          JSON.stringify(clinic),
          { headers: this.headers }
        )
        .subscribe(
          resClinic => {
            if (resClinic) {
              clinic.id = resClinic.rows.id;

              // Create address.
              this.http
                .post<IAPIResponse>(
                  environment.urls.api + 'addresses',
                  JSON.stringify(address),
                  { headers: this.headers }
                )
                .subscribe(
                  resAddr => {
                    address.id = resAddr.rows.id;

                    // Create clinic-address association.
                    this.http
                      .post<IAPIResponse>(
                        environment.urls.api + 'clinics-addresses',
                        JSON.stringify({
                          clinicId: clinic.id,
                          addressId: address.id,
                        }),
                        { headers: this.headers }
                      )
                      .subscribe(
                        res => {
                          resolve(resClinic);
                        },
                        err => {
                          reject(err);
                        }
                      );
                  },
                  errAddr => {
                    reject(errAddr);
                  }
                );
            } else {
              reject('Erro ao criar a clínica');
            }
          },
          errClinic => {
            reject(errClinic);
          }
        );
    });

    return promise;
  }

  // Update clinic.
  public updateClinic(
    clinic: IClinic,
    address?: IAddress
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(
          environment.urls.api + 'clinics/' + clinic.id,
          JSON.stringify(clinic),
          { headers: this.headers }
        )
        .subscribe(
          result => {
            if (address) {
              this.http
                .put<IAPIResponse>(
                  environment.urls.api + 'addresses/' + address.id,
                  JSON.stringify(address),
                  { headers: this.headers }
                )
                .subscribe(
                  res => {
                    resolve(result);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Delete a clinic.
  public deleteClinic(clinic: IClinic): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(environment.urls.api + 'clinics/' + clinic.id, {
          headers: this.headers,
        })
        .subscribe(
          result => {
            resolve(result);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get clinics and partners count.
  public getTotals(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(environment.urls.api + 'partners/get-totals', {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get multiple clinics.
  public getMultiple(ids: number[]): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(endpoint + 'multiple/', JSON.stringify({ ids }), {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Create new user.
  public associateHuman(
    clinicId: number,
    humanId: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          clinicHumansEndpoint,
          JSON.stringify({ clinicId, humanId }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            this.sendNewAssociateEmail(humanId, clinicId);
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Disassociate human.
  public disassociateHuman(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(clinicHumansEndpoint + id, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Associate new pet.
  public associatePet(clinicId: number, petId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          clinicPetsEndpoint,
          JSON.stringify({ clinicId, petId }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            this.associateCustomer(clinicId, petId);
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Disassociate pet.
  public disassociatePet(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(clinicPetsEndpoint + id, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Associate new customer.
  public associateCustomer(
    clinicId: number,
    petId: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.petService
        .getOwners(petId)
        .then(result => {
          result.rows.map((r: any) => {
            this.http
              .post<IAPIResponse>(
                clinicCustomersEndpoint,
                JSON.stringify({ clinicId, humanId: r.id }),
                { headers: this.headers }
              )
              .subscribe(
                res => {
                  resolve(res);
                },
                err => {
                  reject(err);
                }
              );
          });
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise;
  }

  // Disassociate customer.
  public disassociateCustomer(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(clinicCustomersEndpoint + id, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic pets.
  public getPets(clinicId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(clinicPetsEndpoint + 'clinic/' + clinicId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic's customers.
  public getCustomers(clinicId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(clinicCustomersEndpoint + 'clinic/' + clinicId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Create new partner (pre-client).
  public createPartner(partner: INewClinicRegister): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'partners',
          JSON.stringify(partner),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            this.sendNewPartnerEmail(partner);
            resolve();
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Delete a partner (pre-client).
  public deletePartner(partner: INewClinicRegister): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(environment.urls.api + 'partners/' + partner.id, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve();
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Return all partners (pre-registered clinis).
  public getAllPartners(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(environment.urls.api + 'partners', {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Build and send new clinic associate e-mail message.
  private sendNewAssociateEmail(humanId: number, clinicId: number): void {
    this.getWithId(clinicId)
      .then(resCli => {
        const clinic: IClinic = resCli.rows as IClinic;

        this.humanService
          .getWithId(humanId)
          .then(resHum => {
            const human: IHuman = resHum as IHuman;

            const msg: string = newAssociateTemplate(human, clinic);

            const email: IEmailMessage = {
              message: msg,
              senderEmail: 'suporteclinicas@petbee.com.br',
              senderName: 'Petbee',
              recipientEmail: human.email,
              recipientName: null,
              senderPhone: null,
              subject: this.translate.instant('REGISTER.MESSAGES.WELCOME'),
            };

            this.emailService
              .sendEmail(email)
              .then(res => {})
              .catch(err => console.error(err));
          })
          .catch(errHum => {
            console.error(errHum);
          });
      })
      .catch(errCli => {
        console.error(errCli);
      });
  }

  // Build and send new partner e-mail message.
  private sendNewPartnerEmail(partner: INewClinicRegister): void {
    const msg: string = newPartnerTemplate(partner);

    const email: IEmailMessage = {
      fileName: 'Termo de adesão ao sistema Petbee.pdf',
      filePath:
        environment.urls.app + 'assets/docs/Termo-adesao-clinicas-Petbee.pdf',
      message: msg,
      senderEmail: 'suporteclinicas@petbee.com.br',
      senderName: 'Petbee',
      recipientEmail: partner.email,
      recipientName: null,
      senderPhone: null,
      subject: this.translate.instant('REGISTER.MESSAGES.WELCOME'),
    };

    this.emailService
      .sendEmail(email)
      // .then(res => {})
      .catch(err => console.error(err));
  }
}
