// Angular modules.
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Third-party.
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import LogRocket from 'logrocket';
import { datadogRum } from '@datadog/browser-rum';

// App environment.
import { environment } from '@env/environment';

// App models.
import { EAuthType, IAPIResponse } from '@app/core/models';

// App interfaces.
import {
  IHuman,
  IHumanLocalData,
  IHumanLocalLocation,
  IHumanLogin,
} from '@app/core/models';

// Main endpoint.
const endpoint: string = environment.urls.api + 'humans/';

// GTM.
// eslint-disable-next-line @typescript-eslint/ban-types
declare let dataLayer: any[];

@Injectable({ providedIn: 'root' })
export class AuthService {
  // Http.
  public headers: HttpHeaders = this.getHttpHeaders();

  // Constructor method.
  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService
  ) {}

  // Login.
  public login(loginData: IHumanLogin): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .post<any>(endpoint + 'login', JSON.stringify(loginData), {
          headers: this.headers,
        })
        .subscribe(
          res => {
            const h: IHuman = res.human as IHuman;

            const humanData: IHumanLocalData = {
              coupon: h.coupon,
              createdAt: h.createdAt,
              email: loginData.email,
              fullName: h.fullName,
              id: h.id.toString(),
              isAdmin: h.isAdmin,
              name: h.fullName.split(' ')[0],
              phone: h.phone,
              picture: null,
              token: res.token,
              document: h.document,
              invitedBy: h.invitedBy,
            };

            this.getActivePicture(h)
              .then(resPic => {
                humanData.picture = resPic;
              })
              .finally(() => {
                this.finishAuth(h, humanData).then(
                  r => resolve(r),
                  e => reject(e)
                );
              });
          },
          err => {
            if (err.status === 401 && err.statusText === 'Unauthorized') {
              // this.logout();
              reject(this.translate.instant('LOGIN.ERRORS.CANNOT_LOGIN'));
            } else {
              reject(err.error.message || err);
            }
            localStorage.removeItem('token');
          }
        );
    });

    return promise;
  }

  // Register social login.
  public socialLogin(
    human: IHuman,
    loginType: EAuthType,
    token: string
  ): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const humanData: IHumanLocalData = {
        coupon: human.coupon,
        createdAt: human.createdAt,
        email: human.email,
        fullName: human.fullName,
        id: human.id.toString(),
        isAdmin: human.isAdmin,
        name: human.fullName.split(' ')[0],
        phone: human.phone,
        picture: null,
        token,
        document: human.document,
      };

      this.getActivePicture(human)
        .then(resPic => {
          humanData.picture = resPic;
        })
        .finally(() => {
          this.finishAuth(human, humanData).then(
            res => resolve(res),
            err => reject(err)
          );
        });
    });

    return promise;
  }

  // Check Facebook login.
  public checkFacebookLogin(userId: string, token: string): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(endpoint + 'facebook/' + token, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (err.status === 401 && err.statusText === 'Unauthorized') {
              console.log('checkFacebookLogin', err);
              this.logout();
            } else {
              resolve(err.error.message || err);
            }
          }
        );
    });

    return promise;
  }

  // Logout.
  public logout(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const token: string = this.getUserToken();

      if (token) {
        this.http
          .post<any>(endpoint + 'logout/' + token, { headers: this.headers })
          .subscribe(
            res => {
              this.clearLocalData();
              // this.goToLogin();
              resolve(null);
            },
            err => {
              if (err.status === 401 && err.statusText === 'Unauthorized') {
                this.clearLocalData();
                // this.goToLogin();
                reject(this.translate.instant('LOGIN.ERRORS.CANNOT_LOGIN'));
              } else {
                resolve(err.error.message || err);
              }
            }
          );
      } else {
        this.clearLocalData();
        // this.goToLogin();
        resolve(null);
      }
    });

    return promise;
  }

  // Get user's data.
  public getUserData(): IHumanLocalData {
    const hd: IHumanLocalData = this.getLocalData();
    return hd || null;
  }

  // Get user's ID.
  public getUserId(): string {
    const hd: IHumanLocalData = this.getLocalData();
    return hd ? hd.id : null;
  }

  // Check if user is an admin.
  public getUserIsAdmin(): boolean {
    const hd: IHumanLocalData = this.getLocalData();
    return hd ? hd.isAdmin : false;
  }

  // Get user's name.
  public getUserName(): string {
    const hd: IHumanLocalData = this.getLocalData();
    return hd ? hd.name : null;
  }

  // Get user's e-mail.
  public getUserEmail(): string {
    const hd: IHumanLocalData = this.getLocalData();
    return hd ? hd.email : null;
  }

  // Get user's token.
  public getUserToken(): string {
    const hd: IHumanLocalData = this.getLocalData();
    return hd ? hd.token : null;
  }

  // Get user's active picture.
  public getActivePicture(h: IHuman): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      const genericPic: string = 'assets/images/avatar/generic-man.png';
      const isLocal: boolean = true;

      let avatar: string;
      if (h.picture) {
        avatar = this.getHumanPicuturesPath() + h.picture;
      } else {
        avatar = genericPic;
      }

      if (isLocal) {
        resolve(avatar);
      } else {
        this.http.get(avatar).subscribe(
          res => resolve(avatar),
          err => resolve(err.status === 200 ? avatar : genericPic)
        );
      }
    });

    return promise;
  }

  // Get a generic human picture.
  public getGenericPicture(): string {
    return 'assets/images/avatar/generic-man.png';
  }

  // Get humans pictures uploads path.
  public getHumanPicuturesPath(): string {
    return environment.uploadPath + '/users/';
  }

  // Get data into LocalStorage.
  public getLocalData(): IHumanLocalData {
    const data: string = localStorage.getItem(environment.storageKeys.user);
    if (data) {
      try {
        const dec: any = CryptoJS.AES.decrypt(data, environment.cryptoKey);
        const parsed: any = JSON.parse(dec.toString(CryptoJS.enc.Utf8));
        return parsed || null;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }

  // Update human's local data (with IHuman object).
  public updateHumansLocalData(h: IHuman): IHumanLocalData {
    const ld: IHumanLocalData = this.getLocalData();
    const newLd: IHumanLocalData = ld;

    Object.keys(ld).forEach(k => (newLd[k] = k in h ? h[k] : ld[k]));

    // Save updated data.
    this.saveLocalData(newLd);

    return newLd;
  }

  // Create remember/reset password key.
  public rememberPassword(email: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'reset-password',
          JSON.stringify({ email }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res.message);
          },
          err => {
            if (err.status === 401 && err.statusText === 'Unauthorized') {
              console.log('rememberPassword', err);
              this.logout();
            } else {
              resolve(err.error.message || err);
            }
          }
        );
    });

    return promise;
  }

  // Save new password (reset password option).
  public saveNewPassword(
    email: string,
    key: string,
    password: string
  ): Promise<IAPIResponse> {
    this.headers = this.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'reset-password/set-new',
          JSON.stringify({ email, key }),
          { headers: this.headers }
        )
        .subscribe(
          response => {
            if (response.count > 0) {
              this.http
                .patch<IAPIResponse>(
                  environment.urls.api + 'humans/update-pass',
                  JSON.stringify({ email, password }),
                  { headers: this.headers }
                )
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    if (
                      err.status === 401 &&
                      err.statusText === 'Unauthorized'
                    ) {
                      console.log('saveNewPassword', err);
                      this.logout();
                    } else {
                      resolve(err.error.message || err);
                    }
                  }
                );
            } else {
              reject(false);
            }
          },
          error => {
            if (error.status === 401 && error.statusText === 'Unauthorized') {
              console.log('saveNewPassword', error);
              this.logout();
            } else {
              reject(error.error.message || error);
            }
          }
        );
    });

    return promise;
  }

  // Update human's password (from change password form).
  public changePassword(
    humanId: string,
    newPassword: string
  ): Promise<IAPIResponse> {
    this.headers = this.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .patch<IAPIResponse>(
          environment.urls.api + 'humans/change-pass',
          JSON.stringify({ id: humanId, password: newPassword }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (err.status === 401 && err.statusText === 'Unauthorized') {
              console.log('changePassword', err);
              this.logout();
            } else {
              reject(err.error.message || err);
            }
          }
        );
    });

    return promise;
  }

  // Teste human's login.
  public testLogin(id: string, password: string): Promise<IAPIResponse> {
    this.headers = this.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'humans/test-login',
          JSON.stringify({ id, password }),
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (err.status === 401 && err.statusText === 'Unauthorized') {
              console.log('testLogin', err);
              this.logout();
            } else {
              reject(err.error.message || err);
            }
          }
        );
    });

    return promise;
  }

  // Return default HTTP header.
  public getHttpHeaders(): HttpHeaders {
    const token: string = this.getUserToken();

    let header: HttpHeaders;
    if (token) {
      header = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        locale: this.translate.currentLang || 'pt-BR',
      });
    } else {
      header = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        locale: this.translate.currentLang || 'pt-BR',
      });
    }

    // if (token) { header.set('Authorization', 'Bearer ' + token); }

    return header;
  }

  // Return HTTP header for uploads.
  public getUploadHttpHeaders(): HttpHeaders {
    const token: string = this.getUserToken();

    let header: HttpHeaders;
    if (token) {
      header = new HttpHeaders({
        Accept: 'application/json,',
        Authorization: 'Bearer ' + token,
        locale: this.translate.currentLang || 'pt-BR',
      });
      header.append(
        'Content-Type',
        'multipart/form-data; boundary=----WebKitFormBoundary6GSZ4er6gUbB8aQA'
      );
      header.append('Accept-Encoding', 'gzip, deflate, br');
      header.append('Connection', 'keep-alive');
    } else {
      header = new HttpHeaders({
        Accept: 'application/json',
        locale: this.translate.currentLang || 'pt-BR',
      });
    }

    // if (token) { header.set('Authorization', 'Bearer ' + token); }

    return header;
  }

  // Encryptation.
  public encrypt(data: string): string {
    return CryptoJS.AES.encrypt(data, environment.cryptoKey).toString();
  }

  // Decryptation.
  public decrypt(data: string): string {
    try {
      const val: any = CryptoJS.AES.decrypt(data, environment.cryptoKey);
      const parsed: any = val.toString(CryptoJS.enc.Utf8);
      return parsed || null;
    } catch {
      return null;
    }
  }

  // Check if user is authenticated.
  public isLoggedIn(): boolean {
    const u: IHumanLocalData = this.getLocalData();
    const isLoggedIn = u && u.email && u.id && u.token ? true : false;

    if (isLoggedIn) {
      dataLayer.push({
        event: 'login',
        userData: {
          id: u.id,
          email: u.email,
          role: u.isAdmin ? 'employee' : 'member',
        },
      });
    }
    return isLoggedIn;
  }

  // Finish auth process.
  private finishAuth(human: IHuman, humanData: IHumanLocalData): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if (environment.production && !human.isAdmin) {
        //Identify user LogRocket
        LogRocket.identify(human.id.toString(), {
          name: human.fullName,
          email: human.email,
        });

        // Identify user Datadog
        datadogRum.setUser({
          id: human.id.toString(),
          name: human.fullName,
          email: human.email,
        });
      }

      this.saveLocalData(humanData);
      this.headers = this.getHttpHeaders();

      this.getHumanLocalLocation(human.id)
        .then(res => {
          humanData.location = res || null;
          this.saveLocalData(humanData);
          resolve(humanData);
        })
        .catch(err => {
          this.saveLocalData(humanData);
          reject(err);
        })
        .finally(() => {
          localStorage.removeItem('token');
        });
    });

    return promise;
  }

  // Get human's location for local storage.
  private getHumanLocalLocation(uid: number): Promise<IHumanLocalLocation> {
    const promise = new Promise<IHumanLocalLocation>((resolve, reject) => {
      const api: string = environment.urls.api;

      this.http
        .get<IAPIResponse>(api + 'humans-addresses/human/' + uid, {
          headers: this.headers,
        })
        .subscribe(
          resHA => {
            if (resHA && resHA.count) {
              this.http
                .get<IAPIResponse>(
                  api + 'addresses/' + resHA.rows[0].addressId,
                  { headers: this.headers }
                )
                .subscribe(
                  resA => {
                    this.http
                      .get<IAPIResponse>(api + 'cities/' + resA.rows.cityId, {
                        headers: this.headers,
                      })
                      .subscribe(
                        resC => {
                          this.http
                            .get<IAPIResponse>(
                              api + 'states/' + resC.rows.stateId,
                              { headers: this.headers }
                            )
                            .subscribe(
                              resS => {
                                const hll: IHumanLocalLocation = {
                                  city: resC.rows.name,
                                  cityId: resC.rows.id,
                                  countryId: 1,
                                  state: resS.rows.code,
                                  stateId: resS.rows.id,
                                  stateName: resS.rows.name,
                                };
                                resolve(hll);
                              },
                              errS => {
                                reject(errS);
                              }
                            );
                        },
                        errC => {
                          reject(errC);
                        }
                      );
                  },
                  errA => {
                    reject(errA);
                  }
                );
            } else {
              resolve(null);
            }
          },
          errHA => {
            reject(errHA);
          }
        );
    });

    return promise;
  }

  // Save data into LocalStorage.
  private saveLocalData(data: IHumanLocalData): void {
    const localData: string = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      environment.cryptoKey
    ).toString();
    localStorage.setItem(environment.storageKeys.user, localData);

    if (!environment.production) {
      localStorage.setItem('token', data.token);
    }
  }

  // Update human.
  private updateHuman(human: IHuman): Promise<IHuman> {
    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(endpoint + human.id, JSON.stringify(human), {
          headers: this.headers,
        })
        .subscribe(
          result => {
            resolve(result.rows);
          },
          error => {
            if (error.status === 401 && error.statusText === 'Unauthorized') {
              console.log('updateHuman', error);
              this.logout();
            } else {
              resolve(error.error.message || error);
            }
          }
        );
    });

    return promise;
  }

  // Rerdirect to login page.
  private goToLogin(): void {
    window.location.href = 'login';
  }

  // Clear local stored data.
  private clearLocalData(): void {
    localStorage.removeItem(environment.storageKeys.user);
    localStorage.removeItem(environment.storageKeys.social);
    localStorage.removeItem('token');
  }
}
