// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App models.
import {} from '@app/core/models';

// App interfaces.
import { IAPIResponse, IEmailMessage } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';

// Main endpoint.
const endpoint: string = environment.urls.api + 'send-email/';

@Injectable({ providedIn: 'root' })
export class EmailService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient
  ) {}

  // Send e-mail message.
  public sendEmail(email: IEmailMessage): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(endpoint, JSON.stringify(email), {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res as IAPIResponse);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }
}
