// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import { IHuman, ISubscription } from '@app/core/models';

// App services.
import {
  ErrorService,
  SubscriptionService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-vh-subscriptions',
  templateUrl: './vh-subscriptions.component.html',
  styleUrls: ['./vh-subscriptions.component.scss'],
  animations: [pageAnimations],
})
export class VhSubscriptionsComponent implements OnInit {
  // Selected human's data.
  @Input() selHuman: IHuman;
  public subscriptions: ISubscription[] = [];

  // Status.
  public isLoading: boolean = true;

  // Constuctor method.
  constructor(
    private errorService: ErrorService,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get human's subscriptions.
    this.getSubscriptions();
  }

  // Get human's subscriptions.
  private getSubscriptions(): void {
    this.isLoading = true;

    this.subscriptionService
      .getSubscriptions(this.selHuman.id.toString())
      .then(res => {
        this.subscriptions = res.rows as ISubscription[];
      })
      .catch(err => {
        this.utilsService.showAlert(
          'vh-subscriptions',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getSubscriptions() > subscriptionService.getSubscriptions()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => (this.isLoading = false));
  }
}
