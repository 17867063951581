<!-- ----------------------------------------------------------------------------- -->

<!-- [GLOBAL CONTAINER: START] -->
<section
  *ngIf="!showFullLoader && showApp"
  id="container"
  class="container"
  [ngClass]="{ 'mob-mode': isMobile, locked: containerLocked }"
  (window:resize)="onResize($event)"
  [@fadeInOut]>
  <!-- Main sidebar (desktop only). -->
  <div *ngIf="!isMobile" class="main-sidebar" [@fadeInOut]>
    <app-main-sidebar></app-main-sidebar>
  </div>

  <!-- Mobile header. -->
  <div *ngIf="isMobile" class="mobile-header" [@fadeInOut]>
    <app-header-mobile></app-header-mobile>
  </div>

  <!-- Dynamic content. -->
  <div class="dynamic-content" [ngClass]="{ 'mob-mode': isMobile }">
    <router-outlet [@fadeInOut]></router-outlet>
  </div>

  <!-- Second sidebar (desktop only). -->
  <div *ngIf="!isMobile" class="second-sidebar" [@fadeInOut]>
    <app-second-sidebar></app-second-sidebar>
  </div>
</section>
<!-- [GLOBAL CONTAINER: END] -->

<!-- ----------------------------------------------------------------------------- -->

<!-- Login page. -->
<app-login
  *ngIf="showLogin"
  (hideFullLoader)="hideLoaderLater($event)"></app-login>

<!-- ----------------------------------------------------------------------------- -->

<!-- Not found page. -->
<app-not-found *ngIf="showNotFound"></app-not-found>

<!-- ----------------------------------------------------------------------------- -->

<!-- Partners. -->
<app-partners *ngIf="showPartners"></app-partners>

<!-- ----------------------------------------------------------------------------- -->

<!-- Register new user. -->
<app-register *ngIf="showRegisterPage"></app-register>

<!-- ----------------------------------------------------------------------------- -->

<!-- Remember password. -->
<app-remember-password *ngIf="showRememberPassPage"></app-remember-password>

<!-- ----------------------------------------------------------------------------- -->

<!-- Reset password. -->
<app-reset-password *ngIf="showResetPassPage"></app-reset-password>

<app-alerts></app-alerts>

<!-- ----------------------------------------------------------------------------- -->

<!-- Full page loader. -->
<div
  *ngIf="isBrowser && showFullLoader"
  id="full-loader"
  class="full-loader"
  [@slideUpDown]>
  <app-full-page-loader></app-full-page-loader>
</div>

<!-- ----------------------------------------------------------------------------- -->

<!-- Borders. -->
<!--<div class="b-hborder b-botton"></div>
<div class="b-hborder b-top"></div>
<div class="b-vborder b-left"></div>
<div class="b-vborder b-right"></div>-->
