<section class="modal-container" [@slideUpDown]>
  <div class="view-pet-modal">
    <!-- Close modal. -->
    <i class="fas fa-times" (click)="closeModal(false)"></i>

    <!-- Title. -->
    <div content="notranslate" class="modal-title notranslate">
      {{ isLoading ? 'Carregando...' : pet.name }}
    </div>

    <!-- [CONTENT: START] -->
    <div *ngIf="!isLoading" class="modal-content">
      <div class="pet-container pet-item">
        <!-- [SIDEBAR: START] -->
        <div class="pet-sidebar pet-item-container">
          <img
            src="{{ petService.getAvatar(pet) }}"
            class="pet-avatar"
            [ngClass]="petService.getPetStatusClass(pet.subscription)" />
          <p
            class="pet-status"
            [ngClass]="petService.getPetStatusClass(pet.subscription)">
            <i
              *ngIf="
                pet.subscription &&
                pet.subscription.id &&
                !pet.subscription.canceledAt &&
                !pet.subscription.blocked
              "
              class="far fa-grin-wink"></i>
            <!--<i *ngIf="!pet.subscriptionId" class="far fa-sad-tear"></i>-->
            {{ petService.getPetStatus(pet.subscription, true) }}
          </p>

          <p class="pet-remove">
            <button
              class="btn btn-sm btn-secondary btn-icon-label"
              type="button"
              style="margin-top: 20px"
              (click)="deletePet(pet)">
              <span class="btn-inner--icon">
                <i class="fas fa-trash-alt"></i>
              </span>
              <span *ngIf="!isSaving" class="btn-inner--text">{{
                'PETS.DELETE' | translate
              }}</span>
              <span *ngIf="isSaving" style="margin-left: 50px"
                ><i class="fas fa-spinner fa-spin"></i
              ></span>
            </button>
          </p>
        </div>
        <!-- [SIDEBAR: END] -->

        <!-- [DYNAMIC CONTENT: START] -->
        <div class="pet-content">
          <!-- Navigation. -->
          <ul class="nav nav-tabs">
            <li class="nav-item" (click)="changeTab('about')">
              <a class="nav-link" [ngClass]="{ active: activeTab === 'about' }">
                {{ 'PETS.TABS.ABOUT' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('treatments')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'treatments' }">
                {{ 'PETS.TABS.TREATMENTS' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('owners')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'owners' }">
                {{ 'PETS.TABS.OWNERS' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('subscription')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'subscription' }">
                {{ 'PETS.TABS.SUBSCRIPTION' | translate }}
              </a>
            </li>
          </ul>

          <!-- Resume. -->
          <app-vp-resume
            *ngIf="activeTab === 'about'"
            [pet]="pet"></app-vp-resume>

          <!-- Treatments. -->
          <app-vp-treatments
            *ngIf="activeTab === 'treatments'"
            [pet]="pet"></app-vp-treatments>

          <!-- Owners. -->
          <app-vp-owners
            *ngIf="activeTab === 'owners'"
            [pet]="pet"></app-vp-owners>

          <!-- Subscription. -->
          <app-vp-subscription
            *ngIf="activeTab === 'subscription'"
            [pet]="pet"
            (updatedSubs)="subscriptionUpdate($event)"></app-vp-subscription>
        </div>
        <!-- [DYNAMIC CONTENT: END] -->
      </div>
    </div>
    <!-- [CONTENT: END] -->
  </div>
</section>
