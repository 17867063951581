/* eslint-disable id-blacklist */
// Angular modules.
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import {
  EAlertType,
  EAuthType,
  EBroadcast,
  IHumanLogin,
} from '@app/core/models';

// App interfaces.
import {
  IAddressRegister,
  IAlert,
  IHumanRegister,
  ISocialLoginData,
} from '@app/core/models';

// App services.
import {
  BroadcastService,
  ErrorService,
  HumanService,
  LocationService,
  UtilsService,
  AuthService,
} from '@app/core/services';

// GTM.
// eslint-disable-next-line @typescript-eslint/ban-types
declare let dataLayer: any[];

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  animations: [slideBottomAnimation],
})
export class RegisterFormComponent implements OnChanges, OnInit {
  // Output.
  @Output() redirectToMain: EventEmitter<any> = new EventEmitter<any>();

  // Zip code.
  @Input() zipCode: string;

  // Social login info.
  public sld: ISocialLoginData;

  // Form.
  public newHuman: IHumanRegister;
  public newAddress: IAddressRegister;
  public passwordConfirm: string;
  public zipCodeFound: boolean = false;
  public phoneViaParam: string;
  public emailViaParam: string;
  public nameViaParam: string;

  // Contract, terms and policy.
  public chkContract: boolean = true;
  public chkPolicy: boolean = false;

  // Errors.
  public errAddrCity: string;
  public errAddrComplement: string;
  public errAddrNeighborhood: string;
  public errAddrNumber: string;
  public errAddrState: string;
  public errAddrStreet: string;
  public errAddrZipCode: string;
  public errEmail: string;
  public errName: string;
  public errPassword: string;
  public errPasswordConfirm: string;
  public errPasswordMatch: string;
  public errPhone: string;

  // Modals.
  public isAgreementVisible: boolean = false;
  public isTermsVisible: boolean = false;

  // Status.
  public isLoaded: boolean = false;
  public isLoadingPostalCode: boolean = false;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private humanService: HumanService,
    private locationService: LocationService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.zipCode = params.get('zipCode');
    });

    this.activatedRoute.queryParamMap.subscribe(params => {
      // Portal Code
      this.zipCode = params.get('postalCode');
      if (this.zipCode) {
        this.setZipCodeHasValue();
      }

      // Email
      this.emailViaParam = params.get('email');
      if (this.emailViaParam) {
        this.setEmailHasValue();
      }

      // Full Name
      this.nameViaParam = params.get('name');
      if (this.nameViaParam) {
        this.setFullNameHasValue();
      }

      // Phone
      this.phoneViaParam = params.get('phone');
      if (this.phoneViaParam) {
        this.setPhoneHasValue();
      }
    });
  }

  public ngOnChanges(): void {
    if (
      this.zipCode &&
      this.newAddress &&
      this.newAddress.zipCode !== this.zipCode
    ) {
      this.newAddress.zipCode = this.zipCode;
      this.getAddress(this.zipCode, true);
    }
  }

  // On init.
  public ngOnInit(): void {
    dataLayer.push({
      event: 'generate_lead',
    });

    const invCode: string = localStorage.getItem(
      environment.storageKeys.invitationCode
    );
    if (invCode) {
      // Build social login data object.
      const socLogin: string = localStorage.getItem(
        environment.storageKeys.social
      );
      if (socLogin) {
        this.sld = JSON.parse(socLogin) as ISocialLoginData;
      }
    } else {
      // this.router.navigate(['register/start']);
    }

    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/register'
      );
      this.isLoaded = true;
      setTimeout(() => {
        // if (this.sld) { this.toggleSocialInfoModal(true); }
        this.buildNewFormData();
        if (this.zipCode) {
          this.getAddress(this.zipCode, true);
        }
      }, 500);
    }, 500);

    window.scroll(0, 0);
  }

  // Create new human.
  public createHuman(): void {
    if (this.validateForm(true)) {
      if (this.chkContract && this.chkPolicy) {
        this.isSaving = true;

        // Set authentication type.
        const logType: EAuthType =
          this.sld && this.sld.type ? this.sld.type : EAuthType.Email;

        // Get invitation code, if exists.
        const invitationCode: string = localStorage.getItem(
          environment.storageKeys.invitationCode
        );

        this.humanService
          .getCouponOwner(invitationCode)
          .then(result => {
            if (
              (result && result.rows && result.rows.id) ||
              invitationCode === environment.petbeeInvitationCode
            ) {
              this.newHuman.invitedBy = invitationCode;
            }

            // Save new human.
            this.humanService
              .createNewHuman(logType, this.newHuman, this.newAddress)
              .then(res => {
                dataLayer.push({
                  event: 'sign_up',
                  method: res.rows.authMethod,
                  userData: {
                    id: res.rows.id,
                    email: res.rows.email,
                    role: this.utilsService.getUserRole(res.rows.isAdmin),
                  },
                });

                this.utilsService.showAlert(
                  'alert-reg-frm',
                  this.translate.instant('REGISTER.MESSAGES.ACCOUNT_CREATED'),
                  EAlertType.Success
                );
                if (invitationCode) {
                  localStorage.removeItem(
                    environment.storageKeys.invitationCode
                  );
                }
                setTimeout(() => {
                  const loginData: IHumanLogin = {
                    email: this.newHuman.email,
                    password: this.newHuman.password,
                  };

                  this.authService
                    .login(loginData)
                    .then(res => {
                      this.authService.getLocalData();

                      this.broadcastService.sendBroadcast(
                        EBroadcast.FullLoaderOn
                      );
                      setTimeout(() => {
                        const nextPath: string =
                          localStorage.getItem('last-path') || '';
                        localStorage.removeItem('last-path');
                        this.router.navigate([nextPath]);
                      }, 500);
                    })
                    .catch(err => {
                      this.router.navigate(['/login']);
                    })
                    .finally(() => {
                      this.router.navigate(['/']);
                    });
                }, 1000);
              })
              .catch(err => {
                this.utilsService.showAlert(
                  'alert-reg-frm',
                  this.errorService.manageError(
                    err,
                    this.constructor.name,
                    'createHuman() > humanService.createNewHuman()'
                  ),
                  EAlertType.Error
                );
              })
              .finally(() => {
                this.isSaving = false;
              });
          })
          .catch(error => {
            this.utilsService.showAlert(
              'alert-reg-frm',
              this.errorService.manageError(
                error,
                this.constructor.name,
                'createHuman() > humanService.getCouponOwner()'
              ),
              EAlertType.Error
            );
            this.isSaving = false;
          });
      } else {
        this.utilsService.showAlert(
          'alert-reg-frm',
          'Você deve aceitar o contrato e a política de privacidade.',
          EAlertType.Error
        );
      }
    }
  }

  // When user is typing on postal code field.
  public typingPostalCode(event: any): void {
    const val: string = this.newAddress.zipCode;
    if (val && val.length >= 8) {
      this.getAddress(val);
    }
  }

  // Get address based on the given CEP.
  public getAddress(cep: string, avoidFocus: boolean = false): void {
    this.isLoadingPostalCode = true;

    this.locationService.searchByZipCode(cep).subscribe(
      (data: IAddressRegister) => {
        if (data.city) {
          this.newAddress = data;
          this.newAddress.complement = null;
        } else {
          this.errAddrZipCode = 'CEP inválido';
        }
        this.zipCodeFound = true;

        setTimeout(() => {
          this.isLoadingPostalCode = false;
          if (this.newAddress.zipCode) {
            document
              .getElementById('txt-zipcode')
              .classList.add('el-has-value');
          }
          if (this.zipCodeFound && this.newAddress.city && !avoidFocus) {
            document.getElementById('txt-addr-number') &&
              document.getElementById('txt-addr-number').focus();
          }
        }, 300);
      },
      (error: any) => {
        this.errAddrZipCode = 'CEP inválido';
        this.isLoadingPostalCode = false;
        this.zipCodeFound = true;
        if (this.newAddress.zipCode) {
          document.getElementById('txt-zipcode').classList.add('el-has-value');
        }
      }
    );
  }

  // Check/uncheck contract field.
  public toggleContract(): void {
    this.chkContract = !this.chkContract;
  }

  // Check/uncheck policy field.
  public togglePolicy(): void {
    this.chkPolicy = !this.chkPolicy;
  }

  // Show/hide agreements modal.
  public toggleAgreementsWindow(event?: any): void {
    this.isAgreementVisible = !this.isAgreementVisible;
  }

  // Show/hide terms of use modal.
  public toggleTermsWindow(event?: any): void {
    this.isTermsVisible = !this.isTermsVisible;
  }

  // Show/hide social info modal.
  public toggleSocialInfoModal(open: boolean = true): void {
    const modal: HTMLElement = document.getElementById('modal-socialLogin');

    if (open) {
      modal.classList.remove('hide');
      modal.classList.add('show');
    } else {
      modal.classList.remove('show');
      modal.classList.add('hide');
      const focus: string = !this.sld.email
        ? 'txt-email'
        : !this.sld.name
        ? 'txt-name'
        : 'txt-phone';
      document.getElementById(focus) && document.getElementById(focus).focus();
    }
  }

  // Return to main page.
  public returnToMainPage(): void {
    this.redirectToMain.emit();
  }

  // Redirect to home page.
  public goToHome(): void {
    // this.router.navigate([environment.urls.site]);
    window.location.href = environment.urls.site;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }

  // Validate form.
  public validateForm(force: boolean = false): boolean {
    let isValid: boolean = true;
    const nh: IHumanRegister = this.newHuman;
    const na: IAddressRegister = this.newAddress;

    // Clear all errors.
    this.clearFormErrors();

    // Validate human's data.
    if (!nh.email && (force || this.hasTouched('txt-email'))) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_REQUIRED');
      isValid = false;
    } else if (
      nh.email &&
      !this.errEmail &&
      !this.utilsService.validateEmail(nh.email)
    ) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_INVALID');
      isValid = false;
    }
    if (!nh.fullName && (force || this.hasTouched('txt-name'))) {
      this.errName = this.translate.instant('REGISTER.ERRORS.NAME_REQUIRED');
      isValid = false;
    }
    if (!nh.password && (force || this.hasTouched('txt-password'))) {
      this.errPassword = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_REQUIRED'
      );
      isValid = false;
    }
    if (
      !nh.passwordConfirm &&
      (force || this.hasTouched('txt-password-confirm'))
    ) {
      this.errPasswordConfirm = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_CONFIRM_REQUIRED'
      );
      isValid = false;
    }
    if (
      nh.password &&
      nh.passwordConfirm &&
      nh.password !== nh.passwordConfirm
    ) {
      this.errPasswordMatch = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_DOES_NOT_MATCH'
      );
      isValid = false;
    }
    if (!nh.phone && (force || this.hasTouched('txt-phone'))) {
      this.errPhone = this.translate.instant('REGISTER.ERRORS.PHONE_REQUIRED');
      isValid = false;
    }

    // Validate address data.
    if (!na.city && (force || this.hasTouched('txt-addr-city'))) {
      this.errAddrCity = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_CITY_REQUIRED'
      );
      isValid = false;
    }
    if (
      !na.neighborhood &&
      (force || this.hasTouched('txt-addr-neighborhood'))
    ) {
      this.errAddrNeighborhood = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_NEIGHBORHOOD_REQUIRED'
      );
      isValid = false;
    }
    if (!na.state && (force || this.hasTouched('txt-addr-state'))) {
      this.errAddrState = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_STATE_REQUIRED'
      );
      isValid = false;
    }
    if (!na.street && (force || this.hasTouched('txt-addr-street'))) {
      this.errAddrStreet = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_STREET_REQUIRED'
      );
      isValid = false;
    }
    if (
      !this.errAddrStreet &&
      !na.number &&
      (force || this.hasTouched('txt-addr-number'))
    ) {
      this.errAddrStreet = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_NUMBER_REQUIRED'
      );
      isValid = false;
    }
    if (!na.zipCode && (force || this.hasTouched('txt-zipcode'))) {
      this.errAddrZipCode = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_ZIPCODE_REQUIRED'
      );
      isValid = false;
    }

    return isValid;
  }

  private getLastPartOfCookie(cookieName) {
    const cookieArray = document.cookie.split(';');
    const targetCookie = cookieArray.find(cookie =>
      cookie.includes(`${cookieName}=`)
    );

    if (!targetCookie) {
      return null; // Return null if the specified cookie is not found
    }

    const parts = targetCookie.split('.');
    return parts[parts.length - 1];
  }

  // Check if an element was touched.
  private hasTouched(elementId: string): boolean {
    let touched: boolean = false;

    const el: HTMLElement = document.getElementById(elementId);
    if (el && !el.classList.contains('ng-pristine')) {
      touched = true;
    }

    return touched;
  }

  // Clear all errors.
  private clearFormErrors(): void {
    this.errAddrCity = null;
    this.errAddrComplement = null;
    this.errAddrNeighborhood = null;
    this.errAddrNumber = null;
    this.errAddrState = null;
    this.errAddrStreet = null;
    this.errAddrZipCode = null;
    this.errEmail = null;
    this.errName = null;
    this.errPassword = null;
    this.errPasswordConfirm = null;
    this.errPasswordMatch = null;
    this.errPhone = null;
  }

  // Build newHuman and newAddress object.
  private buildNewFormData(): void {
    let authMethod: EAuthType = EAuthType.Email;
    let idFacebook: string;
    let idGoogle: string;
    if (this.sld) {
      authMethod = this.sld.type;
      idFacebook = authMethod === EAuthType.Facebook ? this.sld.id : null;
      idGoogle = authMethod === EAuthType.Google ? this.sld.id : null;
    }

    this.newHuman = {
      createdAt: this.utilsService.getFormatedDBDate(new Date()),
      email: this.sld ? this.sld.email : this.emailViaParam || null,
      fullName: this.sld ? this.sld.name : this.nameViaParam || null,
      password: null,
      phone: this.phoneViaParam || null,
    };

    this.newAddress = {
      city: null,
      country: 'BR',
      neighborhood: null,
      state: null,
      street: null,
      complement: null,
      number: null,
      zipCode: this.zipCode || null,
    };

    setTimeout(() => {
      if (this.sld) {
        if (this.sld.email) {
          document.getElementById('txt-email').classList.add('el-has-value');
        }
        if (this.sld.name) {
          document.getElementById('txt-name').classList.add('el-has-value');
        }
      } else {
        document.getElementById('txt-name') &&
          document.getElementById('txt-name').focus();
      }
      // const focus: string = !this.sld.email ? 'txt-email' : (!this.sld.name ? 'txt-name' : 'txt-phone');
      // document.getElementById(focus) && document.getElementById(focus).focus();
    }, 300);
  }

  // Set e-mail field dirty.
  private setEmailHasValue(): void {
    const txtEmail: HTMLElement = document.getElementById('txt-email');
    if (txtEmail) {
      txtEmail.classList.add('el-has-value');
    } else {
      setTimeout(() => {
        this.setEmailHasValue();
      }, 500);
    }
  }

  // Set name field dirty.
  private setFullNameHasValue(): void {
    const txtName: HTMLElement = document.getElementById('txt-name');
    if (txtName) {
      txtName.classList.add('el-has-value');
    } else {
      setTimeout(() => {
        this.setFullNameHasValue();
      }, 500);
    }
  }

  // Set phone field dirty.
  private setPhoneHasValue(): void {
    const txtPhone: HTMLElement = document.getElementById('txt-phone');
    if (txtPhone) {
      txtPhone.classList.add('el-has-value');
    } else {
      setTimeout(() => {
        this.setPhoneHasValue();
      }, 500);
    }
  }

  // Set zip code field dirty.
  private setZipCodeHasValue(): void {
    const txtPhone: HTMLElement = document.getElementById('txt-zipcode');
    if (txtPhone) {
      txtPhone.classList.add('el-has-value');
    } else {
      setTimeout(() => {
        this.setPhoneHasValue();
      }, 500);
    }
  }
}
