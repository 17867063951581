// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast, EAlertType } from '@app/core/models';

// App interfaces.
import { IAddressRegister, IAlert } from '@app/core/models';

// App services.
import {
  BroadcastService,
  ErrorService,
  LocationService,
} from '@app/core/services';

@Component({
  selector: 'app-register-availability',
  templateUrl: './register-availability.component.html',
  styleUrls: ['./register-availability.component.scss'],
  animations: [slideBottomAnimation],
})
export class RegisterAvailabilityComponent implements OnInit {
  // Outputs.
  @Output() redirectToNotification: EventEmitter<any> = new EventEmitter<any>();
  @Output() redirectToRegister: EventEmitter<any> = new EventEmitter<any>();

  // Location.
  public zipCode: string;
  public city: string;
  public state: string;
  public errZipCode: string;

  // User's e-mail notification.
  public userEmail: string;
  public userName: string;
  public userPhone: string;
  public errUserEmail: string;
  public errUserName: string;
  public errUserPhone: string;

  // Status.
  public alertSaved: boolean = false;
  public isLoading: boolean = false;
  public isLoaded: boolean = false;
  public isLoadingPostalCode: boolean = false;
  public isSaving: boolean = false;
  public zipCodeFound: boolean = false;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private locationService: LocationService,
    private router: Router
  ) {}

  // On init.
  public ngOnInit(): void {
    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/register'
      );
      this.isLoaded = true;
      setTimeout(() => {
        document.getElementById('txt-zipcode') &&
          document.getElementById('txt-zipcode').focus();
      }, 500);
    }, 500);

    window.scroll(0, 0);
  }

  public showAlert(): void {
    const alert: IAlert = {
      message: 'Testando envio de mensagens.',
      type: EAlertType.Error,
      avoidAutoClose: false,
      id: 'alert-reg-av',
      secondsToClose: 8,
    };
    this.broadcastService.sendBroadcast(EBroadcast.Alert, alert);
  }

  // When user is typing on postal code field.
  public typingPostalCode(event: any): void {
    const charCode: any = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.zipCode = this.zipCode.length[-1];
    } else {
      const val: string = this.zipCode;
      if (val.length === 5) {
        this.zipCode += '-';
      } else if (val && val.length >= 8) {
        this.getAddress(val);
      }
    }
  }

  // Get address based on the given CEP.
  public getAddress(cep: string): void {
    this.isLoadingPostalCode = true;
    this.errZipCode = null;
    this.zipCodeFound = false;
    this.city = null;
    this.state = null;

    this.locationService
      .searchByZipCode(cep)
      .subscribe((data: IAddressRegister) => {
        if (data.city && data.state) {
          this.city = data.city;
          this.state = data.state;
          this.errZipCode = null;
          this.zipCodeFound = true;
        } else {
          this.errZipCode = 'CEP não localizado';
          this.zipCodeFound = false;
        }

        this.isLoadingPostalCode = false;
      });
  }

  // Redirect to register page.
  public goToRegister(): void {
    this.isLoaded = false;

    setTimeout(() => {
      this.router.navigate(['/register/form', this.zipCode]);
    }, 300);
  }

  // Redirect to register alert page.
  public registerToReceiveAlert(): void {
    // this.redirectToNotification.emit();
    this.isLoaded = false;

    setTimeout(() => {
      this.router.navigate(['/register/notification', this.zipCode]);
    }, 300);
  }

  // Redirect to home page.
  public goToHome(): void {
    // this.router.navigate([environment.urls.site]);
    window.location.href = environment.urls.site;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }
}
