// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App models.
import { IClinic, IClinicPet, IHuman, IHumanLocalData, IPet } from '@app/core';

// App services.
import {
  AuthService,
  ClinicsService,
  ErrorService,
  HumanService,
  PetService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-clinic-pet-register',
  templateUrl: './clinic-pet-register.component.html',
  styleUrls: ['./clinic-pet-register.component.scss'],
  animations: [modalAnimation],
})
export class ClinicPetRegisterComponent implements OnInit {
  // Outputs.
  @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatedList: EventEmitter<any> = new EventEmitter<any>();

  // Inputs.
  @Input() clinic: IClinic;

  // Human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Pet Plan & Grace Period
  public gracePeriodDaysLeft = 60;
  public gracePeriodStatus = true;
  public gracePeriodType = null;
  public petStatusClass = 'blocked';

  // Clinic's data.
  public clinicPets: IClinicPet[] = [];

  // Pet's data.
  public pets: IPet[] = [];
  public owner: IHuman;
  public petAvatar: string;

  // Errors.
  public errChip: string;
  public errOwner: string;

  // Search.
  public searchType: string = '1';
  public chipCode: string;
  public ownerValue: string;

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public isSearching: boolean = false;
  public searchDone: boolean = false;
  public totalSelected: number = 0;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private clinicsService: ClinicsService,
    private errorService: ErrorService,
    public humanService: HumanService,
    public petService: PetService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get clinic pets.
    this.getClinicPets();

    // Set modal open on container
    const container = document.getElementById('container');
    container.classList.add('modal-open');

    setTimeout(() => {
      this.setDefultFocus();
    }, 600);
  }

  public disableRegisterButton(): boolean {
    if (this.human.isAdmin) return false;

    return (
      this.isSearching ||
      this.isSaving ||
      this.totalSelected === 0 ||
      (this.gracePeriodStatus && this.gracePeriodType === 'full')
    );
  }

  public getPetStatusClass(pet: IPet): void {
    const subscription = pet.subscription;

    const subscriptionStatus =
      this.petService.getSubscriptionStatus(subscription);

    if (!subscriptionStatus) {
      this.petStatusClass = 'blocked';
      return;
    }

    const subscriptionGracePeriod =
      this.petService.getPetGracePeriod(subscription);

    if (subscriptionGracePeriod) {
      this.gracePeriodDaysLeft = subscriptionGracePeriod.daysLeft;
      this.gracePeriodType = subscriptionGracePeriod.gracePeriodType;
      this.gracePeriodStatus = subscriptionGracePeriod.gracePeriodStatus;
      this.petStatusClass = subscriptionGracePeriod.gracePeriodClass;
    }
  }

  // Search human.
  public searchHumanPets(): void {
    this.clearErrors();

    if (this.ownerValue) {
      this.searchDone = false;
      this.pets = [];
      this.owner = null;

      if (this.ownerValue.includes('@')) {
        this.searchByEmail();
      } else {
        this.searchByDocument();
      }
    } else {
      this.errOwner = 'Informe o CPF ou o e-mail do proprietário do pet.';
    }
  }

  // Search pet by identification number.
  public searchByCode(): void {
    this.clearErrors();

    if (this.chipCode) {
      this.isSearching = true;
      this.searchDone = false;
      this.pets = [];
      this.owner = null;

      this.petService
        .getByChip(this.chipCode)
        .then(resPet => {
          if (resPet && resPet.count > 0) {
            this.pets = resPet.rows as IPet[];
            this.petAvatar = this.petService.getAvatar(this.pets[0]);
            this.getPetStatusClass(this.pets[0]);
            this.totalSelected = 1;
            this.searchDone = true;
          } else {
            this.errChip = 'Nenhum pet localizado';
            this.totalSelected = 0;
          }
        })
        .catch(errPet => {
          this.totalSelected = 0;
          this.errChip = this.errorService.manageError(
            errPet,
            this.constructor.name,
            'searchByCode() > petService.getByChip()'
          );
          this.setDefultFocus();
        })
        .finally(() => {
          this.isSearching = false;
        });
    } else {
      this.errChip = 'Informe o número de identificação do pet';
    }
  }

  // Check if pet is already registered.
  public checkIfNewPet(p: IPet): boolean {
    return this.clinicPets.filter(cp => cp.petId === p.id).length > 0;
  }

  // Check if a pet is protected.
  public isProtected(p: IPet): boolean {
    return this.petService.getSubscriptionStatus(p.subscription);
  }

  // Select a pet to continue.
  public selectPet(event: any, p: IPet): void {
    if (!this.checkIfNewPet(p) && this.isProtected(p)) {
      const el: HTMLInputElement = document.getElementById(
        'chk-' + p.id
      ) as HTMLInputElement;

      if (event.target) {
        if (event.target.type !== 'checkbox') {
          el.checked = !el.checked;
        }
        if (el.checked) {
          this.totalSelected++;
        } else {
          this.totalSelected--;
        }
      }
    }
  }

  // Register selected pet(s).
  public registerPets(): void {
    const petsList: number[] = [];

    petsList.push(this.pets[0].id);

    // this.pets.map(p => {
    //   const el: HTMLInputElement = document.getElementById(
    //     'chk-' + p.id
    //   ) as HTMLInputElement;
    //   if (el && el.checked) {
    //     petsList.push(p.id);
    //   }
    // });

    if (petsList.length > 0) {
      if (confirm('Confirma o registro do(s) pet(s) buscado(s)?')) {
        this.isSaving = true;
        this.savePet(petsList);
      }
    } else {
      this.utilsService.showAlert(
        'clinic-pet-reg',
        'Nenhum pet foi encontrado.',
        EAlertType.Error
      );
    }
  }

  // Change search type.
  public changeSearchType(val: string): void {
    this.searchType = val;
    this.errChip = null;
    this.errOwner = null;
    setTimeout(() => {
      this.setDefultFocus();
    }, 300);
  }

  // Close modal.
  public closeModal(reload: boolean = false): void {
    // Remove modal open on container
    const container = document.getElementById('container');
    container.classList.remove('modal-open');

    this.finished.emit(reload);
  }

  // Register each pet.
  private savePet(petsList: number[]): void {
    this.clinicsService
      .associatePet(this.clinic.id, petsList[0])
      .then(res => {
        petsList.shift();
        if (petsList.length > 0) {
          this.savePet(petsList);
        } else {
          this.isSaving = false;
          setTimeout(() => {
            this.getClinicPets(true).then(r => {
              if (this.searchType === '0') {
                this.searchHumanPets();
              } else {
                this.searchByCode();
              }
              this.updatedList.emit();
            });
          }, 300);
        }
      })
      .catch(err => {
        this.utilsService.showAlert(
          'clinic-pet-reg',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'savePet() > clinicsService.associatePet()'
          ),
          EAlertType.Error
        );
        console.error(err);
      });
  }

  // Get clinic pets.
  private getClinicPets(resolveIfError: boolean = false): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      this.isLoading = true;

      this.clinicsService
        .getPets(this.clinic.id)
        .then(res => {
          this.clinicPets = res.rows as IClinicPet[];
          resolve(this.clinicPets);
        })
        .catch(err => {
          console.error(err);
          if (resolveIfError) {
            resolve(null);
          } else {
            reject(err);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    });

    return promise;
  }

  // Search human pets by e-mail.
  private searchByEmail(): void {
    if (this.utilsService.validateEmail(this.ownerValue)) {
      this.isSearching = true;

      this.humanService
        .getWithEmail(this.ownerValue)
        .then(res => {
          if (res) {
            this.owner = res as IHuman;

            this.petService
              .getMyPets(this.owner.id)
              .then(resPets => {
                this.pets = resPets.rows as IPet[];
                this.petAvatar = this.petService.getAvatar(this.pets[0]);
                this.getPetStatusClass(this.pets[0]);
                this.totalSelected = 1;
                this.searchDone = true;
              })
              .catch(errPets => {
                this.totalSelected = 0;
                this.errOwner = this.errorService.manageError(
                  errPets,
                  this.constructor.name,
                  'searchByEmail() > petService.getMyPets()'
                );
                this.setDefultFocus();
              })
              .finally(() => {
                this.isSearching = false;
              });
          } else {
            this.errOwner = 'Proprietário não localizado';
            this.totalSelected = 0;
            this.isSearching = false;
            this.setDefultFocus();
          }
        })
        .catch(err => {
          this.errOwner = this.errorService.manageError(
            err,
            this.constructor.name,
            'searchByEmail() > humanService.getWithEmail()'
          );
          this.isSearching = false;
          this.setDefultFocus();
        });
    } else {
      this.errOwner = 'E-mail inválido.';
    }
  }

  // Search human pets by document.
  private searchByDocument(): void {
    this.utilsService.showAlert(
      'clin-pet-reg',
      this.errorService.manageError(
        'Busca por documento ainda não implementada',
        this.constructor.name,
        'searchByDocument()'
      ),
      EAlertType.Error
    );
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errChip = null;
    this.errOwner = null;
  }

  // Set default focus.
  private setDefultFocus(): void {
    const fieldId: string = this.searchType === '0' ? 'pet-owner' : 'chip-code';
    const el = document.getElementById(fieldId);

    if (el) {
      el.focus();
    }
  }
}
