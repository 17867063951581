// Angular modules.
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Third-party.
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Module routing.
import { PartnersRoutingModule } from '@app/partners/partners-routing.module';

// Module components.
import { PartnersComponent } from '@app/partners/partners.component';

// App modules.
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';

// @ngx-translate: AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http);

// NgxMask related.
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    // App components.
    PartnersComponent,
  ],
  exports: [
    // App routing.
    PartnersRoutingModule,

    // App components.
    PartnersComponent,
  ],
  imports: [
    // Angular modules.
    CommonModule,
    FormsModule,

    // Third-party.
    NgxMaskModule.forChild(options),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Routing modules.
    PartnersRoutingModule,

    // App modules
    CoreModule,
    SharedModule,
  ],
})
export class PartnersModule {}
