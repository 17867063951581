// Interface for log types.
export interface ILogType {
  title: string;
  type: ELogTypes;
}

// Enumerator for log types.
export enum ELogTypes {
  Payment = 'Payment',
}
