<section class="header-mobile-container">
  <!-- [MAIN MENU: START] -->
  <div class="hm-menu">
    <i class="fas fa-bars" (click)="toggleMenu()"></i>
  </div>
  <!-- [MAIN MENU: END] -->

  <!-- Logo. -->
  <div class="hm-logo">
    <img
      class="img-logo-mob"
      src="assets/images/petbee.png"
      (click)="goToPage('/')" />
  </div>

  <!-- [SECONDARY MENU: START] -->
  <div class="hm-sec-menu">
    <i class="fas fa-user-circle" (click)="toggleSecMenu()"></i>
  </div>
  <!-- [SECONDARY MENU: END] -->
</section>

<!-- Main menu. -->
<app-menu-mobile *ngIf="showMenu" (close)="toggleMenu(false)"></app-menu-mobile>

<!-- Secondary menu. -->
<app-sec-menu-mobile
  *ngIf="showSecMenu"
  (close)="toggleSecMenu(false)"></app-sec-menu-mobile>
