<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <!-- Count. -->
  <div class="pets-count">
    <p *ngIf="pets.length === 0">O usuário não possui pets cadastrados.</p>
    <p *ngIf="pets.length === 1">1 pet localizado.</p>
    <p *ngIf="pets.length > 1">{{ pets.length }} pets localizados.</p>
  </div>

  <!-- Pets list. -->
  <div
    *ngIf="pets && pets.length > 0"
    class="pets-list pl-{{ pets.length }}"
    [ngClass]="{ 'mob-mode': isMobile }">
    <div *ngFor="let pet of pets; let i = index" class="pet-item">
      <div class="pet-item-container">
        <img
          src="{{ petService.getAvatar(pet) }}"
          class="pet-avatar"
          [ngClass]="petService.getPetStatusClass(pet.subscription)"
          (click)="goToPet(pet)" />
        <p
          content="notranslate"
          class="pet-name notranslate"
          (click)="goToPet(pet)">
          {{ pet.name }}
        </p>
        <p
          class="pet-status"
          [ngClass]="petService.getPetStatusClass(pet.subscription)"
          (click)="goToPlans(pet)">
          <i
            *ngIf="
              pet.subscription &&
              pet.subscription.id &&
              !pet.subscription.canceledAt &&
              !pet.subscription.blocked
            "
            class="far fa-grin-wink"></i>
          <!--<i *ngIf="!pet.subscriptionId" class="far fa-sad-tear"></i>-->
          {{ petService.getPetStatus(pet.subscription, true) }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Loader. -->
<div *ngIf="isLoading" style="margin: 30px 0">
  <app-horizontal-loader></app-horizontal-loader>
</div>
