// Angular modules.
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({ selector: '[appInputs]' })
export class InputsDirective implements OnChanges {
  @Input() public input: string;

  // Constructor method.
  constructor(private el: ElementRef) {}

  // Input field events.
  @HostListener('input') onChanges() {
    this.toggleHasValueClass();
  }

  @HostListener('ngModelChange', ['$event']) onModelChange(value: any) {
    this.toggleHasValueClass();
  }

  @HostListener('change') ngOnChanges() {
    setTimeout(() => {
      this.toggleHasValueClass();
    }, 300);
  }

  // Toggle has-value class.
  private toggleHasValueClass(): void {
    const token: string = 'el-has-value';

    if (this.el.nativeElement.value) {
      if (!this.el.nativeElement.classList.contains(token)) {
        this.el.nativeElement.classList.add(token);
      }
    } else {
      this.el.nativeElement.classList.remove(token);
    }
  }
}
