// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import {
  IAPIResponse,
  ICieloSubscription,
  ICreditCardUpdate,
  IHumanLocalData,
  ICieloTransaction,
  ECreditCardBrand,
} from '@app/core';

// Services.
import { AuthService } from '@app/core/services';

// Endpoints.
const endpoint: string = environment.urls.api;
const epCielo: string = endpoint + 'cielo/';
const epToken: string = epCielo + 'card-token/';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Check credit card bin.
  public checkBin(bin: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epCielo + 'card-bin/' + bin, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new recurrent payment.
  public createRecurrentPayment(
    recPay: ICieloSubscription
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epCielo, JSON.stringify(recPay), {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  public createSimplePayment(
    pay: ICieloTransaction,
    paymentWithToken: boolean
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epCielo + 'payment', JSON.stringify(pay), {
          headers: this.headers,
          params: {
            type: paymentWithToken ? 'cardToken' : 'card',
          },
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Capture payment.
  public capturePayment(recPay: any): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const data: Record<string, unknown> = {
        paymentId: recPay.Payment.PaymentId,
        amount: recPay.Payment.Amount,
      };

      this.http
        .post<IAPIResponse>(epCielo + 'capture', JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Capture payment.
  public cancelPayment(payment: any): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const data: Record<string, unknown> = {
        paymentId: payment.PaymentId,
        amount: payment.Amount,
      };

      this.http
        .post<IAPIResponse>(epCielo + 'cancel', JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get recurrent payment info.
  public getRecurrentPayment(id: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epCielo + 'recurrent/' + id, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Deactiveate recurrent payment.
  public deactivateRecurrentPayment(id: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const data: Record<string, unknown> = { RecurrentPaymentId: id };
      this.http
        .post<IAPIResponse>(epCielo + 'deactivate/', JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Reactiveate recurrent payment.
  public reactivateRecurrentPayment(id: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const data: Record<string, unknown> = { RecurrentPaymentId: id };
      this.http
        .post<IAPIResponse>(epCielo + 'reactivate/', JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new card token.
  public createCardToken(
    human: IHumanLocalData,
    recPay: ICieloSubscription
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      const b: any = recPay.CreditCard;
      const data: Record<string, unknown> = {
        CustomerName: human.fullName,
        CardNumber: b.CardNumber,
        Holder: b.Holder,
        ExpirationDate: b.ExpirationDate,
        Brand: b.Brand,
      };

      this.http
        .post<IAPIResponse>(epToken, JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new card token (alternative method).
  public createCardTokenAlt(data: any): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epToken, JSON.stringify(data), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update credit card.
  public changeCreditCard(upData: ICreditCardUpdate): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epCielo + 'change-card/', JSON.stringify(upData), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Return card's flag.
  public getCardFlag(cardnumber: string): string {
    cardnumber = cardnumber.replace(/[^0-9]+/g, '');

    let flag: string;
    const cards: Record<string, any> = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})/,
      MasterCard: /^5[1-5][0-9]{14}/,
      Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      Amex: /^3[47][0-9]{13}/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      Aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (const f in cards) {
      if (cards[f].test(cardnumber)) {
        flag = f;
      }
    }

    return flag;
  }

  // Return card's flag according to Cielo's rules.
  public getCieloCardFlag(flag: string): ECreditCardBrand {
    let f: ECreditCardBrand;

    switch (flag && flag.toLowerCase()) {
      case 'amex':
        f = ECreditCardBrand.Amex;
        break;
      case 'aura':
        f = ECreditCardBrand.Aura;
        break;
      case 'diners':
        f = ECreditCardBrand.Diners;
        break;
      case 'discover':
        f = ECreditCardBrand.Discover;
        break;
      case 'elo':
        f = ECreditCardBrand.Elo;
        break;
      case 'jcb':
        f = ECreditCardBrand.JCB;
        break;
      case 'mastercard':
        f = ECreditCardBrand.Master;
        break;
      case 'master':
        f = ECreditCardBrand.Master;
        break;
      case 'visa':
        f = ECreditCardBrand.Visa;
        break;
    }

    return f;
  }

  // Get Cielo transaction status.
  public getCieloTransactionStatus(status: number): string {
    let st: string;

    switch (status) {
      case 0:
        st = this.translate.instant('CIELO.STATUS.WAITING');
        break;
      case 1:
        st = this.translate.instant('CIELO.STATUS.AUTHORIZED');
        break;
      case 2:
        st = this.translate.instant('CIELO.STATUS.CONFIRMED');
        break;
      case 3:
        st = this.translate.instant('CIELO.STATUS.DENIED');
        break;
      case 10:
        st = this.translate.instant('CIELO.STATUS.VOIDED');
        break;
      case 11:
        st = this.translate.instant('CIELO.STATUS.REFUNDED');
        break;
      case 12:
        st = this.translate.instant('CIELO.STATUS.PENDING');
        break;
      case 13:
        st = this.translate.instant('CIELO.STATUS.ABORTED');
        break;
      case 20:
        st = this.translate.instant('CIELO.STATUS.SCHEDULED');
        break;
    }

    return st;
  }
}
