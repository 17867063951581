// Interface for subscription's model.
export interface ISubscription {
  amount?: string;
  authCode?: string;
  blocked?: boolean;
  coupon?: string;
  currency?: string;
  createdAt: string;
  deletedAt?: string;
  canceledAt?: string;
  endDate?: string;
  externalId?: string;
  finished?: boolean;
  finishOn?: string;
  freeware?: boolean;
  humanId: number;
  id?: number;
  interval?: number;
  joinAssessment?: boolean;
  nextRecurrency?: string;
  paymentId?: string;
  petId: number;
  planId: number;
  recPayId?: string;
  startDate?: string;
  updatedAt?: string;
}

// Interface for human's guests models.
export interface IHumanGuest {
  coupon: string;
  createdAt: string;
  guestId: number;
  guestName: string;
  guestEmail: string;
  subscribed: boolean;
  // petId: number;
  // petName: string;
}

// Interface for discounts models.
export interface ISubscriptionDiscount {
  totalPets: number;
  monthlyDiscount: number;
  annualDiscount: number;
}

// Interface for subscription installments.
export interface ISubscriptionInstallments {
  installments: number;
  text: string;
  value: number;
}

// Enumerator for subscription interval.
export enum ESubscriptionInterval {
  Monthly = 'Monthly',
  Bimonthly = 'Bimonthly',
  Quarterly = 'Quarterly',
  SemiAnnual = 'SemiAnnual',
  Annual = 'Annual',
}

// Interface for additional coverages.
export interface IAdditionalCoverages {
  type: EAdditionalCoverage;
  value?: number;
}

// Enumerator for additional coverage.
export enum EAdditionalCoverage {
  vaccines = 'vaccines',
  checkup = 'checkup',
  dental = 'dental',
}
