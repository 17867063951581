// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import {} from '@app/core/models';

// App interfaces.
import { IHumanLocalData, IPet, IPetFamily } from '@app/core/models';

// App services.
import { AuthService, PetService } from '@app/core/services';

@Component({
  selector: 'app-vp-treatments',
  templateUrl: './vp-treatments.component.html',
  styleUrls: ['./vp-treatments.component.scss'],
  animations: [pageAnimations],
})
export class VpTreatmentsComponent implements OnInit {
  // Pet.
  @Input() pet: IPet;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  // Constuctor method.
  constructor(
    private authService: AuthService,
    private petService: PetService,
    private translate: TranslateService
  ) {}

  // On init.
  public ngOnInit(): void {}
}
