// Angular modules.
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zcurrency' })
export class ZCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'R$',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '3.2-2',
    locale: string = 'pt'
  ): string | null {
    let v: string = value.toString();
    const arV: string[] = v.split('.');
    if (arV.length > 1) {
      v = (parseInt(arV[0], 10) + 1).toString();
      /*const len: number = arV[1].length;
      if (len === 0) {
        v += '00';
      } else if (len === 1) {
        v += arV[1] + '0';
      } else {
        v += arV[1].substring(0, 2);
      }*/
    }
    const f: string = v.substring(0, v.length - 2);
    v = (f || '0') + ',' + v.substring(v.length - 2, v.length);
    return currencyCode + ' ' + v;

    // Código original.
    /*let v: string = value.toString();
    const f: string = v.substring(0, v.length - 2);
    v = (f || '0') + ',' + v.substring(v.length - 2, v.length);
    return currencyCode + ' ' + v;*/

    /*return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );*/
  }
}
