// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App interfaces.
import { IHubSpotUserToken, IAPIResponse } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const rootEP: string = environment.urls.api;
const endpoint: string = rootEP + 'conversations/';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class HubSpotService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  constructor(private authService: AuthService, private http: HttpClient) {}

  // Get all humans.
  public async getVisitorIdentificationToken(): Promise<IHubSpotUserToken> {
    const token = this.getHsToken();

    if (token && this.checkHsTokenExpiration(token)) {
      return token;
    } else {
      this.headers = this.authService.getHttpHeaders();

      const promise = await new Promise<IHubSpotUserToken>(
        (resolve, reject) => {
          this.http
            .get<IAPIResponse>(endpoint, { headers: this.headers })
            .subscribe(
              result => resolve(result.rows),
              error => reject(error)
            );
        }
      );

      this.saveHsToken(promise.token);

      return promise;
    }
  }

  // Set HubSpot settings.
  public setHsSettings(email?: string, token?: IHubSpotUserToken): void {
    token = token || this.getHsToken();
    const userEmail = email || this.authService.getUserEmail();

    if (!token || !userEmail) {
      return;
    }

    window.hsConversationsSettings = {
      identificationEmail: this.authService.getUserEmail(),
      identificationToken: token,
    };
  }

  // Save data into LocalStorage.
  private saveHsToken(token: string): void {
    localStorage.setItem(
      environment.storageKeys.hsUserIdentification,
      JSON.stringify(token)
    );
  }

  // Get data from LocalStorage.
  private getHsToken(): IHubSpotUserToken {
    const hsToken = localStorage.getItem(
      environment.storageKeys.hsUserIdentification
    );
    return JSON.parse(hsToken);
  }

  // Check hubspot token expiration.
  private checkHsTokenExpiration(token: IHubSpotUserToken): boolean {
    if (token.expiration < Date.now()) {
      return false;
    }
    return true;
  }
}
