<section *ngIf="id" id="{{ id }}" class="app-alert-container {{ customClass }}">
  <!-- Icon. -->
  <div class="app-alert-icon"><i class="{{ customIcon }}"></i></div>

  <!-- Message. -->
  <div class="app-alert-message">{{ customMessage }}</div>

  <!-- Close icon. -->
  <div class="app-alert-close">
    <i class="fas fa-times" (click)="closeMessage(id, 0)"></i>
  </div>
</section>
