// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import { IStatsResponse } from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const statsEndpoint: string = endpoint + 'stats/';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all stats.
  public getAllStats(): Promise<IStatsResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IStatsResponse>((resolve, reject) => {
      this.http.get<any>(statsEndpoint, { headers: this.headers }).subscribe(
        result => resolve(result),
        error => reject(error)
      );
    });

    return promise;
  }

  // Get clinics stats.
  public getClinicsStats(): Promise<IStatsResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IStatsResponse>((resolve, reject) => {
      this.http
        .get<any>(statsEndpoint + 'clinics', { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get humans stats.
  public getHumansStats(): Promise<IStatsResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IStatsResponse>((resolve, reject) => {
      this.http
        .get<any>(statsEndpoint + 'humans', { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get pets stats.
  public getPetsStats(): Promise<IStatsResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IStatsResponse>((resolve, reject) => {
      this.http
        .get<any>(statsEndpoint + 'pets', { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get subscriptions stats.
  public getSubscriptionsStats(): Promise<IStatsResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IStatsResponse>((resolve, reject) => {
      this.http
        .get<any>(statsEndpoint + 'subscriptions', { headers: this.headers })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }
}
