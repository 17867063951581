// Angular modules.
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App models.
import { EBroadcast } from '@app/core/models';

// App interfaces.
import { IAddressRegister } from '@app/core/models';

// App services.
import { BroadcastService, LocationService } from '@app/core/services';

// App components.
import { RegisterFormComponent } from '@app/register/register-form/register-form.component';

// Enumerator for active page.
// eslint-disable-next-line no-shadow
enum ECurrentPage {
  Alert = 'Alert',
  Availability = 'Availability',
  Notification = 'Notification',
  Register = 'Register',
  Start = 'Start',
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [slideBottomAnimation],
})
export class RegisterComponent implements OnInit {
  // Components.
  @ViewChild(RegisterFormComponent) regForm: RegisterFormComponent;

  // Location.
  public zipCode: string;
  public zipCity: string;
  public zipState: string;

  // Status.
  public isLoaded: boolean = false;
  public currentPage: ECurrentPage = ECurrentPage.Availability;
  public isLoadingPostalCode: boolean = false;
  public zipCodeFound: boolean = false;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private locationService: LocationService,
    private router: Router
  ) {}

  // On init.
  public ngOnInit(): void {
    switch (this.router.url) {
      case '/register':
        this.currentPage = ECurrentPage.Start;
        this.router.navigate(['/register/form']);
        // this.currentPage = ECurrentPage.Availability;
        // this.router.navigate(['/register/availability']);
        break;

      case '/register/form':
        // if (this.currentPage !== ECurrentPage.Register) { this.currentPage = ECurrentPage.Register; }
        break;

      case '/register/notification':
        this.currentPage = ECurrentPage.Notification;
        break;
    }

    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/register'
      );
      this.isLoaded = true;
      // setTimeout(() => { document.getElementById('txt-email') && document.getElementById('txt-email').focus(); }, 1500);
    }, 1000);
  }

  // When user is typing on postal code field.
  public typingPostalCode(event: any): void {
    const val: string = this.zipCode;
    if (val && val.length >= 8) {
      this.getAddress(val);
    }
  }

  // Get address based on the given CEP.
  public getAddress(cep: string): void {
    this.isLoadingPostalCode = true;

    this.locationService
      .searchByZipCode(cep)
      .subscribe((data: IAddressRegister) => {
        this.zipCode = cep;
        this.zipCity = data.city;
        this.zipState = data.state;

        this.zipCodeFound = true;
        this.isLoadingPostalCode = false;
      });
  }

  // Show register page.
  public redirectToRegister(event?: any): void {
    this.currentPage = ECurrentPage.Register;

    setTimeout(() => {
      this.zipCode = event;
    }, 300);
  }

  // Show register for notification page.
  public redirectToNotification(event?: any): void {
    // this.currentPage = ECurrentPage.Notification;
    window.location.href = window.location.href + '/notification';
  }

  // Show main page.
  public redirectToMain(event?: any): void {
    this.currentPage = ECurrentPage.Availability;
  }
}
