<section *ngIf="isLoaded" id="recover-pass" class="page-container" [@slideDown]>
  <!-- Empty column. -->
  <div class="page-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div class="page-content page-form">
    <div class="form-container" [@slideDown]>
      <!-- Logo (mobile only). -->
      <img
        class="img-logo mob-logo"
        src="assets/images/petbee.png"
        (click)="goToHome()" />

      <!-- Instructions. -->
      <h1 style="font-style: italic">
        {{ 'LOGIN.RECOVER.FORGOT_PASSWORD' | translate }}
      </h1>
      <p>{{ 'LOGIN.RECOVER.TRY_RECOVER' | translate }}</p>

      <!-- E-Mail. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="email"
          id="txt-email"
          [(ngModel)]="email"
          (keydown.enter)="sendEmail($event)"
          [disabled]="isSendingEmail"
          appInputs />
        <span class="frm-label">{{ 'LOGIN.ELEMENTS.EMAIL' | translate }}</span>
      </div>
      <span *ngIf="errEmail" class="spn-error">{{ errEmail }}</span>

      <!-- E-mail was sent. -->
      <div *ngIf="emailWasSent" class="email-sent">
        <p>{{ 'LOGIN.RECOVER.FOLLOW_INSTRUCTIONS' | translate }}</p>

        <p class="p-link">
          <a role="button" (click)="tryAgain()">{{
            'GENERIC.TRY_AGAIN' | translate
          }}</a>
        </p>
      </div>

      <!-- Send e-mail button. -->
      <div *ngIf="!emailWasSent" class="form-element">
        <button
          class="btn btn-info btn-full"
          (click)="sendEmail()"
          [disabled]="isSendingEmail">
          <span *ngIf="!isSendingEmail">{{
            'LOGIN.RECOVER.BUTTON_TEXT' | translate
          }}</span>
          <span *ngIf="isSendingEmail"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <div class="new-login">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/login')">
            {{ 'LOGIN.ELEMENTS.SIGN_IN' | translate }}
          </button>
        </div>

        <div class="new-account">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/register')">
            {{ 'LOGIN.ELEMENTS.REGISTER' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- [CONTENT: END] -->

  <!-- Empty column. -->
  <div class="page-content lg-b"></div>
</section>
