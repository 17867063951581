<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <p style="font-size: small">
    Utilize os campos abaixo para alterar a senha do usuário.
  </p>

  <!-- Admin password. -->
  <div>
    <div class="frm-element">
      <input
        class="frm-textbox"
        type="password"
        id="txt-password"
        [(ngModel)]="password"
        (blur)="elLostFocus($event)"
        [disabled]="isSaving"
        appInputs />
      <span class="frm-label">Sua senha de administrador</span>
    </div>
    <span *ngIf="errPassword" class="spn-error">{{ errPassword }}</span>
  </div>

  <!-- New password. -->
  <div>
    <div class="frm-element">
      <input
        class="frm-textbox"
        type="password"
        id="txt-new-password"
        [(ngModel)]="newPassword"
        (blur)="elLostFocus($event)"
        [disabled]="isSaving"
        appInputs />
      <span class="frm-label">Nova senha do usuário</span>
    </div>
    <span *ngIf="errNewPassword" class="spn-error">{{ errNewPassword }}</span>
  </div>

  <!-- Confirm new password. -->
  <div>
    <div class="frm-element">
      <input
        class="frm-textbox"
        type="password"
        id="txt-password-confirm"
        [(ngModel)]="newPasswordConfirm"
        (blur)="elLostFocus($event)"
        [disabled]="isSaving"
        appInputs />
      <span class="frm-label">Confirme a nova senha do usuário</span>
    </div>
    <span *ngIf="errNewPasswordConfirm" class="spn-error">{{
      errNewPasswordConfirm
    }}</span>
  </div>

  <!-- Passwords don't match. -->
  <div
    *ngIf="errPasswordMatch"
    class="frm-element"
    style="height: auto; text-align: center">
    <span class="spn-error">{{ errPasswordMatch }}</span>
  </div>

  <!-- Save button. -->
  <div style="margin: 20px 0 0; text-align: center">
    <button
      type="button"
      class="btn btn-success btn-icon-label"
      (click)="changePassword()"
      [disabled]="isSaving">
      <span class="btn-inner--icon">
        <i class="fas fa-key"></i>
      </span>
      <span *ngIf="!isSaving" class="btn-inner--text">Salvar nova senha</span>
      <span *ngIf="isSaving" style="margin-left: 50px"
        ><i class="fas fa-spinner fa-spin"></i
      ></span>
    </button>
  </div>
</div>
