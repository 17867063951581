// Angular modules.
import { Component } from '@angular/core';

@Component({
  selector: 'app-horizontal-loader',
  templateUrl: './horizontal-loader.component.html',
  styleUrls: ['./horizontal-loader.component.scss'],
})
export class HorizontalLoaderComponent {
  // Constructor method.
  constructor() {}
}
