// Angular modules.
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Third-party.
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Module routing.
import { NotFoundRoutingModule } from './not-found-routing.module';

// App modules.
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';

// Module components.
import { NotFoundComponent } from './not-found.component';

// @ngx-translate: AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    // App components.
    NotFoundComponent,
  ],
  exports: [
    // App routing.
    NotFoundRoutingModule,

    // App components.
    NotFoundComponent,
  ],
  imports: [
    // Angular modules.
    CommonModule,
    FormsModule,

    // Third-party.
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // App routing.
    NotFoundRoutingModule,

    // App modules
    CoreModule,
    SharedModule,
  ],
})
export class NotFoundModule {}
