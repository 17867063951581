// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType, IHuman } from '@app/core/models';

// App models.
import {} from '@app/core';

// App interfaces.
import { IHumanLocalData } from '@app/core';

// App services.
import {
  AuthService,
  ErrorService,
  HumanService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
  animations: [modalAnimation],
})
export class ChangeAvatarComponent implements OnInit {
  // Outputs.
  @Output() finished: EventEmitter<IHumanLocalData> =
    new EventEmitter<IHumanLocalData>();
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  // Logged human's data.
  @Input() human: IHumanLocalData;
  public humanAvatar: string;

  // Image cropper.
  public imageChangedEvent: any = '';

  // New avatar.
  public currentAvatar: string;
  public newAvatar: any;
  public newAvatarUrl: any;
  public newAvatarBtnText: string = this.translate.instant(
    'PETS.CHOOSE_PICTURE'
  );
  public avatarUpdated: boolean = false;
  public errText: string;

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public upReady: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private humanService: HumanService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    this.humanAvatar = this.human.picture;
  }

  // Update avatar.
  public saveNewPicture(): void {
    if (this.newAvatar) {
      this.isSaving = true;

      this.humanService
        .updateHumanAvatar(this.human.id, this.newAvatar)
        .then(resAvatar => {
          this.human = this.authService.updateHumansLocalData(resAvatar);
          this.closeModal();
        })
        .catch(errAvatar => {
          this.errText = JSON.stringify(errAvatar, [
            'message',
            'arguments',
            'type',
            'name',
          ]);
          this.utilsService.showAlert(
            'human-reg',
            this.errorService.manageError(
              errAvatar,
              this.constructor.name,
              'saveNewPicture() > humanService.updateHumanAvatar()'
            ),
            EAlertType.Error
          );
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      this.utilsService.showAlert(
        'human-reg',
        'Selecione uma imagem.',
        EAlertType.Error
      );
    }
  }

  // Change avatar.
  public changeHumanAvatar(event?: any): void {
    if (event && event.target && event.target.files.length > 0) {
      this.errText = null;
      this.newAvatar = event.target.files[0];

      if (this.newAvatar.type.match(/image\/*/) == null) {
        this.errText = this.translate.instant(
          'PROFILE.ACCOUNT.NEW_PICTURE_INVALID'
        );
        this.newAvatar = null;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(this.newAvatar);
        reader.onload = ev => {
          // this.newAvatarUrl = reader.result;
          // this.upReady = true;
          this.imageChangedEvent = event;
        };

        this.newAvatarBtnText = this.newAvatar.name;
      }
    }
  }

  // [CROPPER] When image is cropped.
  public imageCropped(event: ImageCroppedEvent): void {
    this.newAvatarUrl = event.base64;
    this.newAvatar = event;
  }

  // [CROPPER] When image is loaded.
  public imageLoaded(): void {}

  // [CROPPER] When cropper is ready.
  public cropperReady(): void {}

  // [CROPPER] On error.
  public loadImageFailed(): void {}

  public cropDone(): void {
    this.imageChangedEvent = null;
    this.upReady = true;
  }

  // Get generic avatar.
  public getGenericAvatar(): string {
    const man: string = 'generic-man.png';
    const woman: string = 'generic-woman.png';

    return 'assets/images/avatar/' + man;
  }

  // Cancel upload new avatar.
  public cancelNewAvatar(): void {
    this.clearErrors();
    this.upReady = false;
    this.newAvatar = null;
    this.newAvatarUrl = null;
    this.newAvatarBtnText = this.translate.instant('PETS.CHOOSE_PICTURE');
    const el: HTMLInputElement = document.getElementById(
      'file-picture'
    ) as HTMLInputElement;
    el.value = '';
  }

  // Close modal.
  public closeModal(saved: boolean = true): void {
    if (saved) {
      this.finished.emit(this.human);
    } else {
      this.modalClosed.emit();
    }
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errText = null;
  }
}
