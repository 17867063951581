<section class="modal-container" (keyup.esc)="closeModal()" [@slideUpDown]>
  <div class="change-card-modal" [ngClass]="{ 'mobile-mode': isMobile }">
    <!-- Close modal. -->
    <i
      class="fas fa-times"
      [ngClass]="{ 'mobile-mode': isMobile }"
      (click)="closeModal()"></i>

    <!-- Title. -->
    <div class="modal-title">{{ 'GENERIC.CHANGE_CARD' | translate }}</div>

    <!-- [CONTENT: START] -->
    <div
      *ngIf="!isLoading"
      class="modal-content"
      [ngClass]="{ 'mobile-mode': isMobile }">
      <!-- Tips. -->
      <div class="cc-tips">
        <p>
          Você pode trocar o cartão de crédito utilizado na Petbee a qualquer
          momento, e qualquer cobrança a partir de então será realizada neste
          novo cartão.
        </p>

        <p>
          Lembre-se que nós não armazenamos os dados do seu cartão. As
          informações são repassadas ao nosso gateway de pagamentos (Cielo), que
          se responsabiliza por toda a parte de cobrança, bem como toma todas as
          medidas necessárias relacionadas à segurança das transações e
          armazenamento dos dados.
        </p>

        <p>
          Se tiver alguma dúvida a respeito, por favor,
          <a href="support" target="blank"
            >entre em contato com o nosso suporte</a
          >.
        </p>
      </div>

      <!-- [FORM: START] -->
      <div class="cc-form" [ngClass]="{ 'mobile-mode': isMobile }">
        <!-- Card's number. -->
        <div class="frm-element">
          <div style="min-height: 40px">
            <input
              id="card-number"
              class="frm-textbox"
              type="text"
              [(ngModel)]="cardNumber"
              (focusout)="validateCardNumber()"
              [disabled]="isSaving"
              appInputs />
            <span class="frm-label">{{
              'CREDIT_CARD.FIELDS.NUMBER' | translate
            }}</span>
            <img
              *ngIf="cardIcon"
              class="ico-cardFlag"
              src="assets/images/icons/cards/{{ cardIcon }}" />
          </div>
          <span *ngIf="errCardNumber" class="spn-error spn-err-input">{{
            errCardNumber
          }}</span>
        </div>

        <!-- Card's name. -->
        <div class="frm-element">
          <div style="min-height: 40px">
            <input
              id="card-name"
              class="frm-textbox"
              type="text"
              [(ngModel)]="cardName"
              (keypress)="makeNameUpper()"
              (focusout)="validateCardName()"
              [disabled]="isSaving"
              appInputs />
            <span class="frm-label">{{
              'CREDIT_CARD.FIELDS.NAME' | translate
            }}</span>
          </div>
          <span *ngIf="errCardName" class="spn-error spn-err-input">{{
            errCardName
          }}</span>
        </div>

        <div class="row">
          <!-- Card's month. -->
          <div
            class="frm-element frm-el-inline"
            [ngClass]="{ 'mobile-mode': isMobile }">
            <div style="min-height: 40px">
              <input
                id="card-month"
                class="frm-textbox"
                type="text"
                [(ngModel)]="cardMonth"
                (focusout)="validateCardMonth()"
                [disabled]="isSaving"
                appInputs />
              <span class="frm-label">{{
                'CREDIT_CARD.FIELDS.MONTH' | translate
              }}</span>
            </div>
            <span *ngIf="errCardMonth" class="spn-error spn-err-input">{{
              errCardMonth
            }}</span>
          </div>

          <!-- Card's year. -->
          <div
            class="frm-element frm-el-inline"
            [ngClass]="{ 'mobile-mode': isMobile }">
            <div style="min-height: 40px">
              <input
                id="card-year"
                class="frm-textbox"
                type="text"
                [(ngModel)]="cardYear"
                (focusout)="validateCardYear()"
                [disabled]="isSaving"
                appInputs />
              <span class="frm-label">{{
                'CREDIT_CARD.FIELDS.YEAR' | translate
              }}</span>
            </div>
            <span *ngIf="errCardYear" class="spn-error spn-err-input">{{
              errCardYear
            }}</span>
          </div>

          <!-- Card's CVC. -->
          <div
            class="frm-element frm-el-inline"
            [ngClass]="{ 'mobile-mode': isMobile }">
            <div style="min-height: 40px">
              <input
                id="card-cvc"
                class="frm-textbox"
                type="text"
                [(ngModel)]="cardCVC"
                (focusout)="validateCardCVC()"
                [disabled]="isSaving"
                appInputs />
              <span class="frm-label">{{
                'CREDIT_CARD.FIELDS.CVC' | translate
              }}</span>
            </div>
            <span *ngIf="errCardCVC" class="spn-error spn-err-input">{{
              errCardCVC
            }}</span>
          </div>
        </div>

        <!-- Save button. -->
        <div class="row" style="text-align: center">
          <button
            type="button"
            class="btn btn-sm btn-success btn-icon-label btn-next"
            [ngClass]="{ 'mobile-mode': isMobile }"
            (click)="saveNewCard()"
            [disabled]="isSaving || !formValid">
            <span class="btn-inner--icon"
              ><i class="fas fa-check-circle"></i
            ></span>
            <span *ngIf="!isSaving" class="btn-inner--text">{{
              'GENERIC.SAVE_UPDATES' | translate
            }}</span>
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>
      </div>
      <!-- [FORM: END] -->
    </div>
    <!-- [CONTENT: END] -->
  </div>
</section>
