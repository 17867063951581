// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideFadeAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast, EAlertType } from '@app/core/models';

// App models.
import { IClinic, IHumanLocalData, IUserMenu } from '@app/core';

// App services.
import {
  AuthService,
  BroadcastService,
  ErrorService,
  HumanService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-sec-menu-mobile',
  templateUrl: './sec-menu-mobile.component.html',
  styleUrls: ['./sec-menu-mobile.component.scss'],
  animations: [slideFadeAnimation],
})
export class SecMenuMobileComponent implements OnInit {
  // Outputs.
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Human's clinics.
  public clinics: IClinic[] = [];
  public myClinicText: string = this.translate.instant(
    'MENU.USER_MENU.MY_CLINIC'
  );
  public myClinicsText: string = this.translate.instant(
    'MENU.USER_MENU.MY_CLINICS'
  );

  // Menu.
  public menu: IUserMenu[] = [];
  public menuAdmin: IUserMenu[] = [];

  // Status.
  public isProdMode: boolean = environment.production;
  public isLoading: boolean = true;
  public showContent: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private http: HttpClient,
    private humanService: HumanService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {
    // Subscribe to broadcast events.
    this.listenToBroadcast();

    // Subscribe to router events.
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.changedPage();
      }
    });
  }

  // On init.
  public ngOnInit(): void {
    // Build user's menu.
    this.buildMenu();

    // Get clinics.
    this.getHumanClinics();

    // Get active menu (if any).
    this.changedPage(this.router.url);

    /*this.http.get('https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2692572634153245&height=50&width=50&ext=1586891858&hash=AeQ-zEPKm7thyPD4').subscribe(
      res => console.error(res),
      err => console.error(err)
    );

    this.http.get('https://www.petbee.com.br/img/petbee.png').subscribe(
      res => console.error(res),
      err => console.error(err)
    );*/
  }

  // Change active language.
  public changeLanguage(lang: string): void {
    this.broadcastService.sendBroadcast(EBroadcast.ChangeLanguage, lang);
  }

  // Close menu.
  public closeMenu(): void {
    this.close.emit();
  }

  // Show refer a friend modal.
  public showReferModal(): void {
    this.broadcastService.sendBroadcast(EBroadcast.ToggleReferModal);
    this.closeMenu();
  }

  // Go to another page.
  public goToPage(url: string): void {
    this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn);
    setTimeout(() => {
      this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOff);
    }, 1000);
    this.close.emit();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  // Go to a clinic's page.
  public goToClinic(clinId: number): void {
    // this.router.navigate(['my-clinics/manage/' + clinId]);
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['my-clinics/manage/' + clinId]));
  }

  // Logout.
  public logout(): void {
    this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn);
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 500);
  }

  // When a new page is loaded.
  private changedPage(force?: string): void {
    const u: string = (force || this.router.url).replace('/', '');
    this.menu.map(item => (item.active = item.url === u));
  }

  // Listen to broadcast events.
  private listenToBroadcast(): void {
    this.broadcastService.events.subscribe(ev => {
      switch (ev.key) {
        // Change language.
        case EBroadcast.ChangedLanguage:
          this.buildMenu();
          break;
      }
    });
  }

  // Get human's clinics.
  private getHumanClinics(): void {
    this.isLoading = true;
    this.clinics = [];

    this.humanService
      .getHumanClinics(parseInt(this.human.id, 10))
      .then(res => {
        this.clinics = (res.rows as IClinic[]) || [];
      })
      .catch(err => {
        this.utilsService.showAlert(
          'second-sb',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getHumanClinics() > humanService.getHumanClinics()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  // Build menu items.
  private buildMenu(): void {
    // General menu.
    this.menu = [];
    // this.menu.push({
    //   active: false,
    //   adminOnly: false,
    //   icon: 'fas fa-paw',
    //   title: this.translate.instant('MENU.USER_MENU.MY_PETS'),
    //   url: '',
    // });
    this.menu.push({
      active: false,
      adminOnly: false,
      icon: 'fas fa-address-card',
      title: this.translate.instant('MENU.USER_MENU.MY_DATA'),
      url: 'my-data',
    });
    // this.menu.push({
    //   active: false,
    //   adminOnly: false,
    //   icon: 'fas fa-headset',
    //   title: this.translate.instant('MENU.USER_MENU.SUPPORT'),
    //   url: 'support',
    // });

    // Admin menu.
    this.menuAdmin = [];
    if (this.human.isAdmin) {
      this.menuAdmin.push({
        active: false,
        adminOnly: true,
        icon: 'fas fa-user-tie',
        title: this.translate.instant('MENU.USER_MENU.ADMIN'),
        url: 'admin',
      });
    }
  }
}
