// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { NotFoundComponent } from '@app/not-found/not-found.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: NotFoundComponent,
    data: {
      title: 'Página não localizada - Petbee',
      description: 'Esta página não existe.',
      ogUrl: 'https://clinics.petbee.com.br',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotFoundRoutingModule {}
