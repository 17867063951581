<section *ngIf="isLoaded" id="agreements" class="modal-container" [@slideDown]>
  <div class="close-modal">
    <i class="far fa-times-circle" (click)="closeWindow($event)"></i>
  </div>

  <div class="modal-content">
    <h1>Declaração de privacidade</h1>

    <p>
      Esta Declaração de privacidade explica as práticas da Petbee, incluindo as
      suas escolhas como cliente, referentes à coleta, utilização e divulgação
      de certas informações.
    </p>

    <h3>Como contatar a Petbee</h3>

    <p>
      Caso tenha dúvidas gerais sobre sua conta ou sobre como entrar em contato
      com o atendimento ao cliente, acesse nossa página de contato em
      <a href="https://www.petbee.com.br/contato" target="blank"
        >petbee.com.br/contato</a
      >. Para questões específicas sobre esta Declaração de privacidade,
      incluindo a utilização de informações pessoais, cookies e outras
      tecnologias semelhantes, entre em contato com o nosso Diretor de Proteção
      de Dados/Divisão de Privacidade por email no endereço
      atendimento@Petbee.com.br.
    </p>

    <p>
      A Petbee Tecnologia de Saude, Ltda. é a controladora das suas informações
      pessoais. Se você entrar em contato solicitando assistência, para sua
      segurança e da Petbee, poderá ser preciso autenticar sua identidade antes
      de atender ao seu pedido.
    </p>

    <h3>Coleta de informações</h3>

    <p>A Petbee recebe e armazena informações sobre você, tais como:</p>

    <ul>
      <li class="li-parent">
        <strong>Informações que você nos fornece:</strong> a Petbee coleta as
        informações que você nos fornece, que incluem:
        <ul>
          <li>
            seu nome, email, endereço ou código postal, forma(s) de pagamento e
            número de telefone. Em certos países, coletamos um número de
            identificação nacional para fins de cobrança e cumprimento das leis
            fiscais. As informações são coletadas de diversas formas, incluindo
            quando você as fornece ao usar o serviço, interage com o serviço de
            atendimento ao cliente da Petbee ou participa em pesquisas ou
            promoções de marketing;
          </li>
          <li>
            informações quando você opta por classificar títulos, definir suas
            preferências pessoais e configurações (inclusive preferências
            configuradas na seção “Conta” no nosso site) ou, de outra forma,
            fornece informações à Petbee, seja por intermédio do serviço Petbee ou
            outros.
          </li>
        </ul>
      </li>

      <li class="li-parent">
        <strong>Informações coletadas automaticamente pela Petbee:</strong> a
        Petbee coleta informações sobre você e seu uso do serviço Petbee, suas
        interações com a Petbee e a publicidade da Petbee, assim como informações
        sobre seu computador e outros aparelhos utilizados para acessar o
        serviço Petbee (tais como videogames, smart TVs, aparelhos móveis,
        decodificadores e outros aparelhos de streaming). Tais informações
        incluem:
        <ul>
          <li>
            suas atividades no serviço Petbee, como os títulos selecionados, o
            histórico de títulos assistidos e buscas;
          </li>
          <li>
            suas interações com nossos emails, nossas mensagens de texto e
            nossas mensagens enviadas por canais de comunicação push e online;
          </li>
          <li>
            detalhes sobre suas interações com o serviço de atendimento ao
            cliente, tais como data, hora e motivo do contato, transcrição de
            comunicação pelo bate-papo e, no caso de chamadas, o seu número de
            telefone e as gravações das chamadas;
          </li>
          <li>IDs ou outros identificadores únicos de aparelhos;</li>
          <li>
            identificadores de aparelho redefiníveis (também conhecidos como
            identificadores de publicidade), como os incluídos em certos
            aparelhos móveis, tablets e aparelhos de streaming (consulte a seção
            “Cookies e publicidade na Internet” abaixo para obter mais
            informações);
          </li>
          <li>
            características de aparelhos e software (tais como o tipo e
            configuração), informações sobre a conexão, estatísticas sobre
            visualizações de página, fonte encaminhadora (por exemplo, URLs de
            origem), endereço IP (que pode indicar à Petbee sua localização
            geográfica aproximada), navegador e dados padrão de logs de
            servidores da Internet;
          </li>
          <li>
            informações coletadas pelo uso de cookies, web beacons e outras
            tecnologias, incluindo dados de anúncios (tais como informações
            sobre a disponibilidade e distribuição de anúncios, a URL do site,
            assim como a data e hora). (Consulte a seção “Cookies e publicidade
            na Internet” para obter mais detalhes).
          </li>
        </ul>
      </li>

      <li class="li-parent">
        <strong>Informações obtidas com parceiros:</strong> a Petbee coleta
        informações de outras empresas com as quais você mantém um
        relacionamento (“Parceiros”). Esses Parceiros podem incluir (dependendo
        dos serviços que você usa): o seu provedor de serviços de TV ou
        Internet, ou outros provedores de aparelhos de transmissão multimídia
        que disponibilizam o nosso serviço no seu aparelho; operadoras de
        telefonia celular ou outras empresas que fornecem serviços e recebem o
        pagamento pelo serviço Petbee e o repassam para nós; e provedores de
        plataformas de assistente de voz que permitem a interação com o nosso
        serviço por meio de comandos de voz. As informações fornecidas pelos
        Parceiros para nós dependem da natureza dos serviços do Parceiro e podem
        incluir:
        <ul>
          <li>
            consultas e comandos de busca de mídia realizados em aparelhos ou
            plataformas de assistente de voz do Parceiro;
          </li>
          <li>
            informações de ativação de serviço, como o seu email ou outras
            informações de contato;
          </li>
          <li>
            IDs de aparelho ou outros identificadores únicos usados na
            autenticação de usuários, na experiência de assinatura do serviço
            Petbee, no processamento de pagamentos pelo Parceiro e na exibição de
            conteúdo da Petbee para você por meio de partes da interface do
            usuário do Parceiro.
          </li>
        </ul>
      </li>

      <li class="li-parent">
        <strong>Informações de outras fontes:</strong> a Petbee também obtém
        informações de outras fontes. Protegemos essas informações de acordo com
        as práticas descritas nesta Declaração de privacidade, além de respeitar
        as restrições adicionais impostas pela fonte dos dados. Essas fontes
        variam no decorrer do tempo, mas podem incluir:
        <ul>
          <li>
            prestadores de serviços que nos ajudam a identificar uma localização
            baseada no seu endereço IP para personalizar o nosso serviço e para
            outros fins compatíveis com esta Declaração de privacidade;
          </li>
          <li>
            prestadores de serviços de pagamento que nos fornecem informações de
            pagamento ou atualizações dessas informações baseadas no seu
            relacionamento com você;
          </li>
          <li>
            fornecedores de dados online e offline, dos quais obtemos dados
            demográficos, dados baseados no interesse e dados relacionados a
            anúncios online;
          </li>
          <li>
            fontes disponíveis ao público, como bancos de dados abertos do
            governo.
          </li>
        </ul>
      </li>
    </ul>

    <h3>Uso de informações</h3>

    <p>
      A Petbee utiliza as informações para oferecer, analisar, administrar,
      aprimorar e personalizar nossos serviços e esforços de marketing, para
      processar sua inscrição, seus pedidos e pagamentos, e para nos
      comunicarmos com você sobre esses e outros assuntos. Por exemplo, a Petbee
      utiliza as informações para:
    </p>

    <ul>
      <li>
        determinar sua localização geográfica aproximada, oferecer conteúdo
        localizado, oferecer recomendações personalizadas e customizadas de
        filmes e séries que, na nossa avaliação, poderiam ser do seu interesse,
        determinar o seu provedor de serviços de Internet e ajudar nossa equipe
        a responder de forma rápida e eficiente às suas dúvidas e solicitações;
      </li>
      <li>
        coordenar com os Parceiros a disponibilização do serviço Petbee para os
        assinantes e fornecer aos não assinantes informações sobre a
        disponibilidade do serviço Petbee de acordo com a relação específica que
        você mantém com o Parceiro;
      </li>
      <li>
        prevenir, detectar e investigar atividades possivelmente proibidas ou
        ilegais, incluindo atividades fraudulentas, e aplicar nossos termos
        (tais como determinar a sua elegibilidade para uma utilização gratuita);
      </li>
      <li>
        analisar e entender nosso público, melhorar o serviço (inclusive a
        interface do usuário) e otimizar a seleção de conteúdo, os algoritmos de
        recomendação e a transmissão;
      </li>
      <li>
        comunicar-se com você sobre o serviço para que possamos enviar novidades
        sobre a Petbee, detalhes sobre novas funcionalidades, conteúdos
        disponíveis na Petbee, ofertas especiais, anúncios sobre promoções,
        pesquisas de mercado, e para ajudar você com pedidos de natureza
        operacional, como pedidos de redefinição de senha. Essas comunicações
        podem ser feitas por vários métodos, tais como email, notificações push,
        mensagens de texto, canais de mensagens online e comunicações de
        identificadores correspondentes (descritas abaixo). Consulte a seção
        “Suas opções” desta Declaração de privacidade para obter informações
        sobre como configurar e modificar suas preferências de comunicação.
      </li>
    </ul>

    <h3>Divulgação de informações</h3>

    <p>
      A Petbee divulgará seus dados para fins específicos e a terceiros, conforme
      descrição abaixo:
    </p>

    <ul>
      <li class="li-parent">
        <strong>Prestadores de Serviços:</strong> a Petbee poderá contratar
        outras empresas, agentes ou terceirizados (os “Prestadores de Serviços”)
        para fornecer serviços em nome da Petbee ou ajudar a Petbee no
        fornecimento de serviços destinados a você. Por exemplo, a Petbee
        contrata Prestadores de Serviços para fornecer serviços de marketing,
        comunicação, infraestrutura e serviços de TI, para personalizar e
        otimizar o serviço Petbee, processar transações por cartão de crédito e
        outras formas de pagamento, fornecer serviços de atendimento ao cliente,
        cobrar dívidas, analisar e aprimorar dados (inclusive dados de interação
        com o serviço Petbee) e conduzir pesquisas de mercado. No decorrer da
        prestação desses serviços, esses Prestadores de Serviços podem ter
        acesso a suas informações pessoais ou de outra natureza. Não autorizamos
        estas empresas a usar ou divulgar seus dados pessoais, a não ser com a
        finalidade de fornecer os serviços contratados pela Petbee.
      </li>

      <li class="li-parent">
        <strong>Parceiros:</strong> como descrito acima, você pode ter um
        relacionamento com um ou mais de nossos Parceiros. Nesse caso, poderemos
        compartilhar determinadas informações com esses Parceiros para coordenar
        com eles a prestação do serviço Petbee aos assinantes e fornecer
        informações sobre a disponibilidade do serviço Petbee. Por exemplo,
        dependendo de quais serviços de Parceiros você usa, poderemos
        compartilhar informações:
        <ul>
          <li>
            para facilitar o recebimento do pagamento do serviço Petbee pelo
            Parceiro e o repasse desse pagamento para a Petbee;
          </li>
          <li>
            com Parceiros que operam plataformas de assistente de voz que
            permitem interagir com o nosso serviço por meio de comandos de voz;
          </li>
          <li>
            para que seja possível sugerir a você, na interface de usuário do
            Parceiro, o conteúdo e os recursos disponíveis no serviço Petbee.
            Para os assinantes, essas sugestões fazem parte do serviço Petbee e
            podem incluir recomendações personalizadas de conteúdo a ser
            assistido.
          </li>
        </ul>
      </li>

      <li class="li-parent">
        <strong>Ofertas promocionais:</strong> a Petbee poderá oferecer programas
        ou promoções conjuntas que, para efeitos de participação, exijam que
        informações pessoais sejam compartilhadas com terceiros. Para realizar
        estes tipos de promoção, a Petbee poderá compartilhar seu nome e outras
        informações pessoais referentes ao benefício que estamos oferecendo. Por
        favor, observe que estes terceiros são responsáveis por suas próprias
        políticas de privacidade.
      </li>

      <li class="li-parent">
        <strong>Proteção da Petbee e outros:</strong> a Petbee e seus Prestadores
        de Serviços poderão divulgar ou, de outra forma, utilizar suas
        informações pessoais quando a Petbee ou tais empresas tiverem motivos
        razoáveis para acreditar que tal divulgação é necessária para (a)
        atender a alguma lei ou norma aplicável, processo legal ou solicitação
        governamental, (b) fazer cumprir os termos de uso aplicáveis, incluindo
        a investigação de potenciais infrações destes, (c) detectar, prevenir ou
        endereçar atividades ilegais ou suspeitas (inclusive fraude de
        pagamentos), problemas técnicos ou de segurança ou (d) proteger-se
        contra infrações aos direitos, propriedade ou segurança da Petbee, de
        seus usuários ou do público, conforme requerido ou permitido por lei.
      </li>

      <li class="li-parent">
        <strong>Transferência de propriedade:</strong> em relação a qualquer
        reorganização, reestruturação, fusão ou venda, ou transferência de
        ativos, a Petbee transferirá informações, incluindo informações pessoais,
        desde que a parte receptora se comprometa a respeitar suas informações
        pessoais de maneira condizente com nossa Declaração de privacidade. No
        curso do compartilhamento de dados, sempre que transferirmos informações
        pessoais a países fora do Espaço Econômico Europeu e outras regiões com
        leis abrangentes de proteção de dados, nós nos certificaremos de que as
        informações sejam transferidas segundo esta Declaração de privacidade e
        conforme permitido pela legislação de proteção de dados aplicável. Você
        também poderá optar por divulgar informações suas das seguintes
        maneiras:
        <ul>
          <li>
            determinadas seções do serviço Petbee poderão conter uma ferramenta
            que lhe dá a opção de compartilhar informações por email, mensagem
            de texto ou outros aplicativos de compartilhamento por intermédio de
            softwares cliente e aplicativos instalados no seu aparelho;
          </li>
          <li>
            plugins de redes sociais e tecnologias similares permitem o
            compartilhamento de informações. Plugins e aplicativos de redes
            sociais são operados pelas próprias redes sociais e estão sujeitos
            aos seus respectivos termos de uso e políticas de privacidade.
          </li>
        </ul>
      </li>
    </ul>

    <h3>Acesso à conta e perfis</h3>

    <p>
      Para simplificar o acesso à sua conta, você pode utilizar a função
      “Lembre-se de mim neste aparelho” ao acessar o site. Essa função utiliza
      tecnologia que nos permite dar acesso direto à conta e ajuda a administrar
      o serviço Petbee sem redigitar a senha ou identificação do usuário ao
      retornar ao serviço no navegador.
    </p>
    <p>
      Para excluir o acesso à sua conta Petbee de seus aparelhos: (a) acesse a
      seção “Conta” no nosso site, selecione “Encerrar a sessão em todos os
      aparelhos” e siga as instruções para desativar os aparelhos (a desativação
      poderá não ocorrer imediatamente) ou (b) remova as configurações da sua
      conta Petbee no seu aparelho (as instruções variam segundo o aparelho, e
      esta opção não está disponível em todos os aparelhos). Sempre que
      possível, assinantes que utilizam aparelhos públicos ou compartilhados
      devem encerrar sua sessão ao final de cada acesso. Você sempre deve
      encerrar sua sessão e desativar o aparelho compatível com a Petbee se for
      vender ou devolver um computador ou aparelho. Se você não mantiver a
      segurança da sua senha ou aparelho, ou não encerrar a sessão e desativar o
      seu aparelho, usuários subsequentes poderão ter acesso à sua conta e a
      suas informações pessoais.
    </p>
    <p>
      Se você compartilhar ou permitir que outras pessoas tenham acesso à sua
      conta, elas poderão ver suas informações (inclusive algumas informações
      pessoais), como seu histórico de títulos assistidos, classificações e
      informações da sua conta (inclusive seu email e outros dados na seção
      “Conta” no nosso site). Isso ocorre mesmo utilizando o recurso de perfis.
    </p>

    <h3>Suas informações e direitos</h3>

    <p>
      Você pode solicitar acesso às suas informações pessoais, bem como pode
      corrigir ou atualizar informações pessoais desatualizadas ou incorretas
      que temos sobre você.
    </p>
    <p>
      Para isso, basta visitar a seção “Conta” no nosso site, onde você pode
      acessar e atualizar uma série de informações sobre sua conta, inclusive
      suas informações de contato, de pagamento e diversas outras relacionadas à
      sua conta (como o conteúdo a que você assistiu e classificou). Será
      preciso iniciar uma sessão para acessar a seção “Conta”. Você também pode
      solicitar que a Petbee exclua suas informações pessoais.
    </p>
    <p>
      Para fazer essas solicitações ou caso você tenha qualquer outra dúvida
      sobre nossa política de privacidade, escreva para o Diretor de Proteção de
      Dados/Divisão de Privacidade por email no endereço
      atendimento@Petbee.com.br Respondemos a todas as solicitações que recebemos
      de indivíduos que queiram exercer os seus direitos de proteção de dados em
      conformidade com as respectivas leis de proteção de dados. Consulte também
      a seção “Suas opções” desta Declaração de privacidade para ver quais são
      suas outras opções relativas às suas informações.
    </p>
    <p>
      A Petbee pode rejeitar solicitações que sejam desarrazoadas ou não exigidas
      por lei, como aquelas que sejam extremamente impraticáveis, capazes de
      exigir um esforço técnico desproporcional ou que possa nos expor a riscos
      operacionais, como fraude em relação ao período de utilização gratuita. A
      Petbee pode reter informações conforme exigência ou permissão prevista em
      leis e regulamentos aplicáveis, inclusive para honrar suas escolhas, para
      fins de cobrança ou registros e para atender às finalidades descritas
      nesta Declaração de privacidade. A Petbee toma medidas razoáveis para
      destruir ou desidentificar informações pessoais de forma segura quando
      deixam de ser necessárias.
    </p>

    <h3>Segurança</h3>

    <p>
      A Petbee emprega medidas administrativas, lógicas, gerenciais e físicas
      razoáveis para proteger suas informações pessoais contra perdas, roubos e
      acesso, uso e alterações não autorizados. Essas medidas são elaboradas
      para oferecer um nível de segurança adequado aos riscos de processamento
      de suas informações pessoais.
    </p>

    <h3>Crianças</h3>

    <p>
      Você deve ter 18 anos ou mais para assinar o serviço Petbee. Em algumas
      jurisdições, a maioridade pode ser acima de 18 anos e, neste caso, você
      deve atender aos requisitos de idade para assinar o serviço Petbee. Apesar
      de indivíduos com menos de 18 anos poderem utilizar o serviço, eles só
      poderão fazê-lo com o envolvimento, a supervisão e aprovação dos pais ou
      responsáveis.
    </p>

    <h3>Alterações na presente Declaração de privacidade</h3>

    <p>
      A Petbee poderá periodicamente alterar esta Declaração de privacidade para
      atender a mudanças na legislação, exigências regulatórias ou operacionais.
      Comunicaremos qualquer alteração (inclusive a data em que as mesmas
      entrarão em vigor) conforme previsto por lei. O uso continuado por você do
      serviço Petbee depois que tais alterações passem a vigorar constituirá seu
      reconhecimento e (conforme o caso) a sua aceitação das mesmas. Caso você
      não queira reconhecer ou aceitar nenhuma das atualizações desta Declaração
      de privacidade, você poderá cancelar o serviço Petbee. Para saber quando
      essa Declaração de privacidade foi atualizada pela última vez, consulte a
      seção “Última atualização”, abaixo.
    </p>

    <h3>Cookies e publicidade na Internet</h3>

    <p>
      A Petbee e os nossos Prestadores de Serviços contratados utilizam cookies e
      outras tecnologias (tais como web beacons), assim como identificadores de
      aparelho redefiníveis, por diversos motivos. Por exemplo, utilizamos essas
      tecnologias para facilitar o seu acesso a nossos serviços, permitindo-nos
      reconhecer você a cada vez que você volta, e para fornecer e analisar
      nossos serviços. Também utilizamos cookies e identificadores de aparelho
      redefiníveis para aprender mais sobre nossos usuários e seus prováveis
      interesses e para personalizar e enviar mensagens de marketing ou
      publicidade. Nós queremos informá-lo do nosso uso dessas tecnologias.
      Assim, esta seção explica quais tipos de tecnologias utilizamos, sua
      função e suas opções em relação ao uso das mesmas.
    </p>

    <p>1. <strong>O que são cookies?</strong></p>

    <p>
      Cookies são pequenos arquivos de dados, armazenados normalmente no seu
      aparelho enquanto você navega e utiliza sites e serviços online. Cookies
      são bastante utilizados para que sites funcionem ou funcionem de forma
      mais eficiente, bem como para gerar informações de relatórios e ajudar na
      personalização do serviço ou publicidade.
    </p>

    <p>
      Os cookies não são os únicos tipos de tecnologias que permitem essa
      funcionalidade. Também usamos outros tipos de tecnologias semelhantes.
      Consulte os itens abaixo para obter mais informações e exemplos.
    </p>

    <p>
      2. <strong>O que são identificadores de aparelho redefiníveis?</strong>
    </p>

    <p>
      Os identificadores de aparelho redefiníveis (também conhecidos como
      identificadores de publicidade) são similares aos cookies e se encontram
      em muitos aparelhos móveis e tablets (por exemplo, o “Identificador de
      Publicidade” [ou IDFA] nos aparelhos Apple iOS e o “Código de publicidade
      da Google” em aparelhos Android) e em determinados aparelhos de streaming.
      Assim como os cookies, os identificadores de aparelho redefiníveis são
      utilizados para aumentar a relevância de anúncios online.
    </p>

    <p>
      3.
      <strong
        >Por que a Petbee usa cookies e identificadores de aparelho
        redefiníveis?</strong
      >
    </p>
    <ul>
      <li class="li-parent">
        <strong>Cookies essenciais:</strong> esses cookies são estritamente
        necessários para fornecer nosso serviço online ou website. Por exemplo,
        nós e nossos Prestadores de Serviços podemos usar esses cookies para
        autenticar e identificar nossos assinantes quando eles usam nossos sites
        e aplicativos e possamos fornecer serviços a eles. Os cookies também nos
        ajudam a garantir o cumprimento de nossos Termos de uso, prevenir
        fraudes e manter a segurança do nosso serviço.
      </li>
      <li class="li-parent">
        <strong>Cookies de desempenho e funcionalidade:</strong> esses cookies
        não são essenciais, mas nos ajudam a personalizar e aprimorar a sua
        experiência online com a Petbee. Por exemplo, eles nos ajudam a lembrar
        de suas preferências e evitar que você precise digitar informações que
        você já forneceu (por exemplo, durante a inscrição). Também usamos esses
        cookies para coletar informações (como páginas populares, taxas de
        conversão, padrões de visualização, número de cliques e outras
        informações) sobre o uso do serviço Petbee por nossos visitantes, para
        que possamos aprimorar e personalizar nosso site e nosso serviço e fazer
        pesquisas de mercado. A exclusão desses tipos de cookies poderá resultar
        em funcionalidade limitada do nosso serviço.
      </li>
      <li class="li-parent">
        <strong
          >Cookies de publicidade e identificadores de aparelho
          redefiníveis:</strong
        >
        esses cookies e identificadores de aparelho redefiníveis usam
        informações sobre a sua utilização deste e de outros sites e
        aplicativos, assim como sua resposta a anúncios e emails, para exibir
        anúncios mais relevantes a você. Esses tipos de anúncios são chamados de
        “anúncios baseados em interesse”. Muitos dos cookies de publicidade
        associados ao nosso serviço pertencem aos nossos Prestadores de
        Serviços.
      </li>
    </ul>

    <p>
      4. <strong>Como a Petbee usa web beacons e outras tecnologias?</strong>
    </p>

    <p>
      Os web beacons (também conhecidos como clear gifs ou pixel tags)
      normalmente funcionam em conjunto com os cookies. Nós e nossos Prestadores
      de Serviços podemos usá-los para fins semelhantes aos dos cookies, como
      para entender e aprimorar o uso do nosso serviço, melhorar o desempenho do
      site, monitorar as ações e o tráfego de visitantes no nosso site e
      entender as interações com o nosso marketing (incluindo emails e anúncios
      online em sites de terceiros). Como os web beacons normalmente funcionam
      em conjunto com os cookies, em vários casos, rejeitar os cookies impedirá
      também a eficácia dos web beacons associados a esses cookies.
    </p>

    <p>
      Usamos outras tecnologias semelhantes a cookies, como armazenamento de
      navegador e plugins (por exemplo, HTML5, IndexedDB e WebSQL). Como os
      cookies, algumas dessas tecnologias podem armazenar pequenas quantidades
      de dados em seu aparelho. Podemos usar essas e outras tecnologias para
      fins semelhantes aos dos cookies, como para fazer valer os nossos termos,
      evitar fraude e analisar o uso do serviço. Existem várias formas de
      exercer as suas escolhas em relação a essas tecnologias. Por exemplo,
      vários navegadores comuns fornecem a capacidade de eliminar o
      armazenamento do navegador, normalmente localizado na área de
      configurações ou preferências. Consulte a função de ajuda ou suporte do
      seu navegador para saber mais. Outras tecnologias, como armazenamento
      Silverlight, podem ser excluídas de dentro do aplicativo.
    </p>

    <p class="p-small">
      <strong>Última atualização:</strong> 10 de Fevereiro de 2020
    </p>
  </div>
</section>
