<section
  id="availability"
  *ngIf="isLoaded"
  class="availability-container"
  [@slideDown]>
  <!-- Side column. -->
  <div class="availability-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div
    class="availability-content availability-form"
    [ngClass]="{ 'clean-form': !zipCodeFound }">
    <div class="form-container" [@slideDown]>
      <!-- Logo. -->
      <!--<img class="img-logo" src="assets/images/petbee.png">-->

      <h1 style="font-style: italic">
        {{ 'REGISTER.AVAILABILITY.AVAILABILITY' | translate }}
      </h1>

      <p class="p-small">
        {{ 'REGISTER.AVAILABILITY.CHECK_CITY' | translate }}
      </p>

      <!-- Zip code. -->
      <div class="frm-element">
        <input
          class="frm-textbox"
          type="text"
          id="txt-zipcode"
          mask="00000-000"
          [(ngModel)]="zipCode"
          (keyup)="typingPostalCode($event)"
          [disabled]="isLoading || isLoadingPostalCode"
          appInputs />
        <span class="frm-label">{{
          'REGISTER.ELEMENTS.ZIP_CODE' | translate
        }}</span>
        <span *ngIf="isLoadingPostalCode" class="spn-spin-zipcode">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
      </div>
      <span *ngIf="errZipCode" class="spn-error">{{ errZipCode }}</span>

      <div *ngIf="zipCodeFound" class="check-result">
        <div class="not-available alert alert-danger alert-icon">
          <div>
            <i class="fas fa-sad-tear"></i>
          </div>
          <div>
            {{ 'REGISTER.AVAILABILITY.NOT_AVAILABLE' | translate }}
            {{ city }}/{{ state }}.
          </div>
        </div>

        <hr class="divider divider-vertical-fade" />

        <div>
          <p class="p-small">
            {{ 'REGISTER.AVAILABILITY.REGISTER_FOR_FREE' | translate }}
          </p>

          <!-- Register button. -->
          <div style="margin: 10px 0; text-align: center">
            <button
              type="button"
              class="btn btn-success btn-icon-label"
              (click)="goToRegister()">
              <span class="btn-inner--icon">
                <i class="fas fa-user-check"></i>
              </span>
              <span class="btn-inner--text">{{
                'REGISTER.OPERATIONS.CREATE_MY_ACCOUNT' | translate
              }}</span>
            </button>
          </div>
        </div>

        <hr class="divider divider-vertical-fade" />

        <div *ngIf="!alertSaved">
          <p class="p-small">
            {{ 'REGISTER.AVAILABILITY.REGISTER_FOR_ALERT' | translate }}
          </p>

          <!-- Receive alert button. -->
          <div style="margin: 10px 0; text-align: center">
            <button
              type="button"
              class="btn btn-secondary btn-icon-label"
              (click)="registerToReceiveAlert()">
              <span class="btn-inner--icon">
                <i class="fas fa-bell"></i>
              </span>
              <span class="btn-inner--text">{{
                'REGISTER.OPERATIONS.REGISTER_ALERT' | translate
              }}</span>
            </button>
          </div>
        </div>
      </div>

      <div style="font-size: small; margin-top: 30px; text-align: center">
        <button
          class="btn btn-link btn-link-thin"
          (click)="exitPage($event, '/login')">
          {{ 'LOGIN.QUESTIONS.ALREADY_REGISTERED' | translate }}
          {{ 'LOGIN.ELEMENTS.DO_LOG_IN' | translate }}
        </button>
        <!--<a href="/login" role="button">
          {{ 'LOGIN.QUESTIONS.ALREADY_REGISTERED' | translate }}
          {{ 'LOGIN.ELEMENTS.DO_LOG_IN' | translate }}
        </a>-->
      </div>
    </div>
    <!-- [CONTAINER: END] -->
  </div>
  <!-- [CONTENT: END] -->

  <!-- Empty column. -->
  <div class="login-content lg-b"></div>
</section>

<!-- Alerts. -->
<app-alerts id="alert-reg-av" style="display: none"></app-alerts>
