<section
  id="reg-notification"
  *ngIf="isLoaded"
  class="notification-container"
  [@slideDown]>
  <!-- Side column. -->
  <div class="notification-content lg-a">
    <!-- Logo. -->
    <img class="img-logo" src="assets/images/petbee.png" (click)="goToHome()" />
  </div>

  <!-- [CONTENT: START] -->
  <div class="notification-content notification-form">
    <!-- [FORM: START]-->
    <div class="form-container" [@slideDown]>
      <h1 style="font-style: italic">Alerta de disponibilidade</h1>

      <p>
        Informe seus dados no campo abaixo para receber uma notificação quando o
        serviço estiver disponível em sua cidade.
      </p>

      <div *ngIf="!alertSaved">
        <!-- Name. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-name"
            [(ngModel)]="userName"
            [disabled]="isSaving"
            (focusout)="validateName($event)"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.NAME' | translate
          }}</span>
        </div>
        <span *ngIf="errUserName" class="spn-error">{{ errUserName }}</span>

        <!-- E-Mail. -->
        <div class="frm-element">
          <input
            class="frm-textbox"
            type="email"
            id="txt-email"
            [(ngModel)]="userEmail"
            [disabled]="isSaving"
            (focusout)="validateEmail($event)"
            appInputs />
          <span class="frm-label">{{
            'REGISTER.ELEMENTS.EMAIL' | translate
          }}</span>
        </div>
        <span *ngIf="errUserEmail" class="spn-error">{{ errUserEmail }}</span>

        <!-- Phone. -->
        <!--<div class="frm-element">
          <input
            class="frm-textbox"
            type="text"
            id="txt-phone"
            [(ngModel)]="userPhone"
            [disabled]="isSaving"
            appInputs
          >
          <span class="frm-label">{{ 'REGISTER.ELEMENTS.PHONE' | translate }}</span>
        </div>
        <span *ngIf="errUserPhone" class="spn-error">{{errUserPhone}}</span>-->

        <div style="display: grid; grid-template-columns: 35% 65%">
          <!-- Zip code. -->
          <div class="frm-element">
            <input
              class="frm-textbox el-has-value"
              type="text"
              id="txt-zipcode"
              mask="00000-000"
              [(ngModel)]="zipCode"
              (keyup)="typingPostalCode($event)"
              [disabled]="isLoadingPostalCode"
              (focusout)="validateZipCode($event)"
              appInputs />
            <span class="frm-label">{{
              'REGISTER.ELEMENTS.ZIP_CODE' | translate
            }}</span>
            <span *ngIf="isLoadingPostalCode" class="spn-spin-zipcode">
              <i class="fas fa-spinner fa-spin"></i>
            </span>
          </div>

          <!-- City. -->
          <div class="frm-element" style="padding-left: 5px">
            <input
              class="frm-textbox el-has-value"
              type="text"
              id="txt-city"
              [(ngModel)]="city"
              disabled
              appInputs />
            <span class="frm-label">{{
              'REGISTER.ELEMENTS.ADDRESS.CITY' | translate
            }}</span>
          </div>
        </div>

        <span *ngIf="errZipCode" class="spn-error">{{ errZipCode }}</span>

        <!-- Receive alert button. -->
        <div style="margin: 10px 0; text-align: center">
          <button
            type="button"
            class="btn btn-secondary btn-icon-label"
            (click)="register()">
            <span class="btn-inner--icon">
              <i class="fas fa-bell"></i>
            </span>
            <span *ngIf="!isSaving" class="btn-inner--text"
              >Registrar alerta</span
            >
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>
      </div>

      <div
        *ngIf="alertSaved"
        class="alert alert-success alert-icon"
        [@slideDown]>
        <div>
          <i class="fas fa-smile-wink"></i>
        </div>
        <div>
          Registramos seus dados. Assim que o serviço estiver em funcionamento
          em {{ city }} lhe enviaremos um aviso.
        </div>
      </div>

      <!-- Divider. -->
      <hr class="divider" />

      <!-- Footer links. -->
      <div class="footer-links">
        <div class="login">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/login')">
            {{ 'LOGIN.ELEMENTS.SIGN_IN' | translate }}
          </button>
        </div>

        <div class="new-account" style="text-align: right">
          <button
            class="btn btn-link btn-link-thin"
            (click)="exitPage($event, '/register/availability')">
            {{ 'LOGIN.ELEMENTS.REGISTER' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- [FORM: END]-->
  </div>
  <!-- [CONTENT: END] -->

  <!-- Empty column. -->
  <div class="login-content lg-b"></div>
</section>
