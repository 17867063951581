// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App models.
import { ICountryLanguage, IAPIResponse } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

// App environment.
import { environment } from '@env/environment';

// Main endpoint.
const endpoint: string = environment.urls.api;
const endpointCountries: string = endpoint + 'countries/';
const endpointCounLang: string = endpoint + 'countries-languages/';

@Injectable({ providedIn: 'root' })
export class CountryService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all countries.
  getCountries(): Observable<IAPIResponse> {
    const lang: string = this.translate.currentLang;
    return this.http.get<IAPIResponse>(endpointCounLang + 'language/' + lang, {
      headers: this.headers,
    });
  }
}
