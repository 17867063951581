// Angular modules.
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt');

// App directives.
import { InputsDirective } from './directives/inputs/inputs.directive';

// App pipes.
import { PhonePipe } from './pipes/phone/phone.pipe';
import { ZCurrencyPipe } from './pipes/zcurrency/zcurrency.pipe';
import { ZipcodePipe } from './pipes/zipcode/zipcode.pipe';

@NgModule({
  declarations: [
    // App directives.
    InputsDirective,

    // App pipes.
    PhonePipe,
    ZCurrencyPipe,
    ZipcodePipe,
  ],
  exports: [
    // App directives.
    InputsDirective,

    // App pipes.
    PhonePipe,
    ZCurrencyPipe,
    ZipcodePipe,
  ],
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    /*if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }*/
  }
}
