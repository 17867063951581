import {
  animate,
  keyframes,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Fade in/out animation.
export const mainSidebarAnimations: any = [
  trigger('fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('1000ms ease-in-out', style({ opacity: 0.5 })),
      animate('13000ms ease-in-out', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5 })),
      animate('800ms ease-in-out', style({ opacity: 0 })),
    ]),
  ]),
  trigger('slideUpDown', [
    transition(':enter', [
      style({ opacity: 0, marginTop: '-100%' }),
      // animate('500ms ease-in-out', style({opacity: 0.5, marginTop: '-50%'})),
      animate('500ms ease-in-out', style({ opacity: 1, marginTop: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5, marginTop: '-50%' })),
      animate('800ms ease-in-out', style({ opacity: 0, marginTop: '-100%' })),
    ]),
  ]),
];
