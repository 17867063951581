<section class="modal-container" [@slideUpDown]>
  <div class="view-modal">
    <!-- Close modal. -->
    <i class="fas fa-times" (click)="closeModal(false)"></i>

    <!-- Title. -->
    <div class="modal-title">
      {{ isLoading ? 'Carregando...' : (selHuman.fullName | uppercase) }}
    </div>

    <!-- [CONTENT: START] -->
    <div *ngIf="!isLoading" class="modal-content">
      <div class="res-container res-item">
        <!-- [SIDEBAR: START] -->
        <div class="res-sidebar res-item-container">
          <img src="{{ humanAvatar }}" class="human-avatar" />

          <hr class="divider divider-fade" />

          <p class="created-at">
            Desde {{ selHuman.createdAt | date : 'dd/MM/yyyy' }}
          </p>

          <button
            type="button"
            class="btn btn-sm btn-secondary btn-icon-label"
            [ngClass]="{
              'btn-secondary': !selHuman.blocked,
              'btn-danger': selHuman.blocked
            }"
            (click)="lockUser()"
            [disabled]="isSaving">
            <span class="btn-inner--icon">
              <i class="fas fa-lock"></i>
            </span>
            <span *ngIf="!isSaving" class="btn-inner--text">{{
              selHuman.blocked ? 'Desbloquear' : 'Bloquear'
            }}</span>
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>
        <!-- [SIDEBAR: END] -->

        <!-- [DYNAMIC CONTENT: START] -->
        <div class="res-content">
          <!-- Navigation. -->
          <ul class="nav nav-tabs">
            <li class="nav-item" (click)="changeTab('about')">
              <a class="nav-link" [ngClass]="{ active: activeTab === 'about' }">
                {{ 'HUMANS.TABS.ABOUT' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('address')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'address' }">
                {{ 'HUMANS.TABS.ADDRESS' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('pets')">
              <a class="nav-link" [ngClass]="{ active: activeTab === 'pets' }">
                {{ 'HUMANS.TABS.PETS' | translate }}
              </a>
            </li>
            <li class="nav-item" (click)="changeTab('subscriptions')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'subscriptions' }">
                {{ 'HUMANS.TABS.SUBSCRIPTIONS' | translate }}
              </a>
            </li>
            <li
              *ngIf="human.isAdmin"
              class="nav-item"
              (click)="changeTab('password')">
              <a
                class="nav-link"
                [ngClass]="{ active: activeTab === 'password' }">
                {{ 'HUMANS.TABS.PASSWORD' | translate }}
              </a>
            </li>
          </ul>

          <!-- Address. -->
          <app-vh-address
            *ngIf="activeTab === 'address'"
            [selHuman]="selHuman"></app-vh-address>

          <!-- Pets. -->
          <app-vh-pets
            *ngIf="activeTab === 'pets'"
            [selHuman]="selHuman"></app-vh-pets>

          <!-- Subscriptions. -->
          <app-vh-subscriptions
            *ngIf="activeTab === 'subscriptions'"
            [selHuman]="selHuman"></app-vh-subscriptions>

          <!-- Resume. -->
          <app-vh-resume
            *ngIf="activeTab === 'about'"
            [selHuman]="selHuman"></app-vh-resume>

          <!-- Password. -->
          <app-vh-password
            *ngIf="human.isAdmin && activeTab === 'password'"
            [selHuman]="selHuman"></app-vh-password>
        </div>
        <!-- [DYNAMIC CONTENT: END] -->
      </div>
    </div>
    <!-- [CONTENT: END] -->
  </div>
</section>
