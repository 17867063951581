// Angular modules.
import { Component } from '@angular/core';

// App animations.
import { fullLoaderAnimation } from '@app/core/animations';

@Component({
  selector: 'app-full-page-loader',
  templateUrl: './full-page-loader.component.html',
  styleUrls: ['./full-page-loader.component.scss'],
  animations: [fullLoaderAnimation],
})
export class FullPageLoaderComponent {
  // Constructor method.
  constructor() {}
}
