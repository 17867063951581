// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast } from '@app/core/models';

// App services.
import { BroadcastService, MobileService } from '@app/core/services';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  animations: [slideBottomAnimation],
})
export class AgreementComponent implements OnInit {
  // Event emmiters.
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoaded: boolean = true;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private mobileService: MobileService
  ) {
    // Listen to broadcast events.
    this.listenToBroadcast();
  }

  // On init.
  public ngOnInit(): void {}

  // Close this modal.
  public closeWindow(event?: any): void {
    this.isLoaded = false;
    setTimeout(() => {
      this.closeModal.emit();
    }, 300);
  }

  // Listen to broadcast events.
  private listenToBroadcast(): void {
    this.broadcastService.events.subscribe(ev => {
      switch (ev.key) {
        // Window resized.
        case EBroadcast.WindowResized:
          this.isMobile = this.mobileService.isMobile();
          break;
      }
    });
  }
}
