import { IHuman } from '@app/core/models';
import { ISubscription } from './subscription.model';

// Interface for Pets model.
export interface IPet {
  chip?: string;
  createdAt: string;
  family: IPetFamily;
  familyId?: number;
  gender: string;
  id?: number;
  name: string;
  humans?: IHuman[];
  picture?: string;
  subscription?: ISubscription;
  updatedAt?: string;
  diedAt?: string;
  birthday?: string;
  year?: number;
}

// Interface for Pets-Humans model.
export interface IPetHuman {
  createdAt: string;
  id?: number;
  humanId: number;
  petId: number;
  updatedAt?: string;
}

// Interface for pet families model.
export interface IPetFamily {
  blocked?: boolean;
  code: EPetFamily;
  createdAt: string;
  deletedAt?: string;
  id?: number;
  name: string;
  languageId: number;
  updatedAt?: string;
}

// Enumerator for pet families.
export enum EPetFamily {
  Cat = 'Cat',
  Dog = 'Dog',
}
