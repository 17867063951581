// Angular modules.
import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// Interface.
import { IHumanLocalData, IPet } from '@app/core';

// Services.
import {
  AuthService,
  ErrorService,
  PetService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-view-pet',
  templateUrl: './view-pet.component.html',
  styleUrls: ['./view-pet.component.scss'],
  animations: [modalAnimation],
})
export class ViewPetComponent implements OnInit {
  // Outputs.
  @Output() finished: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

  // Pet's data.
  @Input() pet: IPet;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Pet's data.
  public petAvatar: string;

  // Status.
  public isLoading: boolean = true;
  public isSaving: boolean = false;
  public activeTab: string = 'about';

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    public petService: PetService,
    private translate: TranslateService,
    private router: Router,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get pet's avatar.
    this.petAvatar = this.petService.getAvatar(this.pet);
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  }

  // Change active tab.
  public changeTab(tab: string): void {
    this.activeTab = tab.toLowerCase();
  }

  // Close modal window.
  public closeModal(forceReload: boolean = false): void {
    this.finished.emit(forceReload);
  }

  // When a subscription is updated.
  public subscriptionUpdate(p: IPet): void {
    this.isLoading = true;
    this.pet = p;
    this.updateList.emit();
    this.isLoading = false;
  }

  // Delete a pet.
  public deletePet(petDel: IPet): void {
    if (petDel.subscription && petDel.subscription.planId !== 1) {
      this.utilsService.showAlert(
        'my-pet',
        this.errorService.manageError(
          'Para excluir um pet com assinatura diferente da básica você deve cancelar a assinatura primeiro.',
          this.constructor.name,
          'deletePet()'
        ),
        EAlertType.Error
      );

      return;
    }

    if (confirm('Confirma a exclusão do pet?')) {
      this.isSaving = true;
      this.petService
        .deletePet(petDel)
        .then(res => {
          this.utilsService.showAlert(
            'my-pet',
            this.errorService.manageError(
              'Pet excluído com sucesso',
              this.constructor.name,
              'deletePet() > petService.deletePet()'
            ),
            EAlertType.Success
          );
          this.router.navigate(['/admin/pets']);
        })
        .catch(err => {
          this.utilsService.showAlert(
            'my-pet',
            this.errorService.manageError(
              err,
              this.constructor.name,
              'deletePet() > petService.deletePet()'
            ),
            EAlertType.Error
          );
        })
        .finally(() => (this.isSaving = false));
    }
  }
}
