// Angular modules.
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App interfaces.
import { IHuman, IPet } from '@app/core/models';

// App services.
import {
  ErrorService,
  MobileService,
  PetService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-vh-pets',
  templateUrl: './vh-pets.component.html',
  styleUrls: ['./vh-pets.component.scss'],
  animations: [pageAnimations],
})
export class VhPetsComponent implements OnInit {
  // Selected human's data.
  @Input() selHuman: IHuman;
  public pets: IPet[] = [];

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoading: boolean = true;

  // Constuctor method.
  constructor(
    private errorService: ErrorService,
    private mobileService: MobileService,
    public petService: PetService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get human's pets.
    this.getPets();
  }

  // Go to pet's page.
  public goToPet(pet: IPet): void {
    // this.router.navigate(['my-pet/' + pet.id]);
    window.open('my-pet/' + pet.id, '_blank');
  }

  public goToPlans(pet: IPet): void {
    if (!pet.subscription || !pet.subscription.id) {
      this.router.navigate(['my-plans'], { queryParams: { pet: pet.id } });
    }
  }

  // Get human's pets.
  private getPets(): void {
    this.isLoading = true;

    this.petService
      .getMyPets(this.selHuman.id)
      .then(res => (this.pets = res.rows as IPet[]))
      .catch(err => {
        this.utilsService.showAlert(
          'vh-pets',
          this.errorService.manageError(
            err,
            this.constructor.name,
            'getPets() > petService.getMyPets()'
          ),
          EAlertType.Error
        );
      })
      .finally(() => (this.isLoading = false));
  }
}
