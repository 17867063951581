<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <div *ngIf="pet.updatedAt" class="last-update">
    {{ 'GENERIC.LAST_UPDATE' | translate }}:
    {{ pet.updatedAt | date : 'dd/MM/yyyy hh:mm' }}
  </div>

  <div class="row">
    <!-- Species. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'PETS.SPECIE' | translate }}:</label>
      <div class="div-item">{{ petFamily || '---' }}</div>
    </div>

    <!-- Gender. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'PETS.GENDER' | translate }}:</label>
      <div *ngIf="pet.gender === 'F'" class="div-item">
        {{ 'GENERIC.GENDERS.FEMALE' | translate }}
      </div>
      <div *ngIf="pet.gender === 'M'" class="div-item">
        {{ 'GENERIC.GENDERS.MALE' | translate }}
      </div>
    </div>

    <!-- Chip. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'PETS.CHIP' | translate }}:</label>
      <div class="div-item">{{ chipNumber }}</div>
    </div>
  </div>

  <div class="row">
    <!-- Pet's name. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'PETS.ATTRIBUTES.NAME' | translate }}</label>
      <div content="notranslate" class="div-item notranslate">
        {{ pet.name }}
      </div>
    </div>

    <!-- Year. -->
    <div class="frm-element frm-el-inline">
      <label>{{ 'PETS.ATTRIBUTES.BIRTHDAY' | translate }}</label>
      <div class="div-item">
        {{ pet.birthday ? (pet.birthday | date : 'dd/MM/yyyy') : '---' }}
      </div>
    </div>
  </div>
</div>

<!-- Loader. -->
<div *ngIf="isLoading" style="margin: 30px 0">
  <app-horizontal-loader></app-horizontal-loader>
</div>
