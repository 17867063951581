// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// Environment.
import { environment } from '@env/environment';

// Interfaces.
import {
  IAPIResponse,
  IHumanLocalData,
  IClinic,
  INewTreatmentsRequest,
  INewTreatmentsItem,
  ITreatmentsItem,
  IUpdateTreatmentsItem,
  ICancelTreatmentsItem,
  TreatmentStatusMap,
  ETreatmentRequestStatusCode,
  ITreatmentsRequestUpdate,
  ITreatment,
} from '@app/core';

// Services.
import { AuthService } from '@app/core/services/auth/auth.service';

// Endpoints.
const endpoint: string = environment.urls.api;
const epMain: string = endpoint + 'treatments/';
const epRequest: string = endpoint + 'treatments-requests/';
const epRequestItem: string = endpoint + 'treatments-requests-items/';
const epRequestStatus: string = endpoint + 'treatments-requests-status/';

@Injectable({ providedIn: 'root' })
export class TreatmentsService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Treatment Status Mapping
  private treatmentStatusMap: TreatmentStatusMap = {
    pending: 1,
    approved: 2,
    completed: 3,
    refused: 4,
    canceled: 5,
  };

  // Constructor method.
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all available treatments for a petId.
  public getByPet(petId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epMain + 'pet/' + petId, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get clinic requests.
  public getClinicRequests(
    clinic: IClinic,
    status: number | null
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const url = `${epRequest}clinic/${clinic.id}${
      status !== null ? `?status=${status}` : ''
    }`;

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(url, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new treatment request.
  public createNewRequest(req: INewTreatmentsRequest): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epRequest, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get pet treatment request active today.
  public getPetTreatmentRequestActiveToday(
    petId: number,
    clinicId: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(
          epRequest + 'clinic/' + clinicId + '/pet/' + petId + '/active',
          {
            headers: this.headers,
          }
        )
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get treatment request.
  public getTreatmentRequest(requestId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epRequest + requestId, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update an existing treatment request.
  public updateRequest(req: ITreatmentsRequestUpdate): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epRequest + req.id, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new treatment request item.
  public createNewRequestItem(req: INewTreatmentsItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epRequestItem, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update an existing treatment request item.
  public updateRequestItem(req: IUpdateTreatmentsItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epRequestItem + req.id, JSON.stringify(req), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Delete a request item.
  public deleteRequestItem(ri: ICancelTreatmentsItem): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(epRequestItem + ri.id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get status.
  public getStatus(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epRequestStatus, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Filter request items.
  public filterRequestItems(
    status: number | null,
    from: number,
    to: number
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const url = `${epRequest}filter?${
      status !== null ? `status=${status}&` : ''
    }from=${from}&to=${to}`;

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http.get<IAPIResponse>(url, { headers: this.headers }).subscribe(
        res => resolve(res),
        err => reject(err)
      );
    });

    return promise;
  }

  // Convert status name to id
  public convertTreatmentStatusToID: (
    status: ETreatmentRequestStatusCode
  ) => number | undefined = status => this.treatmentStatusMap[status];

  // Get request status.
  public getRequestStatus(s: string): string {
    switch (s) {
      case 'pending':
        return 'Aguardando aprovação';
      case 'approved':
        return 'Aprovado';
      case 'completed':
        return 'Finalizado';
      case 'refused':
        return 'Recusado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Aguardando aprovação';
    }
  }

  /*
   * Region: Old Methods
   */

  // Filter items by period.
  public getByPeriod(start: number, end: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(
          epRequestItem +
            'reports/filter-period?start=' +
            start +
            '&end=' +
            end,
          { headers: this.headers }
        )
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get people joined to assessments.
  public getAssessmentCount(start: number, end: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(
          epRequestItem +
            'reports/assessment-count?start=' +
            start +
            '&end=' +
            end,
          { headers: this.headers }
        )
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  public getItemsByPet(petId: number): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(epRequestItem + 'pet-items/' + petId, {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Create new treatment.
  public createNew(treatment: ITreatment): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(epMain, JSON.stringify(treatment), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Update a treatment.
  public update(treatment: ITreatment): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(epMain + treatment.id, JSON.stringify(treatment), {
          headers: this.headers,
        })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Delete a treatment.
  public delete(treatment: ITreatment): Promise<IAPIResponse> {
    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(epMain + treatment.id, { headers: this.headers })
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

    return promise;
  }

  // Get price per human (assessment).
  public getPricePerHuman(
    periodCost: number,
    assessmentCount: number,
    useMaxValue: boolean = true
  ): number {
    const maxValue: number = environment.apportionment.maxValue;
    const finalValue: number = periodCost / assessmentCount;
    if (useMaxValue) {
      return finalValue > maxValue ? maxValue : finalValue;
    } else {
      return finalValue;
    }
  }

  // Get all available treatments.
  public getAll(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(
          epMain + 'country/' + this.human.location.countryId,
          { headers: this.headers }
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }
}
