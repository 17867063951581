// Angular modules.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

// Angular components.
import { AppComponent } from './app.component';

// Third-party modules.
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Routing.
import { AppRoutingModule } from './app-routing.module';

// Global error handler.
import { GlobalErrorHandler } from '@app/core';

// App interceptors.
import { CacheInterceptor } from '@app/core';

// App modules.
import { CoreModule } from '@app/core';
import { LoginModule } from '@app/login';
import { NotFoundModule } from '@app/not-found';
import { PartnersModule } from '@app/partners';
import { RegisterModule } from '@app/register';
import { RememberPasswordModule } from '@app/remember-password';
import { ResetPasswordModule } from '@app/reset-password';
import { SharedModule } from '@app/shared';

// TRANSLATE:
// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http);

// NgxMask related.
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

// NG2-Tooltip related.
export const ng2TooltipOptions: TooltipOptions = {
  'show-delay': 300,
};

@NgModule({
  declarations: [
    // App components.
    AppComponent,
  ],
  exports: [
    // App modules.
    CoreModule,
  ],
  imports: [
    // Angular modules.
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,

    // App modules.
    AppRoutingModule,

    // Third-party.
    NgxMaskModule.forRoot(options),
    TooltipModule.forRoot(ng2TooltipOptions as TooltipOptions),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),

    // App modules.
    CoreModule,
    LoginModule,
    NotFoundModule,
    PartnersModule,
    RegisterModule,
    RememberPasswordModule,
    ResetPasswordModule,
    SharedModule,

    // App directives.
    // InputsDirective
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: 'pt' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
