// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import {} from '@app/core/models';

// App interfaces.
import { IClinicPet, IHuman, IHumanLocalData, IPet } from '@app/core/models';

// App services.
import { AuthService, HumanService, PetService } from '@app/core/services';

@Component({
  selector: 'app-vp-owners',
  templateUrl: './vp-owners.component.html',
  styleUrls: ['./vp-owners.component.scss'],
  animations: [pageAnimations],
})
export class VpOwnersComponent implements OnInit {
  // Pet.
  @Input() pet: IPet;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  // Constuctor method.
  constructor(
    private authService: AuthService,
    private humanService: HumanService,
    private petService: PetService,
    private translate: TranslateService
  ) {}

  // On init.
  public ngOnInit(): void {}

  // Get human's picture.
  public getHumanAvatar(h: IHuman): string {
    // return this.humanService.getHumanPicture(h);
    return this.authService.getGenericPicture();
  }
}
