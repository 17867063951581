// Angular modules.
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { slideAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast } from '@app/core/models';

// App interfaces.
import {} from '@app/core/models';

// App services.
import { BroadcastService, MobileService } from '@app/core/services';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [slideAnimation],
})
export class NotFoundComponent implements OnInit {
  // Status.
  public showContent: boolean = false;
  public isMobile: boolean = this.mobileService.isMobile();

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private router: Router,
    private mobileService: MobileService,
    private translate: TranslateService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        'not-found'
      );
      setTimeout(() => {
        this.showContent = true;
      }, 200);
    }, 1000);
  }

  // Go to another page.
  public goToPage(url: string = ''): void {
    this.router.navigate([url]);
  }
}
