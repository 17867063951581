// Angular modules.
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

// Third-party.
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// App modules.
import { CoreModule } from '@app/core';

// Module components (alerts).
import { AlertsComponent } from './alerts/alerts.component';

// Module components (modals).
import { AgreementComponent } from './modals/agreement/agreement.component';
import { AgreementPartnersComponent } from './modals/agreement-partners/agreement-partners.component';
import { ChangeAvatarComponent } from './modals/change-avatar/change-avatar.component';
import { ChangeCardComponent } from './modals/change-card/change-card.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { ClinicPetRegisterComponent } from './modals/clinic-pet-register/clinic-pet-register.component';
import { PetRegisterComponent } from './modals/pet-register/pet-register.component';
import { TermsofuseComponent } from './modals/termsofuse/termsofuse.component';
import { VhAddressComponent } from './modals/view-human/vh-address/vh-address.component';
import { VhResumeComponent } from './modals/view-human/vh-resume/vh-resume.component';
import { VhPetsComponent } from './modals/view-human/vh-pets/vh-pets.component';
import { ViewHumanComponent } from './modals/view-human/view-human.component';
import { ViewPetComponent } from './modals/view-pet/view-pet.component';
import { VpOwnersComponent } from './modals/view-pet/vp-owners/vp-owners.component';
import { VpResumeComponent } from './modals/view-pet/vp-resume/vp-resume.component';
import { VpSubscriptionComponent } from './modals/view-pet/vp-subscription/vp-subscription.component';
import { VpTreatmentsComponent } from './modals/view-pet/vp-treatments/vp-treatments.component';
import { McVpRegisterResumeComponent } from './modals/clinic-pet-register/mc-register-resume/mc-register-resume.component';

// Module components (loaders).
import { FullPageLoaderComponent } from './loaders/full-page-loader/full-page-loader.component';
import { HorizontalLoaderComponent } from './loaders/horizontal-loader/horizontal-loader.component';

// Module components (mobile).
import { HeaderMobileComponent } from './mobile/header-mobile/header-mobile.component';
import { MenuMobileComponent } from './mobile/menu-mobile/menu-mobile.component';
import { SecMenuMobileComponent } from './mobile/sec-menu-mobile/sec-menu-mobile.component';

// Module components (sidebar).
import { MainSidebarComponent } from './sidebars/main-sidebar/main-sidebar.component';
import { SecondSidebarComponent } from './sidebars/second-sidebar/second-sidebar.component';
import { VhSubscriptionsComponent } from './modals/view-human/vh-subscriptions/vh-subscriptions.component';
import { VhPasswordComponent } from './modals/view-human/vh-password/vh-password.component';

// @ngx-translate: AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http);

// NgxMask related.
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

// NG2-Tooltip related.
export const ng2TooltipOptions: TooltipOptions = {
  'show-delay': 300,
  'tooltip-class': 'tooltip',
};

@NgModule({
  declarations: [
    // Alerts.
    AlertsComponent,

    // Modals.
    AgreementComponent,
    AgreementPartnersComponent,
    ChangeAvatarComponent,
    ChangeCardComponent,
    ChangePasswordComponent,
    ClinicPetRegisterComponent,
    PetRegisterComponent,
    TermsofuseComponent,
    VhAddressComponent,
    VhResumeComponent,
    VhPetsComponent,
    ViewHumanComponent,
    ViewPetComponent,
    VpOwnersComponent,
    VpResumeComponent,
    VpSubscriptionComponent,
    VpTreatmentsComponent,
    McVpRegisterResumeComponent,

    // Loaders.
    FullPageLoaderComponent,
    HorizontalLoaderComponent,

    // Mobile.
    HeaderMobileComponent,
    MenuMobileComponent,
    SecMenuMobileComponent,

    // Sidebar
    MainSidebarComponent,
    SecondSidebarComponent,
    VhSubscriptionsComponent,
    VhPasswordComponent,
  ],
  exports: [
    // Alerts.
    AlertsComponent,

    AgreementComponent,
    AgreementPartnersComponent,
    ChangeAvatarComponent,
    ChangeCardComponent,
    ChangePasswordComponent,
    ClinicPetRegisterComponent,
    FullPageLoaderComponent,
    HorizontalLoaderComponent,
    PetRegisterComponent,
    TermsofuseComponent,
    ViewHumanComponent,
    ViewPetComponent,

    // Mobile.
    HeaderMobileComponent,
    MenuMobileComponent,
    SecMenuMobileComponent,

    // Sidebars.
    MainSidebarComponent,
    SecondSidebarComponent,
  ],
  imports: [
    // Angular modules.
    // BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,

    // Third-party.
    ImageCropperModule,
    NgxMaskModule.forChild(options),
    TooltipModule.forRoot(ng2TooltipOptions as TooltipOptions),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // App modules
    CoreModule,
  ],
})
export class SharedModule {
  constructor(@Optional() @SkipSelf() parentModule: SharedModule) {
    /*if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }*/
  }
}
