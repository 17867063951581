<section class="mob-menu-container" [@slideAndFade]>
  <!-- Close icon. -->
  <i
    id="i-close-mob-menu"
    class="fas fa-times-circle"
    (click)="closeMenu()"></i>

  <!-- Menu items. -->
  <ul>
    <li
      *ngFor="let m of menu"
      [ngClass]="{ 'mnu-active': m.active }"
      (click)="goToPage(m.url)">
      <div class="li-mnu-i"><i class="{{ m.icon }}"></i></div>
      <div class="li-mnu-title">{{ m.title }}</div>
    </li>
  </ul>

  <!-- Development flag. -->
  <div *ngIf="!isProd" class="dev-flag">
    <i class="fas fa-code"></i>
    Desenvolvimento
  </div>
</section>
