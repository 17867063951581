// Angular modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Module components.
import { LoginComponent } from '@app/login/login.component';

// Routes.
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: {
      title: 'Petbee - Identificação de usuário',
      description: 'Faça o login para ter acesso ao site.',
      ogUrl: 'http:/www.petbee.com.br',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
