// Angular modules.
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Third-party.
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Module routing.
import { RememberPasswordRoutingModule } from './remember-password-routing.module';

// Module components.
import { RememberPasswordComponent } from '@app/remember-password/remember-password.component';

// App modules.
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';

// @ngx-translate: AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http);

@NgModule({
  declarations: [
    // App components.
    RememberPasswordComponent,
  ],
  exports: [
    // App routing.
    RememberPasswordRoutingModule,

    // App components.
    RememberPasswordComponent,
  ],
  imports: [
    // Angular modules.
    CommonModule,
    FormsModule,

    // Third-party.
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Routing modules.
    RememberPasswordRoutingModule,

    // App modules
    CoreModule,
    SharedModule,
  ],
})
export class RememberPasswordModule {}
