// Angular modules.
import { Component, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast, EAlertType } from '@app/core/models';

// App interfaces.
import { IAlert, IHumanQueue } from '@app/core/models';

// App services.
import {
  BroadcastService,
  ErrorService,
  HumanService,
  MobileService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-register-start',
  templateUrl: './register-start.component.html',
  styleUrls: ['./register-start.component.scss'],
  animations: [slideBottomAnimation],
})
export class RegisterStartComponent implements OnInit {
  // Form.
  public newCity: string;
  public newEmail: string;
  public newName: string;
  public newPhone: string;

  // Errors.
  public errCity: string;
  public errEmail: string;
  public errName: string;
  public errPhone: string;

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();
  public isLoading: boolean = false;
  public isLoaded: boolean = false;
  public isSaving: boolean = false;
  public saved: boolean = false;

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private errorService: ErrorService,
    private humanService: HumanService,
    private mobileService: MobileService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Hide full loader.
    setTimeout(() => {
      this.broadcastService.sendBroadcast(
        EBroadcast.FullLoaderOff,
        '/register'
      );
      this.isLoaded = true;
      if (!this.isMobile) {
        setTimeout(() => {
          document.getElementById('txt-name') &&
            document.getElementById('txt-name').focus();
        }, 500);
      }
    }, 500);

    window.scroll(0, 0);
  }

  // Redirect to home page.
  public goToHome(): void {
    window.location.href = environment.urls.site;
  }

  // Save data.
  public save(): void {
    if (this.validateForm()) {
      this.isSaving = false;

      const queueData: IHumanQueue = {
        city: this.newCity,
        email: this.newEmail,
        name: this.newName,
        phone: this.newPhone,
      };

      this.humanService
        .registerQueue(queueData)
        .then(res => {
          this.utilsService.showAlert(
            'reg-queue',
            'Solicitação enviada com sucesso!',
            EAlertType.Success
          );
          this.saved = true;
        })
        .catch(err => {
          this.utilsService.showAlert(
            'reg-queue',
            this.errorService.manageError(err),
            EAlertType.Error
          );
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  // Validate form.
  private validateForm(): boolean {
    let isValid: boolean = true;
    this.clearErrors();

    if (!this.newName) {
      this.errName = this.translate.instant('REGISTER.ERRORS.NAME_REQUIRED');
      isValid = false;
    }
    if (!this.newEmail) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_REQUIRED');
      isValid = false;
    }
    if (!this.newPhone) {
      this.errPhone = this.translate.instant('REGISTER.ERRORS.PHONE_REQUIRED');
      isValid = false;
    }
    if (!this.newCity) {
      this.errCity = this.translate.instant(
        'REGISTER.ERRORS.ADDRESS_CITY_REQUIRED'
      );
      isValid = false;
    }

    if (isValid && !this.utilsService.validateEmail(this.newEmail)) {
      this.errEmail = this.translate.instant('REGISTER.ERRORS.EMAIL_INVALID');
      isValid = false;
    }

    return isValid;
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errCity = null;
    this.errEmail = null;
    this.errName = null;
    this.errPhone = null;
  }
}
