// Main module.
export * from '@app/core/core.module';

// Module's children.
export * from '@app/core/animations';
export * from '@app/core/directives';
export * from '@app/core/error';
export * from '@app/core/interceptors';
export * from '@app/core/models';
export * from '@app/core/pipes';
// export * from '@app/core/services';
