<section
  class="modal-container"
  (keyup.enter)="nextStep()"
  (keyup.arrowright)="nextStep()"
  (keyup.arrowleft)="previousStep()"
  [@slideUpDown]>
  <div class="pet-register-modal">
    <!-- Close modal. -->
    <i class="fas fa-times" (click)="closeModal(false)"></i>

    <!-- Title. -->
    <div class="modal-title">{{ pageTitle }}</div>

    <!-- [CONTENT: START] -->
    <div *ngIf="!isLoading" class="modal-content" style="padding: 20px 0">
      <!-- Species. -->
      <div *ngIf="step === 0" class="frm-element">
        <label>Tipo:</label>
        <div>
          <select id="selFamily" class="sel-options" [(ngModel)]="activeFamily">
            <option [disabled]="true" [value]="0">
              Selecione a espécie...
            </option>
            <option *ngFor="let f of petFamilies" [value]="f.id">
              {{ f.name }}
            </option>
          </select>
        </div>
      </div>
      <span *ngIf="errFamily" class="spn-error">{{ errFamily }}</span>

      <!-- Gender. -->
      <div *ngIf="step === 1" class="frm-element">
        <label>Gênero:</label>
        <div>
          <select id="selGender" class="sel-options" [(ngModel)]="activeGender">
            <option [disabled]="true" [value]="0">Selecione o gênero...</option>
            <option value="F">
              {{ 'GENERIC.GENDERS.FEMALE' | translate }}
            </option>
            <option value="M">{{ 'GENERIC.GENDERS.MALE' | translate }}</option>
          </select>
        </div>
      </div>
      <span *ngIf="errGender" class="spn-error">{{ errGender }}</span>

      <!-- Pet's name. -->
      <div *ngIf="step === 2" class="frm-element">
        <div>
          <input
            id="pet-name notranslate"
            content="notranslate"
            class="frm-textbox"
            [ngClass]="{ 'el-has-value': pet.name }"
            type="text"
            [(ngModel)]="pet.name"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'PETS.ATTRIBUTES.NAME' | translate
          }}</span>
        </div>
      </div>
      <span *ngIf="errName" class="spn-error spn-err-input">{{ errName }}</span>

      <!-- Year. -->
      <div *ngIf="step === 3" class="frm-element">
        <div>
          <input
            id="pet-year"
            class="frm-textbox"
            onfocus="this.placeholder='2014'"
            onfocusout="this.placeholder=''"
            placeholder=""
            [ngClass]="{ 'el-has-value': pet.year }"
            type="text"
            [(ngModel)]="pet.year"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">{{
            'PETS.ATTRIBUTES.BIRTHDAY' | translate
          }}</span>
        </div>
      </div>
      <span *ngIf="errYear" class="spn-error spn-err-input">{{ errYear }}</span>

      <!-- Avatar. -->
      <div *ngIf="step === 4" class="pet-avatar">
        <div *ngIf="!imageChangedEvent">
          <div>
            <p style="font-weight: bold">Escolha uma foto para o seu pet.</p>
            <p style="font-size: small; margin: -15px 0 20px 0; opacity: 0.5">
              (Você pode fazer isto mais tarde, se preferir)
            </p>
          </div>
          <div class="pet-pics">
            <img
              *ngIf="!pet.picture"
              src="{{ getGenericAvatar() }}"
              class="img-avatar" />
            <img
              *ngIf="pet.picture"
              src="{{ pet.picture }}"
              class="img-avatar" />
            <i
              *ngIf="newAvatarUrl"
              class="fas fa-long-arrow-alt-right i-avatar-separator"></i>
            <img *ngIf="newAvatarUrl" [src]="newAvatarUrl" class="img-avatar" />
          </div>
          <input
            type="file"
            name="file-picture[]"
            id="file-picture"
            (change)="changePetAvatar($event)"
            class="custom-input-file custom-input-file--2"
            data-multiple-caption="{count} files selected"
            multiple />
          <label for="file-picture" class="custom-input-label">
            <i class="fa fa-upload"></i>
            <span id="spn-new-pic-upload">{{ newAvatarBtnText }}</span>
          </label>
          <div
            *ngIf="newAvatarUrl"
            class="cancel-avatar"
            (click)="cancelNewAvatar()">
            cancelar
          </div>
        </div>

        <div *ngIf="imageChangedEvent" class="pet-crop">
          <div>
            <p style="font-weight: bold; margin: 0 0 10px">Ajuste a imagem.</p>
          </div>

          <!-- Image cropper. -->
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            format="png"
            roundCropper="true"
            [resizeToWidth]="400"
            [resizeToHeight]="400"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>

        <!-- Crop button. -->
        <div *ngIf="imageChangedEvent" style="float: right; margin-top: 20px">
          <button
            type="button"
            class="btn btn-sm btn-success btn-icon-label"
            (click)="cropDone()">
            <span class="btn-inner--icon"
              ><i class="fas fa-check-circle"></i
            ></span>
            <span *ngIf="!isSaving" class="btn-inner--text">{{
              'GENERIC.DONE' | translate
            }}</span>
            <span *ngIf="isSaving" style="margin-left: 50px"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>
          </button>
        </div>
      </div>

      <!-- Previous button. -->
      <div
        style="float: left; margin-top: 20px"
        [ngClass]="{ lastPrevBtn: step === totalSteps }">
        <button
          type="button"
          class="btn btn-sm btn-secondary btn-icon-label"
          (click)="previousStep()"
          [disabled]="step === 0 || isSaving">
          <span class="btn-inner--icon"><i class="fas fa-angle-left"></i></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.PREVIOUS' | translate
          }}</span>
        </button>
      </div>

      <!-- Next button. -->
      <div *ngIf="totalSteps > step" style="float: right; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-primary btn-icon-label"
          (click)="nextStep()"
          [disabled]="isSaving">
          <span class="btn-inner--icon"
            ><i class="fas fa-angle-right"></i
          ></span>
          <span class="btn-inner--text">{{ 'GENERIC.NEXT' | translate }}</span>
        </button>
      </div>

      <!-- Save button. -->
      <div
        *ngIf="step === totalSteps && !imageChangedEvent"
        style="float: right; margin-top: 20px">
        <button
          type="button"
          class="btn btn-sm btn-success btn-icon-label"
          (click)="savePet()"
          [disabled]="isSaving">
          <span class="btn-inner--icon"
            ><i class="fas fa-check-circle"></i
          ></span>
          <span *ngIf="!isSaving" class="btn-inner--text">{{
            'GENERIC.SAVE' | translate
          }}</span>
          <span *ngIf="isSaving" style="margin-left: 50px"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>
    </div>
    <!-- [CONTENT: END] -->

    <div *ngIf="isLoading">Buscando dados...</div>
  </div>
</section>
