// Angular modules.
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App animations.
import { slideBottomAnimation } from '@app/core/animations';

// App enumerators.
import { EBroadcast } from '@app/core/models';

// App interfaces.
import { IAddressRegister } from '@app/core/models';

// App services.
import {
  BroadcastService,
  LocationService,
  UtilsService,
} from '@app/core/services';

@Component({
  selector: 'app-register-notification',
  templateUrl: './register-notification.component.html',
  styleUrls: ['./register-notification.component.scss'],
  animations: [slideBottomAnimation],
})
export class RegisterNotificationComponent implements OnInit {
  // Output.
  @Output() redirectToMain: EventEmitter<any> = new EventEmitter<any>();

  // Form fields.
  public userEmail: string;
  public userName: string;
  public userPhone: string;
  public zipCode: string;
  public city: string;

  // Errors.
  public errUserEmail: string;
  public errUserName: string;
  public errUserPhone: string;
  public errZipCode: string;

  // Status.
  public isLoaded: boolean = false;
  public isSaving: boolean = false;
  public isLoadingPostalCode: boolean = false;
  public zipCodeFound: boolean = false;
  public alertSaved: boolean = false;

  // Constructor method.
  constructor(
    private activatedRoute: ActivatedRoute,
    private broadcastService: BroadcastService,
    private locationService: LocationService,
    private router: Router,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {
    this.activatedRoute.paramMap.subscribe(params => {
      this.zipCode = params.get('id');
      if (this.zipCode) {
        this.getAddress(this.zipCode);
      }
    });
  }

  // On init.
  public ngOnInit(): void {
    window.scroll(0, 0);
  }

  // Get address based on the given CEP.
  public getAddress(cep: string): void {
    this.isLoadingPostalCode = true;
    this.errZipCode = null;
    this.zipCodeFound = false;
    this.city = null;

    this.locationService
      .searchByZipCode(cep)
      .subscribe((data: IAddressRegister) => {
        if (data.city && data.state) {
          this.city = data.city + ' - ' + data.state;
          this.errZipCode = null;
          this.zipCodeFound = true;
        } else {
          this.errZipCode = 'CEP não localizado';
          this.zipCodeFound = false;
        }

        this.isLoadingPostalCode = false;
        this.broadcastService.sendBroadcast(
          EBroadcast.FullLoaderOff,
          '/register'
        );
        this.isLoaded = true;
        setTimeout(() => {
          document.getElementById('txt-name') &&
            document.getElementById('txt-name').focus();
          document.getElementById('txt-zipcode').classList.add('el-has-value');
        }, 500);
      });
  }

  // When user is typing on postal code field.
  public typingPostalCode(event: any): void {
    const charCode: any = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.zipCode = this.zipCode.length[-1];
    } else {
      const val: string = this.zipCode;
      if (val.length === 5) {
        this.zipCode += '-';
      } else if (val && val.length >= 8) {
        this.getAddress(val);
      }
    }
  }

  // Register to receive an alert.
  public register(): void {
    this.errUserEmail = null;
    this.errUserName = null;
    this.errUserPhone = null;

    const validName: boolean = this.validateName();
    const validEmail: boolean = this.validateEmail();
    const validZip: boolean = this.validateZipCode();

    if (validName && validEmail && validZip) {
      this.isSaving = true;

      setTimeout(() => {
        this.alertSaved = true;
        this.isSaving = false;
      }, 2500);
    }
  }

  // Validate user's name.
  public validateName(event?: any): boolean {
    let valid: boolean = true;
    const el: HTMLElement = document.getElementById('txt-name');

    if (!event || !el.classList.contains('ng-pristine')) {
      if (!this.userName) {
        this.errUserName = 'Informe o seu nome.';
        valid = false;
      } else {
        this.errUserName = null;
      }
    }

    return valid;
  }

  // Validate user's email.
  public validateEmail(event?: any): boolean {
    let valid: boolean = true;
    const el: HTMLElement = document.getElementById('txt-email');

    if (!event || !el.classList.contains('ng-pristine')) {
      if (!this.userEmail) {
        this.errUserEmail = this.translate.instant(
          'REGISTER.ERRORS.EMAIL_REQUIRED'
        );
        valid = false;
      } else if (!this.utilsService.validateEmail(this.userEmail)) {
        this.errUserEmail = this.translate.instant(
          'REGISTER.ERRORS.EMAIL_INVALID'
        );
        valid = false;
      } else {
        this.errUserEmail = null;
      }
    }

    return valid;
  }

  // Validate user's zipCode.
  public validateZipCode(event?: any): boolean {
    let valid: boolean = true;
    const z: string = this.zipCode;
    const el: HTMLElement = document.getElementById('txt-zipcode');

    if (!event || !el.classList.contains('ng-pristine')) {
      if (!z) {
        this.errZipCode = 'Informe o seu CEP.';
        valid = false;
      } else if (z.length !== 8 || isNaN(parseInt(z, 10))) {
        this.errZipCode = 'Informe um CEP válido.';
        valid = false;
      } else {
        this.errZipCode = null;
      }
    }

    return valid;
  }

  // Return to main page.
  public returnToMainPage(): void {
    this.redirectToMain.emit();
  }

  // Redirect to home page.
  public goToHome(): void {
    // this.router.navigate([environment.urls.site]);
    window.location.href = environment.urls.site;
  }

  // When exit page.
  public exitPage(event?: any, path?: string): void {
    this.isLoaded = false;

    if (path) {
      setTimeout(() => {
        this.broadcastService.sendBroadcast(EBroadcast.FullLoaderOn, path);
        this.router.navigate([path]);
      }, 300);
    }
  }
}
