// Angular modules.
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class MobileService {
  public isBrowser = isPlatformBrowser(this.platformId);

  // Constructor method.
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  // Return 'true'if app is running on a mobile device or on mobile mode.
  public isMobile(): boolean {
    return this.isMobileDevice() || this.isMobileMode();
  }

  // Return 'true' if application is running on mobile mode.
  public isMobileMode(): boolean {
    let h: number;
    let w: number;

    if (this.isBrowser) {
      h = window.innerHeight;
      w = window.innerWidth;
    }

    const dev: boolean = this.isMobileDevice();

    // return dev || (w <= 1100 && h <= 800);
    return dev || (w && w <= 1100);
  }

  // Return 'true' if application is running on a mobile device.
  public isMobileDevice(): boolean {
    // Devices list.
    const devices: string[] = [];
    devices.push('android');
    devices.push('blackberry');
    devices.push('ipad');
    devices.push('iphone');
    devices.push('ipod');
    devices.push('playbook');
    devices.push('silk');
    devices.push('webos');
    devices.push('windows phone');

    // Current user agent name.
    const agent: string = this.isBrowser
      ? navigator.userAgent.toLowerCase()
      : null;

    // Result.
    let match: boolean = false;
    if (agent) {
      devices.map(dev => {
        if (agent.indexOf(dev) > -1) {
          match = true;
        }
      });
    }
    return match;
  }
}
