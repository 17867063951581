// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App enumerators.
import { EReportTypes } from '@app/core/models/';

// App interfaces.
import { IReportType } from '@app/core/models/';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ReportService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(private authService: AuthService, private http: HttpClient) {}

  // Return available report types.
  public getReportTypes(lang: string): IReportType[] {
    const types: IReportType[] = [];

    if (lang === 'pt-BR') {
      types.push({ title: 'Pagamento', type: EReportTypes.Payment });
    } else if (lang === 'en-US') {
      types.push({ title: 'Payment', type: EReportTypes.Payment });
    }

    return types;
  }
}
