// Angular modules.
import { Component, Input, OnInit } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { pageAnimations } from '@app/core/animations';

// App enumerators.
import {} from '@app/core/models';

// App interfaces.
import { IHumanLocalData, IPet, IPetFamily } from '@app/core/models';

// App services.
import { AuthService, PetService } from '@app/core/services';

@Component({
  selector: 'app-mc-register-resume',
  templateUrl: './mc-register-resume.component.html',
  styleUrls: ['./mc-register-resume.component.scss'],
  animations: [pageAnimations],
})
export class McVpRegisterResumeComponent implements OnInit {
  // Inputs.
  @Input() pet: IPet;

  // Logged human's data.
  public human: IHumanLocalData = this.authService.getLocalData();

  // Pet.
  public petFamilies: IPetFamily[] = [];
  public chipNumber: string;
  public petFamily: string;

  // Status.
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  // Constuctor method.
  constructor(
    private authService: AuthService,
    private petService: PetService,
    private translate: TranslateService
  ) {}

  // On init.
  public ngOnInit(): void {
    // Get chip number (if any).
    this.chipNumber =
      this.pet.chip || this.translate.instant('PETS.ERRORS.NO_CHIP');

    // Get pet's family.
    this.getFamily();
  }

  // Get pet's family.
  private getFamily(): void {
    this.isLoading = true;

    // Get pet's family.
    this.petService
      .getFamilies()
      .then(res => {
        this.petFamilies = res.rows as IPetFamily[];
        this.petFamily = this.petFamilies.find(
          pf => pf.id === this.pet.familyId
        ).name;
      })
      .catch(err => {})
      .finally(() => {
        this.isLoading = false;
      });
  }
}
