import {
  animate,
  keyframes,
  group,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

// Slide in/out animation.
export const slideAnimation: any = [
  trigger('slideUpDown', [
    transition(':enter', [
      style({ opacity: 0, marginTop: '-100%' }),
      // animate('200ms ease-in-out', style({opacity: 0.5, marginTop: '-50%'})),
      animate('500ms ease-in-out', style({ opacity: 1, marginTop: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5, marginTop: '-50%' })),
      animate('800ms ease-in-out', style({ opacity: 0, marginTop: '-100%' })),
    ]),
  ]),
];

// Slide in/out animation (alternative).
export const slideBottomAnimation: any = [
  trigger('slideDown', [
    transition(':enter', [
      style({ opacity: 0, marginTop: '-100%' }),
      // animate('200ms ease-in-out', style({opacity: 0.5, marginTop: '-50%'})),
      animate('500ms ease-in-out', style({ opacity: 1, marginTop: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5, marginTop: '150%' })),
      animate('800ms ease-in-out', style({ opacity: 0, marginTop: '200%' })),
    ]),
  ]),
];

// Slide in/Fade out animation.
export const slideFadeAnimation: any = [
  trigger('slideAndFade', [
    transition(':enter', [
      style({ opacity: 0, marginTop: '-100%' }),
      // animate('200ms ease-in-out', style({opacity: 0.5, marginTop: '-50%'})),
      animate('500ms ease-in-out', style({ opacity: 1, marginTop: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('500ms ease-in-out', style({ opacity: 0.5 })),
      animate('800ms ease-in-out', style({ opacity: 0 })),
    ]),
  ]),
];

// Modal.
export const modalAnimation: any = [
  trigger('slideUpDown', [
    transition(':enter', [
      style({ opacity: 0, marginTop: '-100%' }),
      animate('100ms ease-in-out', style({ opacity: 0.5, marginTop: '-50%' })),
      animate('300ms ease-in-out', style({ opacity: 1, marginTop: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 1, marginTop: 0 }),
      animate('100ms ease-in-out', style({ opacity: 0.5, marginTop: '-50%' })),
      animate('300ms ease-in-out', style({ opacity: 0, marginTop: '-100%' })),
    ]),
  ]),
];
