// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// App environment.
import { environment } from '@env/environment';

// App models.
import { IAPIResponse, ICoupon } from '@app/core/models';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';
import { ErrorService } from '@app/core/services/error/error.service';

// Main endpoint.
const endpoint: string = environment.urls.api + 'coupons/';

@Injectable({ providedIn: 'root' })
export class CouponService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private http: HttpClient
  ) {}

  // Get by it's ID.
  public getById(id: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + id, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get by it's code.
  public getByCode(code: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'code/' + code, { headers: this.headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Verify coupom by its code.
  public verifyCoupon(coupon: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'verify/' + coupon, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }
}
