<section class="mob-menu-container" [@slideAndFade]>
  <!-- Close icon. -->
  <i
    id="i-close-mob-menu"
    class="fas fa-times-circle"
    (click)="closeMenu()"></i>

  <div class="menu-content">
    <ul>
      <!-- Common menu. -->
      <li
        *ngFor="let m of menu"
        [ngClass]="{ 'mnu-active': m.active }"
        (click)="goToPage(m.url)">
        <div class="li-mnu-i"><i class="{{ m.icon }}"></i></div>
        <div class="li-mnu-title">{{ m.title }}</div>
      </li>

      <!-- Refer a friend. -->
      <!-- <li class="refer-menu-item" (click)="showReferModal()">
        <div class="li-mnu-i"><i class="fas fa-bullhorn"></i></div>
        <div class="li-mnu-title">{{ 'MENU.USER_MENU.REFER' | translate }}</div>
      </li> -->

      <!-- My clinics menu. -->
      <li
        *ngIf="clinics.length > 1"
        class="myclinics-menu-item"
        (click)="goToPage('my-clinics')">
        <div class="li-mnu-i"><i class="fas fa-clinic-medical"></i></div>
        <div class="li-mnu-title">{{ myClinicsText }}</div>
      </li>

      <!-- My clinic menu. -->
      <li
        *ngIf="clinics.length === 1"
        class="myclinics-menu-item"
        (click)="goToClinic(clinics[0].id)">
        <div class="li-mnu-i"><i class="fas fa-clinic-medical"></i></div>
        <div class="li-mnu-title">{{ myClinicText }}</div>
      </li>

      <!-- Admin menu. -->
      <li
        *ngFor="let m of menuAdmin"
        class="adm-menu-item"
        [ngClass]="{ 'mnu-active': m.active }"
        (click)="goToPage(m.url)">
        <div class="li-mnu-i"><i class="{{ m.icon }}"></i></div>
        <div class="li-mnu-title">{{ m.title }}</div>
      </li>
    </ul>

    <hr class="divider" />

    <ul>
      <li (click)="logout()">
        <div class="li-mnu-i"><i class="fas fa-sign-out-alt"></i></div>
        <div class="li-mnu-title">{{ 'AUTH.LOGOUT' | translate }}</div>
      </li>
    </ul>
  </div>
</section>
