// Angular modules.
import { Component } from '@angular/core';

// App enumerators.
import { EAlertType, EBroadcast } from '@app/core/models';

// App interfaces.
import { IAlert } from '@app/core/models';

// App services.
import { BroadcastService, MobileService } from '@app/core/services';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {
  // Alert ID.
  public id: string;

  // Custom data.
  public customClass: string = 'app-alert-info';
  public customIcon: string = '';
  public customMessage: string;

  // Default timer to close alert (in seconds).
  public defaultTime: number = 8000;

  // Status.
  public isMobile: boolean = this.mobileService.isMobile();

  // Constructor method.
  constructor(
    private broadcastService: BroadcastService,
    private mobileService: MobileService
  ) {
    // Subscribe to broadcast events.
    this.listenToBroadcast();
  }

  // Force listening to broadcast.
  public startListening(): void {
    this.listenToBroadcast();
  }

  // Close current message.
  public closeMessage(id: string, timer: number = this.defaultTime): void {
    const el: HTMLElement = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        // el.style.display = 'none';
        // el.children[0].classList.add('hidden-app-alert');
        el.classList.add('hidden-app-alert');
        setTimeout(() => {
          this.id = null;
          this.customMessage = null;
        }, 2000);
      }, timer);
    } else {
      setTimeout(() => {
        this.closeMessage(id, timer);
      }, 300);
    }
  }

  // Listen to broadcast events.
  private listenToBroadcast(): void {
    this.broadcastService.events.subscribe(ev => {
      switch (ev.key) {
        // API error.
        case EBroadcast.Alert:
          this.buildAlert(ev.value);
          break;

        // Window resized.
        case EBroadcast.WindowResized:
          this.isMobile = ev.value;
          break;
      }
    });
  }

  // Build a new alert.
  private buildAlert(data: IAlert): void {
    this.id = data.id;
    this.customMessage = data.message;

    switch (data.type) {
      case EAlertType.Error:
        this.customClass = 'app-alert-error';
        this.customIcon = 'fas fa-exclamation-triangle';
        break;

      case EAlertType.Info:
        this.customClass = 'app-alert-info';
        this.customIcon = 'fas fa-info-circle';
        break;

      case EAlertType.Success:
        this.customClass = 'app-alert-success';
        this.customIcon = 'fas fa-check-circle';
        break;
    }

    const el: HTMLElement = document.getElementById(data.id);
    /*if (el) {
      el.style.display = 'block';
      setTimeout(() => { el.children[0].classList.remove('hidden-app-alert'); }, 300);
      this.closeMessage(data.id);
    }*/
    if (!data.avoidAutoClose) {
      this.closeMessage(
        data.id,
        data.secondsToClose ? data.secondsToClose * 1000 : 5000
      );
    }
  }
}
