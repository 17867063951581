<div *ngIf="!isLoading" [@fadeInOut] style="margin: 20px 0">
  <!-- Protected. -->
  <div
    *ngIf="
      pet.subscription && pet.subscription.id && !pet.subscription.canceledAt
    ">
    <div class="frm-element frm-el-inline">
      <label>Rede:</label>
      <div class="div-item">{{ planName }}</div>
    </div>

    <div class="frm-element frm-el-inline">
      <label>Desde:</label>
      <div class="div-item">
        {{ pet.subscription.createdAt | date : 'dd/MM/yyyy' }}
      </div>
    </div>

    <div class="frm-element frm-el-inline">
      <label>Data de início do plano:</label>
      <div class="div-item">
        {{ petService.getSubscriptionStartDate(pet.subscription) }}
      </div>
    </div>
  </div>

  <!-- Protected. -->
  <div
    *ngIf="
      !pet.subscription || !pet.subscription.id || pet.subscription.canceledAt
    "
    style="margin-bottom: 20px">
    Este pet não faz parte da rede.
  </div>

  <!-- Add to/Remove from free plan. -->
  <div *ngIf="human.isAdmin" class="freeplan-container" style="clear: both">
    <div
      *ngIf="!showCancelOptions && !showPlanOptions"
      class="action-btns-container">
      <!-- Add. -->
      <button
        class="btn btn-sm btn-secondary"
        type="button"
        (click)="showPlanOptions = true">
        <span
          *ngIf="!pet.subscription"
          class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
          >Adicionar a rede</span
        >
        <span
          *ngIf="pet.subscription"
          class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
          >Alterar plano</span
        >
      </button>

      <!-- Remove. -->
      <button
        *ngIf="pet.subscription"
        class="btn btn-sm btn-secondary"
        type="button"
        (click)="showCancelOptions = true">
        <span
          *ngIf="!pet.subscription.canceledAt"
          class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
          >Remover da rede</span
        >
        <span
          *ngIf="pet.subscription.canceledAt"
          class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
          >Alterar dados de cancelamento</span
        >
      </button>
    </div>

    <div *ngIf="showPlanOptions">
      <p><strong>Pet participará dos rateios?</strong></p>

      <div class="frm-checkbox-element">
        <div class="freeware-container">
          <input
            id="freeware"
            class="frm-checkbox"
            type="checkbox"
            [disabled]="isSaving"
            [(ngModel)]="activeAssessment"
            required />
          <span class="frm-label">Participa dos rateios</span>
        </div>
      </div>

      <!-- Subscription plan data. -->
      <p><strong>Escolha o plano</strong></p>

      <div class="frm-checkbox-element">
        <div class="free-plan-container">
          <input
            id="free-plan"
            class="frm-checkbox"
            type="checkbox"
            [disabled]="isSaving || freeDefaultPlan"
            [(ngModel)]="chargeDefaultPlan"
            (change)="freePlanAdoptionOnChange($event)"
            required />
          <span class="frm-label">Esse pet pagará o plano básico?</span>
        </div>
        <span *ngIf="freeDefaultPlan" class="spn-error"
          >Gratuito, pois a primeira assinatura é anterior a 01/06/2022</span
        >
      </div>

      <div class="row row-subscription-plan">
        <div
          class="frm-element frm-element-inline frm-subscription-plan-container">
          <div class="btn-subs-plan-container btn-subs-plan-month">
            <button
              type="button"
              class="btn btn-subs-plan"
              [ngClass]="{ 'btn-subs-active': subscriptionPlan === 'Monthly' }"
              (click)="changePlan('Monthly')"
              [disabled]="isSaving">
              <span class="btn-inner--text">Mensal</span>
            </button>
          </div>
          <div class="btn-subs-plan-container btn-subs-plan-annual">
            <button
              type="button"
              class="btn btn-subs-plan"
              [ngClass]="{ 'btn-subs-active': subscriptionPlan === 'Annual' }"
              (click)="changePlan('Annual')"
              [disabled]="isSaving">
              <span class="btn-inner--text">Anual</span>
            </button>
          </div>
          <span *ngIf="errHSubscriptionPlan" class="spn-error">{{
            errHSubscriptionPlan
          }}</span>
        </div>
      </div>

      <p *ngIf="subscriptionPlan"><strong>Coberturas adicionais</strong></p>

      <div *ngIf="subscriptionPlan" class="container-additional-coverage">
        <div
          *ngIf="subscriptionPlan"
          class="row row-additional-coverage row-vaccines-coverage">
          <!-- Vaccines. -->
          <div class="frm-checkbox-element">
            <div class="vaccines-coverage-container">
              <input
                id="vaccines-coverage"
                class="frm-checkbox"
                type="checkbox"
                (change)="changeCoverage('vaccines', $event)"
                [disabled]="isSaving"
                [(ngModel)]="vaccinesCoverage"
                required />
              <span class="frm-label"
                >Adicionar plano de vacinas (+ R$20,00/mês).</span
              >
            </div>
          </div>
        </div>

        <div
          *ngIf="subscriptionPlan"
          class="row row-additional-coverage row-checkup-coverage">
          <!-- Checkup. -->
          <div class="frm-checkbox-element">
            <div class="checkup-coverage-container">
              <input
                id="checkup-coverage"
                class="frm-checkbox"
                type="checkbox"
                (change)="changeCoverage('checkup', $event)"
                [disabled]="isSaving"
                [(ngModel)]="checkupCoverage"
                required />
              <span class="frm-label"
                >Adicionar plano preventivo (+ R$30,00/mês).</span
              >
            </div>
            <span *ngIf="errAdditionalCoverage" class="spn-error">{{
              errAdditionalCoverage
            }}</span>
          </div>
        </div>

        <div
          *ngIf="subscriptionPlan"
          class="row row-additional-coverage row-dental-coverage">
          <!-- Dental. -->
          <div class="frm-checkbox-element">
            <div class="dental-coverage-container">
              <input
                id="dental-coverage"
                class="frm-checkbox"
                type="checkbox"
                (change)="changeCoverage('dental', $event)"
                [disabled]="isSaving"
                [(ngModel)]="dentalCoverage"
                required />
              <span class="frm-label"
                >Adicionar limpeza dentária (+ R$60,00/mês).</span
              >
            </div>
            <span *ngIf="errAdditionalCoverage" class="spn-error">{{
              errAdditionalCoverage
            }}</span>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          subscriptionPlan ||
          vaccinesCoverage ||
          checkupCoverage ||
          dentalCoverage
        "
        class="row row-pay-info"
        [ngClass]="{ 'mob-mode': isMobile }">
        <!-- Plan price. -->
        <div class="frm-element frm-el-inline">
          <div style="min-height: 40px">
            <span
              *ngIf="!subscriptionPlan && !(getFinalValue() >= 0)"
              class="frm-fixed-value frm-textbox el-has-value"
              >Selecione um plano</span
            >
            <span
              *ngIf="subscriptionPlan && getFinalValue() > 0"
              class="frm-fixed-value frm-textbox el-has-value"
              >{{ getFinalValue() | zcurrency }}</span
            >
            <span
              *ngIf="subscriptionPlan && !getFinalValue()"
              class="frm-fixed-value frm-textbox el-has-value spn-success-color"
              >Grátis</span
            >
            <span class="frm-label">Valor final do plano</span>
          </div>
        </div>
      </div>

      <div *ngIf="startDate" style="margin: 10px 0 20px">
        <div class="frm-element">
          <input
            id="txt-phone"
            class="frm-textbox el-has-value"
            style="max-width: 200px"
            [ngClass]="{ 'el-has-value': startDate }"
            type="phone"
            [(ngModel)]="startDate"
            mask="00/00/0000"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">Data de início novo plano:</span>
        </div>
        <span *ngIf="errStartDate" class="spn-error">{{ errStartDate }}</span>
      </div>

      <div *ngIf="paymentSystem !== 'CreditCard'">
        <span class="spn-error"
          >Cliente não possuí cartão de crédito cadastrado, cobrança deve ser
          realizada manualmente.</span
        >
      </div>

      <div class="buttons-container">
        <button
          class="btn btn-sm btn-secondary"
          type="button"
          (click)="showPlanOptions = false"
          [disabled]="isSaving">
          <span class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
            >Cancelar</span
          >
        </button>

        <button
          class="btn btn-sm btn-success"
          style="margin-left: 10px"
          type="button"
          (click)="addToPlan()"
          [disabled]="!formValid || isSaving">
          <span class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
            >Adicionar plano</span
          >
        </button>
      </div>
    </div>

    <div *ngIf="showCancelOptions">
      <p><strong>Motivo do cancelamento?</strong></p>

      <div>
        <input
          class="frm-textbox"
          [ngClass]="{ 'el-has-value': financialOverdue }"
          type="checkbox"
          [(ngModel)]="financialOverdue"
          [disabled]="isSaving"
          appInputs />
        Inadimplência financeira
      </div>

      <div>
        <input
          class="frm-textbox"
          [ngClass]="{ 'el-has-value': petDied }"
          type="checkbox"
          [(ngModel)]="petDied"
          [disabled]="isSaving"
          appInputs />
        Facelimento do pet
      </div>

      <div *ngIf="petDied" style="margin: 10px 0 20px">
        <div class="frm-element">
          <input
            id="txt-phone"
            class="frm-textbox el-has-value"
            style="max-width: 200px"
            [ngClass]="{ 'el-has-value': petDiedDate }"
            type="phone"
            [(ngModel)]="petDiedDate"
            mask="00/00/0000"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">Data de falecimento:</span>
        </div>
        <span *ngIf="errPetDiedDate" class="spn-error">{{
          errPetDiedDate
        }}</span>
      </div>

      <div *ngIf="subscriptionCancelDate" style="margin: 10px 0 20px">
        <div class="frm-element">
          <input
            id="txt-phone"
            class="frm-textbox el-has-value"
            style="max-width: 200px"
            [ngClass]="{ 'el-has-value': subscriptionCancelDate }"
            type="phone"
            [(ngModel)]="subscriptionCancelDate"
            mask="00/00/0000"
            [showMaskTyped]="true"
            [dropSpecialCharacters]="false"
            [disabled]="isSaving"
            appInputs />
          <span class="frm-label">Data de cancelamento:</span>
        </div>
        <span *ngIf="errSubscriptionCancelDate" class="spn-error">{{
          errSubscriptionCancelDate
        }}</span>
      </div>

      <div class="buttons-container">
        <button
          class="btn btn-sm btn-secondary"
          type="button"
          (click)="showCancelOptions = false"
          [disabled]="isSaving">
          <span class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
            >Cancelar</span
          >
        </button>

        <button
          class="btn btn-sm btn-danger"
          style="margin-left: 10px"
          type="button"
          (click)="cancelPlan()"
          [disabled]="isSaving || cancelFormValid">
          <span class="btn-inner--text ng-tns-c30-11 ng-star-inserted"
            >Remover plano</span
          >
        </button>
      </div>
    </div>
  </div>
</div>
