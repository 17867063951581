// Angular modules.
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App environment.
import { environment } from '@env/environment';

// App enumerators.
import { EAuthType, IPet } from '@app/core/models';

// App interfaces.
import {
  IAddressRegister,
  IAPIResponse,
  IEmailMessage,
  IHuman,
  IHumanGuest,
  IHumanRegister,
  IHumanQueue,
  ILocalAdmin,
} from '@app/core/models';

// App services.
import { AddressService } from '@app/core/services/address/address.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { EmailService } from '@app/core/services/email/email.service';
import { ErrorService } from '@app/core/services/error/error.service';
import { PetService } from '../pet/pet.service';

// Email tempalte
// import { newAccountTemplate } from "./new.account.template";

// Endpoints.
const rootEP: string = environment.urls.api;
const endpoint: string = rootEP + 'humans/';
const adminsEndpoint: string = rootEP + 'admins/';
const addressesEndpoint: string = rootEP + 'addresses/';
const humansAddressesEndpoint: string = rootEP + 'humans-addresses/';
const clinicsEndpoint: string = rootEP + 'clinics/';
const cliHumansEndpoint: string = rootEP + 'clinics-humans/';

@Injectable({ providedIn: 'root' })
export class HumanService {
  // Http.
  public headers: HttpHeaders = this.authService.getHttpHeaders();

  // Constructor method.
  constructor(
    private addressService: AddressService,
    private authService: AuthService,
    private petService: PetService,
    private emailService: EmailService,
    private errorService: ErrorService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  // Get all humans.
  public getAll(): Promise<IHuman[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman[]>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint, { headers: this.headers })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get humans pictures uploads path.
  public getHumanPicuturesPath(): string {
    return environment.uploadPath + '/users/';
  }

  // Get multiple humans by ID.
  public getMultiple(ids: number[]): Promise<IHuman[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman[]>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(endpoint + 'multiple', JSON.stringify(ids), {
          headers: this.headers,
        })
        .subscribe(
          result => {
            resolve(result.rows);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get multiple humans by email.
  public getMultipleWithEmail(emails: string[]): Promise<IHuman[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman[]>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          endpoint + 'multiple-email',
          JSON.stringify(emails),
          { headers: this.headers }
        )
        .subscribe(
          result => {
            resolve(result.rows);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get a human by ID.
  public getWithId(humanId: number): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .get<any>(endpoint + humanId, { headers: this.headers })
        .subscribe(
          result => {
            resolve(result.rows);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get a human with e-mail.
  public getWithEmail(email: string): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .get<any>(endpoint + 'email/' + email, { headers: this.headers })
        .subscribe(
          result => {
            resolve(result && result.rows ? result.rows : null);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get a human with document.
  public getWithDocument(doc: string): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .get<any>(endpoint + 'doc/' + doc, { headers: this.headers })
        .subscribe(
          result => {
            resolve(result && result.rows ? result.rows : null);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get a human with his coupoun.
  public getWithCoupon(c: string): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .get<any>(endpoint + 'coupon/' + c, { headers: this.headers })
        .subscribe(
          result => {
            resolve(result && result.rows ? result.rows : null);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Get all admins.
  public getAdmins(): Promise<ILocalAdmin[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<ILocalAdmin[]>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(adminsEndpoint, { headers: this.headers })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Create a new admin.
  public createAdmin(humanId: number): Promise<any> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<any[]>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(adminsEndpoint, JSON.stringify({ humanId }), {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get human associated clinics.
  public getHumanClinics(humanId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(cliHumansEndpoint + 'human/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          res => {
            if (res && res.rows) {
              const arIds: number[] = res.rows.map(r => r.clinicId);
              this.http
                .post<IAPIResponse>(
                  clinicsEndpoint + 'multiple/',
                  JSON.stringify({ arIds }),
                  { headers: this.headers }
                )
                .subscribe(
                  resHC => {
                    resolve(resHC);
                  },
                  errHC => {
                    reject(errHC);
                  }
                );
            } else {
              resolve(null);
            }
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  // Get human's address.
  public getHumanAddress(humanId: number): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(humansAddressesEndpoint + 'human/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          result => {
            if (result && result.rows && result.rows.length > 0) {
              const addrId: number = result.rows[0].addressId;
              this.http
                .get<IAPIResponse>(addressesEndpoint + addrId, {
                  headers: this.headers,
                })
                .subscribe(
                  res => {
                    resolve(res);
                  },
                  err => {
                    reject(err);
                  }
                );
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Create a new human.
  public createNewHuman(
    logType: EAuthType,
    newHuman: IHumanRegister,
    newAddress?: IAddressRegister
  ): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      if (!newAddress.zipCode) {
        newAddress = {
          city: 'Curitiba',
          country: 'BR',
          neighborhood: 'Centro',
          // eslint-disable-next-line id-blacklist
          number: '68',
          state: 'PR',
          street: 'NoAddress',
          zipCode: '0',
        };
      }

      const now = new Date();
      const createdAtUtc = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      );

      const human: IHuman = {
        blocked: false,
        coupon: this.createCoupon(newHuman.fullName),
        createdAt: createdAtUtc.toISOString(),
        email: newHuman.email,
        fullName: newHuman.fullName,
        id: null,
        invitedBy: newHuman.invitedBy || null,
        password: newHuman.password,
        phone: newHuman.phone,
        updatedAt: null,
      };

      this.http
        .post<IAPIResponse>(endpoint, JSON.stringify(human), {
          headers: this.headers,
        })
        .subscribe(
          result => {
            const humanId: number = result.rows.id;

            if (humanId) {
              human.id = humanId;
              // this.sendNewAccountEmail(human);
            }

            if (newAddress) {
              this.addressService
                .createNewAddress(newAddress)
                .then(res => {
                  const addressId: number = res.rows.id;

                  this.addressService
                    .createHumanAddress(humanId, addressId)
                    .then(r => {
                      resolve(result);
                    })
                    .catch(e => {
                      reject(e);
                    });
                })
                .catch(err => {
                  reject(err);
                });
            } else {
              resolve(result);
            }
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Update human.
  public updateHuman(human: IHuman): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(endpoint + human.id, JSON.stringify(human), {
          headers: this.headers,
        })
        .subscribe(
          result => {
            const human: IHuman = result.rows;
            this.authService.updateHumansLocalData(human);
            resolve(result.rows);
          },
          error => {
            reject(error);
          }
        );
    });

    return promise;
  }

  // Update admin status.
  public updateAdminStatus(adm: ILocalAdmin): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(
          adminsEndpoint + adm.id,
          JSON.stringify({ blocked: adm.blocked }),
          { headers: this.headers }
        )
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Update admin.
  public deleteAdmin(adm: ILocalAdmin): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .delete<IAPIResponse>(adminsEndpoint + adm.id, {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get humans invited by another human.
  public getInvitedBy(humanId: string): Promise<IHumanGuest[]> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHumanGuest[]>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'invited/' + humanId, {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result.rows),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get human's picture.
  public getHumanPicture(h: IHuman): string {
    const pic = h.picture || this.getGenericPicture();

    return this.getHumanPicuturesPath() + pic || this.getGenericPicture();
  }

  // Get generic picture.
  public getGenericPicture(): string {
    return 'assets/images/avatar/generic-man.png';
  }

  // Update human's avatar.
  public updateHumanAvatar(humanId: string, file: any): Promise<IHuman> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IHuman>((resolve, reject) => {
      const head: HttpHeaders = this.authService.getUploadHttpHeaders();

      const name: string = humanId + '.png';
      const f: any = this.DataURIToBlob(file.base64);
      const formData: FormData = new FormData();
      formData.append('humanPicture', f, name);

      this.http
        .put<any>(endpoint + humanId, formData, { headers: head })
        .subscribe(
          result => {
            const h: IHuman = result.rows as IHuman;
            this.authService
              .getActivePicture(h)
              .then(res => {
                h.picture = res;
                resolve(h);
              })
              .catch(err => reject(err));
          },
          error => reject(error)
        );
    });

    return promise;
  }

  // Get coupon code.
  public createCoupon(name: string): string {
    let coupon: string = Date.now().toString();

    if (name) {
      const ar: string[] = name.split(' ');
      let begin: string = '';
      for (const val of ar) {
        begin += val.charAt(0);
      }
      coupon = begin + coupon;
    }

    return coupon;
  }

  // Get coupon's owner.
  public getCouponOwner(coupon: string): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'get-coupon-owner/' + coupon, {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Register user to receive an invitation.
  public registerQueue(userData: IHumanQueue): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .post<IAPIResponse>(
          environment.urls.api + 'register-queue',
          JSON.stringify(userData),
          { headers: this.headers }
        )
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Register invite sent to an human.
  public registerSentInvite(userData: IHumanQueue): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .put<IAPIResponse>(
          environment.urls.api + 'register-queue/' + userData.id,
          JSON.stringify(userData),
          { headers: this.headers }
        )
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get all people who asked for an invite.
  public getPeopleWhoAskInvite(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(environment.urls.api + 'register-queue', {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get people who have invited someone.
  public getPeopleWhoInvite(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'people-invite/1', {
          headers: this.headers,
        })
        .subscribe(
          result => {
            this.http
              .post<IAPIResponse>(
                endpoint + 'multiple-coupon',
                JSON.stringify(result.rows),
                { headers: this.headers }
              )
              .subscribe(
                res => resolve(res),
                err => reject(err)
              );
          },
          error => reject(error)
        );
    });

    return promise;
  }

  // Get invited people.
  public getInvitedPeople(): Promise<IAPIResponse> {
    this.headers = this.authService.getHttpHeaders();

    const promise = new Promise<IAPIResponse>((resolve, reject) => {
      this.http
        .get<IAPIResponse>(endpoint + 'invited-people/1', {
          headers: this.headers,
        })
        .subscribe(
          result => resolve(result),
          error => reject(error)
        );
    });

    return promise;
  }

  // Get invited people.
  public humanHasSubscription(pets: IPet[]): boolean {
    const petsWithSubs = pets.filter(p =>
      this.petService.getSubscriptionStatus(p.subscription)
    );

    return petsWithSubs.length ? true : false;
  }

  // Convert Base64 image to Blob.
  private DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  // Build and send new account e-mail message.
  // private sendNewAccountEmail(human: IHuman): void {
  //   const msg: string = newAccountTemplate(human);

  //   const email: IEmailMessage = {
  //     message: msg,
  //     senderEmail: "contato@petbee.com.br",
  //     senderName: "Petbee",
  //     recipientEmail: human.email,
  //     recipientName: null,
  //     senderPhone: null,
  //     subject: this.translate.instant("REGISTER.MESSAGES.WELCOME"),
  //   };

  //   this.emailService
  //     .sendEmail(email)
  //     // .then(res => {})
  //     .catch((err) => console.error(err));
  // }
}
