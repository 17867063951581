// Angular modules.
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

// App services.
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthUserGuard implements CanActivate {
  // Constructor method.
  constructor(private authService: AuthService, private router: Router) {}

  // Can activate method (human default).
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      if ((next.url.length > 0 && next.url[0].path) === 'admin') {
        if (this.authService.getUserIsAdmin()) {
          resolve(true);
        } else {
          this.router.navigate(['']);
          reject(false);
        }
      } else {
        if (this.authService.isLoggedIn()) {
          resolve(true);
        } else {
          if (
            next.queryParams &&
            (next.queryParams.iv || next.queryParams.iv === '')
          ) {
            resolve(true);
          } else {
            this.router.navigate(['/login']);
            reject(false);
          }
        }
      }
    });

    return promise;
  }
}
