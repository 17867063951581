// Angular modules.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Third-party.
import { TranslateService } from '@ngx-translate/core';

// App animations.
import { modalAnimation } from '@app/core/animations';

// App enumerators.
import { EAlertType } from '@app/core/models';

// App models.
import {} from '@app/core';

// App interfaces.
import { IHumanLocalData } from '@app/core';

// App services.
import { AuthService, ErrorService, UtilsService } from '@app/core/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [modalAnimation],
})
export class ChangePasswordComponent implements OnInit {
  // Outputs.
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  // Logged human's data.
  @Input() human: IHumanLocalData;

  // Data.
  public password: string;
  public newPassword: string;
  public newPasswordConfirm: string;

  // Errors.
  public errPassword: string;
  public errPasswordMatch: string;
  public errNewPassword: string;
  public errNewPasswordConfirm: string;

  // Status.
  public isLoading: boolean = false;
  public isSaving: boolean = false;

  // Constructor method.
  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private utilsService: UtilsService
  ) {}

  // On init.
  public ngOnInit(): void {}

  // Change current password.
  public changePassword(): void {
    if (this.validateForm()) {
      this.isSaving = true;

      this.authService
        .testLogin(this.human.id, this.password)
        .then(resTest => {
          this.authService
            .changePassword(this.human.id, this.newPassword)
            .then(res => {
              this.utilsService.showAlert(
                'alert-reg-frm',
                this.translate.instant('GENERIC.MESSAGES.PASSWORD_UPDATED'),
                EAlertType.Success
              );
              setTimeout(() => {
                this.closeModal();
              }, 500);
            })
            .catch(err => {
              this.utilsService.showAlert(
                'alert-reg-frm',
                this.errorService.manageError(
                  err,
                  this.constructor.name,
                  'changePassword() > authService.changePassword()'
                ),
                EAlertType.Error
              );
            })
            .finally(() => {
              this.isSaving = false;
            });
        })
        .catch(errTest => {
          this.utilsService.showAlert(
            'alert-reg-frm',
            this.errorService.manageError(
              'Senha atual inválida',
              this.constructor.name,
              'changePassword() > authService.testLogin()'
            ),
            EAlertType.Error
          );
          this.isSaving = false;
        });
    }
  }

  public elLostFocus(event: any): void {
    if (event && event.srcElement && event.srcElement.id) {
      const el: HTMLElement = document.getElementById(event.srcElement.id);
      el.classList.add('el-has-value');
      el.classList.add('ng-dirt');
    }
  }

  // Close modal.
  public closeModal(): void {
    this.modalClosed.emit();
  }

  // Validate form.
  private validateForm(): boolean {
    let isValid: boolean = true;
    this.clearErrors();

    if (!this.password) {
      this.errPassword = this.translate.instant(
        'REGISTER.ERRORS.CURRENT_PASSWORD_REQUIRED'
      );
      isValid = false;
    }
    if (!this.newPassword) {
      this.errNewPassword = this.translate.instant(
        'REGISTER.ERRORS.NEW_PASSWORD_REQUIRED'
      );
      isValid = false;
    }
    if (!this.newPasswordConfirm) {
      this.errNewPasswordConfirm = this.translate.instant(
        'REGISTER.ERRORS.NEW_PASSWORD_CONFIRM_REQUIRED'
      );
      isValid = false;
    }
    if (isValid && this.newPassword !== this.newPasswordConfirm) {
      this.errNewPasswordConfirm = this.translate.instant(
        'REGISTER.ERRORS.PASSWORD_DOES_NOT_MATCH'
      );
      isValid = false;
    }

    return isValid;
  }

  // Clear form errors.
  private clearErrors(): void {
    this.errPassword = null;
    this.errPasswordMatch = null;
    this.errNewPassword = null;
    this.errNewPasswordConfirm = null;
  }
}
